import { Button } from "./ui/button";

export default function Component() {
  const plans = [
    {
      name: "Free",
      price: "£0",
      period: "Forever",
      features: [
        "Mobile Optimized",
        "For Hobby sites",
        "HelloWorld Domain",
        "HelloWorld banner",
      ],
      cta: "Get Site Plan",
    },
    {
      name: "Mini",
      price: "£5",
      period: "per month",
      description: "For simple websites",
      features: ["Only 1 page", "Custom domain"],
      cta: "Get Site Plan",
    },
    {
      name: "Basic",
      price: "£15",
      period: "per month",
      description: "For basic websites",
      features: ["10 pages", "Custom domain", "SEO capabilities"],
      cta: "Get Site Plan",
    },
    {
      name: "Pro",
      price: "£25",
      period: "per month",
      description: "For professional websites",
      features: [
        "Unlimited pages",
        "Custom domain",
        "Automated SEO",
        "Advanced CRM",
        "24/7 support",
      ],
      cta: "Get Site Plan",
    },
  ];

  return (
    <div className="py-20 px-4 md:px-10 bg-gray-50">
      <h2 className="text-3xl font-bold text-center mb-2">
        Get started with our plans today
      </h2>
      <p className="text-center mb-8">
        <a
          href="#"
          className="text-sm hover:underline hover:underline-offset-8 transition-all duration-1000"
        >
          View pricing plans &gt;
        </a>
      </p>
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-3">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="flex flex-col justify-between bg-white p-6 rounded-lg shadow-sm"
            >
              <div>
                <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                <div className="mb-4">
                  <span className="text-4xl font-bold">{plan.price}</span>
                  <span className="text-sm text-gray-600 ml-1">
                    {plan.period}
                  </span>
                </div>
                {plan.description && (
                  <p className="text-gray-600 mb-4">{plan.description}</p>
                )}
                <ul className="space-y-6 mb-6">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center">
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <Button className="w-full bg-black text-white hover:bg-gray-800 text-sm py-7 mt-4 rounded-xl  px-5 flex items-center gap-2 transition duration-500">
                {plan.cta}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
