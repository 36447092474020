import React from "react";
import WebsiteAnalytics from "../DashboardComponents/Analytics/WebsiteAnalytics";
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";

const Analytics = () => {
  return (
    <>
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
        <HorizontalHeader headerTitle={"Analytics"} />
        <div className="flex-1 overflow-auto p-4 ">
          <WebsiteAnalytics />
        </div>
      </div>
    </>
  );
};

export default Analytics;
