import React from 'react'


import { GoSmiley } from "react-icons/go";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


function Paywall(props) {
   const { credId } =useParams()
    const ActivePlan = useSelector((state) => state.userDetails.planDetails);
    const ActivePlanId = useSelector((state) => state.userDetails.planId);
  
  return (
    <div className='w-full h-full bg-white flex items-center justify-center'>
        
        <div className='flex flex-col items-center justify-center gap-1'>
        <GoSmiley  className='text-8xl text-yellow-500'/>
       
        <p className='text-zinc-900 text-2xl mt-2 '>You are currently on a {ActivePlan.title} plan</p>
        <p className='text-zinc-500 text-lg'>Upgrade your plan to get the access to {props.panel} panel </p>

        <a href={`/dashboard/${credId}/upgrade-plan`} className='rounded-full bg-yellow-500 text-white px-5 py-1 mt-3'>
          Check Plans {" ->"}
        </a>
        </div>
        
        
        </div>
  )
}

export default Paywall