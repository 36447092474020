import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'primereact/progressbar';
import '../global_theme/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addPage, addSectionToPage, addToHistory, setIsSiteCreated, setPageIndex, setUserHtml } from '../../../Store/Slices/userHtmlSlice';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setImages } from '../../../Store/Slices/ImageQuerySlice';
import supabase from '../../../config/supabse';
import { MultiStepLoader } from '../../UI/MultiStepLoader';



function Progress(props) {
  const showSuccessMessage = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: ""
    });
  };

  const showRejectMessage = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,

    });
  }

  const loadingStates = [
    { text: "Initializing Data" },
    { text: "Selecting Layout" },
    { text: "Generating Content" },
    { text: "Applying Styles" },
    { text: "Building Pages" },
    { text: "Finishing Touches" },
    { text: "Almost There" },
    { text: "Website Ready" },
  ];
  const Idea = useSelector((state) => { return state.Idea.data })
  const Name = useSelector((state) => { return state.Name.data })
  const subdomainId = useSelector((state) => { return state.Name.subdomain })
  const Color = useSelector((state) => { return state.Color.data })
  const Fonts = useSelector((state) => { return state.Color.fonts })
  const Title = useSelector((state) => { return state.Color.title })
  const Description = useSelector((state) => { return state.Color.description })
  const Section = useSelector((state) => { return state.Section.data })
  const Logo = useSelector((state) => { return state.Logo.data })
  const query = useSelector((state) => { return state.Query.data })
  const siteId = (useSelector(state => state.Color.siteId))
  const Hue = useSelector((state) => { return state.Color.hue })
  const Data = useSelector((state) => state.userHtml.data);
  const Pages = useSelector((state) => state.userHtml.pages);
  const index = useSelector((state) => state.userHtml.index);
  const history = useSelector((state) => state.userHtml.history);
  const Queries = (useSelector(state => state.Query.data));
  const userEmail = (useSelector(state => state.userId.email));
 

  const [duration, setDuration] = useState(8000)
  const navigate = useNavigate();
  const isSiteCreated = (useSelector(state => state.userHtml.isSiteCreated));
  console.log("Code:900 Progress.... ")
  const { credId } = useParams()
  const dispatch = useDispatch();
  const postData = {
    idea: Idea,
    name: Name,
    color: Color,
    siteId: siteId,
    fonts: Fonts,
    sectionArray: Section,
  };
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080';
  const apiUrl = `${apiEndpoint}/helloworld/generative/body`
  let allImages = []
  var theUsedImages = [];
  const fetchData = async (queries) => {
    try {
      const API_KEY = "Y6PWTHGKl6t5mNZUPIdzeKvBpnbm8hGylJE6Z6AkO81VAcjnl51xeCeX";
      for (const query of queries) {
        const response = await fetch(`https://api.pexels.com/v1/search?query=${query}&per_page=15&orientation=landscape`, {
          headers: {
            Authorization: API_KEY
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch images');
        }
        const data = await response.json();
        const images = data.photos.map(photo => photo.src.large);
        allImages = allImages.concat(images);
      }

      console.log("Code 13 : ", allImages)
      dispatch(setImages(allImages));
      return allImages;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };


  async function insertWebsite(subdomain, userEmail, Title, Description, credId) {
    console.log("The Subdomain = >", subdomain);
    
    // Step 1: Insert website data
    const { data, error } = await supabase
      .from('websites')
      .insert({
        domain: subdomain,
        lastUpdate: new Date(),
        title: Title,
        description: Description,
        userId: credId,
        status: 2,
        primaryDomain: `${subdomain}.helloworld.to`,
        homePage: 'Home',
        email: userEmail
      });
  
    if (error) {
      console.error('Error inserting website:', error.message);
      return null;
    }
  
    console.log('Insert successful for subdomain:', subdomain);
  
    // Step 2: Update the wishlist where email matches userEmail
    const { data: wishlistData, error: wishlistError } = await supabase
      .from('wishlist')
      .update({ isSiteCreated: true })
      .eq('email', userEmail);
  
    if (wishlistError) {
      console.error('Error updating wishlist:', wishlistError.message);
      return null;
    }
  
    console.log('Wishlist updated successfully for email:', userEmail);
  
    return data;
  }
  
 



  async function saveData(theUsedImages, allImages) {
    const { data, error } = await supabase
      .from('histories')
      .insert({
        webIdea: Idea,
        webName: Name,
        webColor: Color,
        userData: {
          data: Data,
          pages: Pages,
          index: index,
          history: history,
          isSiteCreated: isSiteCreated
        },
        userAssets:
        {
          images: allImages,
          queries: Queries,
          used: theUsedImages
        },
        userId: credId,
        webSectionType: Section,
        webTheme: {
          hue: Hue,
          fonts: Fonts
        },
        siteId: siteId,
        history: history,
        domain: subdomainId

      });
    if (error) {
      console.error('Error upserting data:', error);
    } else {
      console.log('Data upserted successfully:', data);
    }

  }



  const replaceImagesInHTML = (htmlString, images) => {
    console.log(" Code 13 :  ALL Images that are being used =>", images);
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgElements = doc.querySelectorAll('img:not([name="logo-image"]):not([name="person-image"]):not([name="static-image"])');
    const uniqueImages = images.filter(image => !theUsedImages.includes(image));
    console.log(" Code 13 :  ALL Images that are being used =>", uniqueImages);
    imgElements.forEach((img, index) => {
      if (index < uniqueImages?.length) {

        const imageUrl = uniqueImages[index];
        img.setAttribute('src', imageUrl);
        theUsedImages.push(imageUrl); // Track used images
        console.log(`Setting image ${imageUrl}`);
      }
    });
    console.log("sending", doc.body.innerHTML);
    return doc.body.innerHTML;
  };



  useEffect(() => {
    console.log("Code15 Render EFFECT")
    fetchData(query)
      .then(images => {
        console.log("Code:900 Images set successfully:", images);
        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        })
          .then((response) => response.json())
          .then((data) => {
            const sections = data.sections;
            dispatch(addPage({ pageName: 'Home' }));
            dispatch(addPage({ pageName: 'Contact' }));
            dispatch(addPage({ pageName: 'About' }));
            dispatch(addPage({ pageName: 'temp' }));
            dispatch(setPageIndex("Home"))
            var index_=1;
            console.log("Retrieved Sections. ", sections)
            const pages = ['Home', 'Contact', 'About', 'temp'];
            pages.forEach(pageName => {
              sections.forEach((section, index) => {
                console.log("Calling ", index_)
                index_ =index_ +1;
                if (pageName === "Home" ) {
                  if(section.sectionType !== 10) {
                    const modifiedSection = { ...section, htmlTemplate: replaceImagesInHTML(section.htmlTemplate.replace(/@logoimage/g, `${Logo}`).replace(/@sitename/g, `${Name}`).replace(/@logoimage/g, `${Logo}`), images) };
                    dispatch(addSectionToPage({ pageName: `Home`, section: modifiedSection }));
                  }
                }
                else {
                  if(section.sectionType === 0 || section.sectionType === 9 || section.sectionType === 10) {
                  const modifiedSection = { ...section, htmlTemplate: replaceImagesInHTML(section.htmlTemplate.replace(/@pagename/g, `${pageName}`).replace(/@sitename/g, `${Name}`).replace(/@logoimage/g, `${Logo}`), images) };
                  dispatch(addSectionToPage({ pageName: `${pageName}`, section: modifiedSection }));
                  }
                }
              });
            });

            dispatch(setIsSiteCreated(1))
            dispatch(addToHistory())
            setDuration(300)
            setTimeout(() => {
              console.log("Code14 :  ALL Images that are being used =>", theUsedImages);
              console.log("Code14 :  ALL Used that are being used =>", allImages);
              saveData(theUsedImages, allImages)
              insertWebsite(subdomainId)
              navigate(`/redirecting/${credId}`);
            }, 1000);
          })
          .catch((error) => {
            showRejectMessage("Error Compiling the section try again later...");
            console.error('Error fetching API:', error);
          });
      })
      .catch(error => {
        showRejectMessage("Error integrating the images try again later...");
        console.error('Error fetching images:', error);
      });
  }, []);


  return (
    <div className='flex items-center justify-center min-h-screen flex-col'>
      <MultiStepLoader loadingStates={loadingStates} loading={true} duration={duration} />
      <ToastContainer />
    </div>

  )
}

export default Progress


