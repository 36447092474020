import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setDomain, setName_ } from "../../../Store/Slices/NameSlice";
import favicon from "../../../assets/blackborderfavicon.svg"
import "../global_theme/theme.css"; // Replace with your themei
import {
  setImageQuery,
  setImages,
} from "../../../Store/Slices/ImageQuerySlice";
import { setSection } from "../../../Store/Slices/SectionSlice";
import {
  setColor,
  setColorArray,
  setFonts,
  setSiteId,
  setHue,
  setTitle,
  setDescription,
} from "../../../Store/Slices/ColorSlice";
import "../../SignIn/spinner.css";
import { PlaceholdersAndVanishInput } from "../../HelloWorldWeb/components/PlaceholdersAndVanishInput";
import supabase from "../../../config/supabse";


function Name() {
  function cleanString(input) {
    // Remove special characters, keep only letters, digits, and hyphens
    let cleanedString = input.replace(/[^a-zA-Z0-9-]/g, "");

    // Convert to lowercase
    cleanedString = cleanedString.toLowerCase();

    return cleanedString;
  }

  const { credId, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idea = useSelector((state) => state.Idea.data);
  const name = useSelector((state) => state.Name.data);

  const [text, setText] = useState(name);
  useEffect(() => {
    const checkAndSetUniqueDomain = async (initialText) => {
      let isUnique = false;
      let newDomain = '';
      let attempts = 0;
      const maxAttempts = 10; // Just a safety net to avoid infinite loops
  
      while (!isUnique && attempts < maxAttempts) {
        const randomNumbers = Math.floor(100 + Math.random() * 900);
        newDomain = cleanString(`${initialText}${randomNumbers}`);
  
        // Check if the domain exists in the database
        const { data, error } = await supabase
          .from('websites')
          .select('domain')
          .eq('domain', newDomain)
          .single();
  
        if (error && error.code !== 'PGRST116') {
          // Handle other possible errors
          console.error('Error checking domain:', error);
          return;
        }
  
        if (!data) {
          // If no data is found, the domain is unique
          isUnique = true;
        }
  
        attempts++;
      }
  
      if (isUnique) {
        dispatch(setDomain(newDomain));
      } else {
        console.error('Failed to generate a unique domain after multiple attempts.');
      }
    };
  
    dispatch(setName_(text));
    checkAndSetUniqueDomain(text);
  }, [text, dispatch]);

  const handleTextChange = (newValue) => {
    setText(newValue);
  };

  const [imgQuery, setImageQuery_] = useState("");
  const handleTextChange2 = (newValue) => {
    setImageQuery_(newValue);
  };

  useEffect(() => {
    dispatch(setImageQuery(imgQuery));
    console.log("up --- ", imgQuery);
  }, [imgQuery]);

  const handleClick = async () => {
    // navigate(`/logo/${credId}/${token}`);
    // Example usage:

    const data = {
      Name: text,
      Idea: idea,
    };

    setSettingTheme(true);
    const apiEndpoint =
      process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080";
    const apiUrl = `${apiEndpoint}/helloworld/generative/metadata`;
    const response = await postApiCall(apiUrl, data);
    dispatch(setColor(response.colors));
    dispatch(setSiteId(response.siteId));
    console.log("Theme : ", response.theme);
    console.log("Response : >>> ", response);
    dispatch(setHue(response.theme.hue));
    dispatch(setFonts(response.theme.fonts));
    dispatch(setTitle(response.theme.title));
    dispatch(setDescription(response.theme.description));
    dispatch(setImageQuery(response.theme.queries));
    setSettingTheme(false);
    dispatch(setSection(0));
    dispatch(setSection(1));
    dispatch(setSection(4));
    dispatch(setSection(6));
    dispatch(setSection(2));
    dispatch(setSection(6));
    dispatch(setSection(4));
    dispatch(setSection(7));
    dispatch(setSection(10));
    dispatch(setSection(9));
    navigate(`/logo/${credId}/${token}`);
  };

  async function postApiCall(url, data) {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response HERE:", responseData);
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  }

  const [settingTheme, setSettingTheme] = useState(false);

  const placeholders = ["Name", "Title"];

  return (
    <div className="flex items-center justify-center min-h-screen ">
      <div className="absolute top-[10%] flex items-center justify-center flex-col">
        <img
           src={favicon}
          alt=""
          className="w-[60px] h-[60px]"
        />
        <h3 className=" text-5xl max-[400px]:text-xl font_inter  mt-4">
          HelloWorld
        </h3>
      </div>
      <div className="flex items-center justify-center flex-col p-2 w-auto">
        <div className="flex items-start justify-center flex-col p-6 mt-6 w-[40vw] max-[600px]:mx-7   ">
          <h3 className="text-xl max-[500px]:ml-1 max-[500px]:text-md font-normal font_inter mt-2 ml-3">
            What is the name of your website?
          </h3>

          <div className="w-full rounded-lg flex flex-row items-center justify-between px-2 py-1 mt-2 ">
            <PlaceholdersAndVanishInput
              placeholders={placeholders}
              onChange={(e) => handleTextChange(e.target.value)}
              onSubmit={handleClick}
            />

            {!settingTheme ? <></> : <div className="spinner ml-3"> </div>}
          </div>

          <p
            onClick={() => {
              navigate(`/idea/${credId}/${token}`);
            }}
            className="font-medium mt-2 font_inter text-md cursor-pointer ml-3 hover:text-zinc-600 "
          >
            &lt; Back
          </p>
        </div>
      </div>
    </div>
  );
}

export default Name;
