import React from "react";

import Ellipse from "../../DashboardAssets/images/ellipse.svg";
import { useNavigate, useParams } from "react-router-dom";
const Card = ({data, unReadCount}) => {
  const {credId} = useParams()
  const navigate = useNavigate()
  return (
    <>
      <div className="card flex flex-col relative  overflow-hidden bg-white border-2 border-gray-100 rounded-[20px] text-[#000000] w-[26vw] h-[22vh] justify-between items-center">
        <div className="w-full p-4">
          <div className="text px-4 text-[#000000] font-manrope font-semibold leading-[20px] p-4 flex flex-col text-[16px] tracking-[-3%]">
            <p className="subtitle pl-2">6 Contacts</p>
          </div>
          <div className="flex px-3 flex-row justify-between items-center w-full"> 
            {/* <div className="  flex items-center ">
            <div className="flex items-center justify-center bg-[#f0f0f0] rounded-full h-12 w-12">
              <span className="font-manrope font-semibold leading-[24px] text-[20px]">
                2
              </span>
            </div>
            <h2 className="font-manrope font-normal text-[15px] leading-[24px] pl-4">
              New Leads
            </h2>
          </div> */}
          <div className="  py-2 flex items-center ">
            <div className="flex items-center justify-center bg-gray-100 rounded-full h-12 w-12">
              <span className="font-manrope font-semibold leading-[24px] text-[20px]">
              {unReadCount}
              </span>
            </div>
            <h2 className="font-manrope font-normal text-[15px] leading-[24px] pl-4">
              Unread Messages
            </h2>
          </div>

          </div>
         
        </div>
        <div className="card-footer px-10 py-5 flex justify-between items-center bg-gray-50 w-full rounded-b-[20px] overflow-hidden">
          <h2 className="font-manrope font-semibold tracking-[-3%] leading-[24px] text-[16px]">
            CRM
          </h2>
          <button
          onClick={()=>{navigate(`/dashboard/${credId}/crm`)}}
          className="bg-white border-2 border-gray-200 hover:border-gray-300 px-6 py-1 rounded-lg">
            View
          </button>
        </div>
      </div>
    </>
  );
};

export default Card;
