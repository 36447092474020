import { useDispatch } from "react-redux";
import "./App.css";
import Idea from "./components/Builder_prompt_components/Idea/Idea";
// import Name from './components/Builder_prompt_components/Name/Name';
// import Progress from './components/Builder_prompt_components/Progress/Progress';
// import LogoUpload from './components/Builder_prompt_components/logo/LogoUpload';
import Signin from "./components/SignIn/Signin";
import { setPageIndex } from "./Store/Slices/userHtmlSlice";
import HomePage from "./components/HelloWorldWeb/pages/HomePage";
import supabase from "./config/supabse";
import { useEffect, useState } from "react";
import { set_access_token, setUserEmail, setUserId } from "./Store/Slices/userIdSlice";
import { useNavigate } from "react-router-dom";
// import Mainpage from './components/MainPage/Mainpage';
// import { useState } from 'react';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setPageIndex("Home"));
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }

      if (data) {
        if (
          data &&
          data.session &&
          data.session.user.id &&
          data.session.access_token
        ) {
          const userId = data.session.user.id;
          const accessToken = data.session.access_token;
          const email = data.session.user.email
          setId(userId);
          setToken(accessToken);
          dispatch(setUserId(userId));
          dispatch(setUserEmail(email))
          dispatch(set_access_token(accessToken));
        }
      }
    };

    getSession();
  }, [dispatch, navigate]);

  return (
    <div>
      <HomePage credId={id} token={token} />
    </div>
  );
}

export default App;
