import React from "react";
import WebsiteSettings from "../DashboardComponents/WebsiteSettings/WebsiteSettings";
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";

const Settings = () => {
  return (
    <>
      {" "}
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
      <HorizontalHeader headerTitle={"Settings"} />
        <div className="flex-1 overflow-auto p-4">
          <WebsiteSettings />
        </div>
      </div>
    </>
  );
};

export default Settings;
