import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="max-w-5xl px-8 py-20 mx-auto mt-20 space-y-16">
        <h2 className="text-[65px] font_manrope text-center">Privacy Policy</h2>
        <div class="space-y-6 font_manrope">
          <p>Effective Date: 13.08.24</p>
          <p>
            HelloWorld, Inc. ("we," "us," "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, and
            safeguard your personal information when you sign up for our email
            list or waitlist through our website, helloworld.to ("Website"). By
            providing your information, you agree to the terms outlined in this
            Privacy Policy.
          </p>

          <h2 class="font-bold text-lg">1. Information We Collect</h2>
          <h3 class="font-bold text-lg">1.1 Personal Information</h3>
          <p>
            When you sign up for our email list or waitlist, we collect the
            following personal information:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>Name:</strong> Your full name, which helps us personalize
              our communication with you.
            </li>
            <li>
              <strong>Email Address:</strong> Used to send you updates,
              announcements, and other relevant information about HelloWorld,
              Inc.
            </li>
          </ul>
          <h3 class="font-bold text-lg">1.2 Non-Personal Information</h3>
          <p>
            We may also collect non-personal information automatically when you
            visit our Website, including:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>IP Address:</strong> Helps us understand how our visitors
              use the Website.
            </li>
            <li>
              <strong>Browser Information:</strong> Information about the
              browser you use, which helps us optimize the Website for better
              performance.
            </li>
            <li>
              <strong>Cookies:</strong> Small data files stored on your device
              that help us track your interaction with the Website. You can
              control cookie settings through your browser preferences.
            </li>
          </ul>

          <h2 class="font-bold text-lg">2. How We Use Your Information</h2>
          <h3 class="font-bold text-lg">2.1 Personal Information</h3>
          <p>We use your personal information to:</p>
          <ul className="list-disc list-inside">
            <li>
              Send you email updates about our products, services, and special
              offers.
            </li>
            <li>
              Notify you of important changes to our services or policies.
            </li>
            <li>Respond to any inquiries or requests you may have.</li>
          </ul>
          <h3 class="font-bold text-lg">2.2 Non-Personal Information</h3>
          <p>Non-personal information is used to:</p>
          <ul>
            <li>
              Improve the functionality and user experience of our Website.
            </li>
            <li>
              Analyze trends and gather demographic information for internal
              purposes.
            </li>
          </ul>

          <h2 class="font-bold text-lg">3. How We Protect Your Information</h2>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information. These measures include:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>Encryption:</strong> Sensitive information is transmitted
              via Secure Socket Layer (SSL) technology and encrypted in our
              database.
            </li>
            <li>
              <strong>Access Control:</strong> Only authorized personnel have
              access to your personal information.
            </li>
          </ul>

          <h2 class="font-bold text-lg">4. Sharing Your Information</h2>
          <p>
            We do not sell, trade, or otherwise transfer your personal
            information to third parties except in the following circumstances:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>Service Providers:</strong> We may share your information
              with trusted third-party service providers who assist us in
              operating our Website or conducting our business, as long as these
              parties agree to keep your information confidential.
            </li>
            <li>
              <strong>Legal Compliance:</strong> We may disclose your
              information if required by law or in response to legal processes,
              to protect our rights or the rights of others, or to enforce our
              policies.
            </li>
          </ul>

          <h2 class="font-bold text-lg">5. Your Choices</h2>
          <h3 class="font-bold text-lg">5.1 Opting Out</h3>
          <p>
            You can unsubscribe from our email communications at any time by
            clicking the "unsubscribe" link in any email we send you or by
            contacting us directly at{" "}
            <a href="mailto:hello@helloworld.to">hello@helloworld.to</a>.
          </p>
          <h3 class="font-bold text-lg">
            5.2 Accessing and Updating Your Information
          </h3>
          <p>
            If you wish to access or update your personal information, please
            contact us at{" "}
            <a href="mailto:hello@helloworld.to">hello@helloworld.to</a>. We
            will respond to your request within a reasonable timeframe.
          </p>

          <h2 class="font-bold text-lg">6. Third-Party Links</h2>
          <p>
            Our Website may contain links to third-party websites. These
            websites have their own privacy policies, and we are not responsible
            for their content or practices. We encourage you to review the
            privacy policies of any third-party sites you visit.
          </p>

          <h2 class="font-bold text-lg">7. Changes to This Privacy Policy</h2>
          <p>
            We reserve the right to update or change this Privacy Policy at any
            time. If we make significant changes, we will notify you via email
            or through a prominent notice on our Website. Your continued use of
            our Website following the posting of changes constitutes your
            acceptance of those changes.
          </p>

          <h2 class="font-bold text-lg">8. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <address>
            HelloWorld, Inc.
            <br />
            San Francisco
            <br />
            <a href="mailto:hello@helloworld.to">hello@helloworld.to</a>
          </address>
          <p>
            By using our Website and providing your information, you consent to
            the terms of this Privacy Policy.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
