import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GoSmiley } from "react-icons/go";
import supabase from '../../config/supabse';
import { setPlanDetails_, setPlanId_ } from '../../Store/Slices/userDetailsSlice';

function SuccessScreen() {
    const [userId, setUserId] = useState('')

    const dispatch = useDispatch()
    const fetchPlanById = async (planId) => {
        const { data, error } = await supabase
            .from('plans')
            .select('*')
            .eq('planId', planId)

        if (error) {
            console.error("Error fetching plan:", error.message);
        } else if (data) {
            console.log("THe Plan: ", data)
            dispatch(setPlanId_({ planId: planId }))
            dispatch(setPlanDetails_({ planDetails: data[0] }))
        }
    };


    useEffect(() => {
        const getSession = async () => {
            const { data, error } = await supabase.auth.getSession();
            if (error) {
                console.error("Error retrieving session:", error.message);
                return;
            }
            if (data) {
                if (
                    data &&
                    data.session &&
                    data.session.user.id &&
                    data.session.access_token
                ) {
                    let userId = data.session.user.id;
                    setUserId(userId)
                    let accessToken = data.session.access_token;
                    let email = data.session.user.email
                    console.log("In func", email)
                    console.log(data)
                    const { data: userPlanData, error: userPlanError } = await supabase
                        .from('userPlans')
                        .select('*')
                        .eq('userId', userId)

                    if (userPlanError) {
                        console.error("Error checking user plan:", userPlanError.message);
                    }
                    else if (!userPlanData || userPlanData?.length === 0) {
                        const { error: upsertError } = await supabase
                            .from('userPlans')
                            .upsert([{ userId: userId, planId: 1 }]);

                        if (upsertError) {
                            console.error("Error upserting user plan:", upsertError.message);
                        } else {
                            console.log("User plan upserted successfully.");
                        }
                    }
                    else {
                        console.log("User plan already exists:", userPlanData);
                        fetchPlanById(userPlanData[0].planId)
                    }
                }
            }
        };
        getSession();
    }, [])


    return (
        <div className='w-full h-[100vh] overflow-hidden bg-white flex items-center flex-col justify-center'>

            <div className='flex flex-col items-center justify-center gap-1'>
                <GoSmiley className='text-8xl text-yellow-500' />

                <p className='text-zinc-900 text-2xl mt-2 '>Thank You for Your Subscription!</p>
                <p className='text-zinc-500 text-xl  '>A payment to Helloworld will appear on your statement.</p>

                {userId ? (<a href={`/dashboard/${userId}/billing`} className='rounded-full bg-black text-white px-5 py-1 mt-3'>

                    Dashboard {" ->"}
                </a>) : (<a href={`/`} className='rounded-full bg-black text-white px-5 py-1 mt-3'>

                   Home {" ->"}
                </a>)}

            </div>


        </div>
    )
}

export default SuccessScreen