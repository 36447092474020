import HomeIcon from "../../DashboardAssets/images/home-icon.svg";
import OverviewIcon from "../../DashboardAssets/images/menu-icon.svg";
import WebsiteIcon from "../../DashboardAssets/images/globe-icon.svg";
import PeopleIcon from "../../DashboardAssets/images/people-icon.svg";
import Logo from "../../../../assets/favicon.png";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import favicon from "../../../../assets/favicon2.png"
import supabase from "../../../../config/supabse";


function Sidebar() {

  const subdomainId = useSelector((state) => { return state.Name.subdomain })
  const iconStyle = {
    fontVariationSettings: "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24",
  };

  const { credId } = useParams();
  const variants = {
    hidden: { height: 0, overflow: 'hidden' },
    visible: { height: 'auto', overflow: 'hidden' },
  };


  function getLastTokenFromPath() {
    const path = window.location.pathname;
    const tokens = path.split('/');
    return tokens[tokens?.length - 1] || '/'; // Return '/' if path is empty
  }

  // Example usage:
  const lastToken = getLastTokenFromPath();
  console.log(lastToken)

  const [unReadCount, setUnReadCount] = useState(0)
  useEffect(() => {

    async function fetchUnreadMessages(subDomainId) {
      try {
        const { data, error } = await supabase
          .from('userMessages')
          .select('*')
          .eq('siteId', subDomainId)
          .eq('isRead', false);

        if (error) {
          throw error;
        }

        console.log('Unread messages fetched successfully:', data);
        setUnReadCount(data.length)
        return data;
      } catch (error) {
        console.error('Error fetching unread messages:', error);
        throw error; // Optionally rethrow error for further handling
      }
    }

    fetchUnreadMessages(subdomainId)
  }, [])





  const [myId, setMyId] =useState('0')
  useEffect(()=>{
    const getSession = async () => {
        const { data, error } = await supabase.auth.getSession();
        if (error) {
          console.error("Error retrieving session:", error.message);
          return;
        }
        if (data) {
          if (
            data &&
            data.session &&
            data.session.user.id &&
            data.session.access_token
          ) {
            setMyId(data.session.user.id)
          }
        }
      };
      getSession();
},[credId])


  return (
    <>
      <div id="hs-application-sidebar" class="hs-overlay  [--auto-close:lg] flex-col w-[20vw] h-screen top-0 sticky  border-r border-[#E5E5E5] overflow-hidden hidden lg:flex" role="dialog" tabindex="-1" aria-label="Sidebar">
        <div class="relative flex flex-col h-full max-h-full">
          <div class="px-6 pt-4 mt-2">

            <a class="flex-none rounded-xl text-xl flex-row gap-2 flex items-center  font-semibold focus:outline-none focus:opacity-80" href="#" aria-label="Preline">
              <img src={favicon} alt="" />
              <h1>HelloWorld</h1>
            </a>

          </div>


          <div class="h-full mt-3 overflow-y-auto  flex flex-col pb-5 justify-between [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
            <nav class="hs-accordion-group p-3 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
              <ul class="flex flex-col space-y-1">
                {/* <li>
                  <a class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500  hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-700 dark:text-white" href="#">
                  <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      home
                    </span>
                    Dashboard
                  </a>
                </li> */}


                <li>
                  <Link to={`/dashboard/${credId}/`} class={`flex items-center gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500  focus:outline-none ${lastToken === "/" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      dashboard
                    </span>

                    Overview
                  </Link>
                </li>


                <li>
                  <Link to={`/dashboard/${credId}/domain`} class={`flex items-center gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500 focus:outline-none ${lastToken === "domain" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      language
                    </span>

                    Domain
                  </Link>
                </li>

                <li>
                  <Link to={`/dashboard/${credId}/analytics`} class={`flex items-center gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500 focus:outline-none ${lastToken === "analytics" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      trending_up
                    </span>

                    Analytcs
                  </Link>
                </li>



                {/* <li class="hs-accordion" id="projects-accordion">
                  <button type="button" onClick={() => { setProjectSubMenu(!projectSubMenu) }} class={`hs-accordion-toggle manrope-500 ${lastToken==="crm" || lastToken==="contact-details" ? ("bg-gray-100") : ("bg-transparent]")} w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none  dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200`} aria-expanded="true" aria-controls="projects-accordion-child">
                  <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                     library_books
                    </span>
                    CRM

                    <motion.svg
                      initial={{ rotate: 180 }}
                      animate={{ rotate: projectSubMenu ? 0 : 180 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="hs-accordion-active:block ms-auto flex size-4"
                    >
                      <path d="m18 15-6-6-6 6" />
                    </motion.svg>


                  </button>

                  <motion.div
                    initial="hidden"
                    animate={projectSubMenu ? "visible" : "hidden"}
                    variants={variants}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    style={{

                      width: '100%', // Full width
                      height: 'auto', // Auto height to fit content
                    }}
                    id="projects-accordion-child"
                    role="region"
                    aria-labelledby="projects-accordion"
                  >
                    <ul className="ps-8 pt-1 space-y-1 w-full">
                      <li>
                        <Link to={`/dashboard/${credId}/crm`} className={`flex ${lastToken==="crm" ? ("bg-gray-100") : ("bg-transparent]")} w-full manrope-500 items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none  dark:bg-neutral-800 dark:text-neutral-200`} href="#">
                        <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      stacked_email
                    </span>
                          CRM Home
                        </Link>
                      </li>
                      <li>
                        <Link to={`/dashboard/${credId}/contact-details`} className={`flex ${lastToken==="contact-details" ? ("bg-gray-100") : ("bg-transparent]")} w-full manrope-500 items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none  dark:bg-neutral-800 dark:text-neutral-200`} href="#">
                        <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                     contacts
                    </span>
                         Contacts
                        </Link>
                      </li>
                      
                    </ul>
                  </motion.div>
                </li>
 */}
                <li className={`flex flex-row items-center justify-between ${lastToken === "crm" ? ("bg-gray-100") : ("bg-transparent]")} hover:bg-gray-100 rounded-lg  `}>
                  <Link to={`/dashboard/${credId}/crm`} className={`flex  w-full manrope-500 items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800  focus:outline-none  dark:bg-neutral-800 dark:text-neutral-200`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      stacked_email
                    </span>
                    CRM
                  </Link>
                   { unReadCount>0 && <div className=" h-[20px] w-[20px] p-3 bg-yellow-500 flex items-center justify-center rounded-full mr-2">
                    <p className="text-white ">{unReadCount}</p>
                  </div>}
                </li>

                <li>
                  <Link to={`/dashboard/${credId}/contact-details`} className={`flex ${lastToken === "contact-details" ? ("bg-gray-100") : ("bg-transparent]")} w-full manrope-500 items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none  dark:bg-neutral-800 dark:text-neutral-200`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      contacts
                    </span>
                    Contacts
                  </Link>
                </li>


                <li>
                  <Link to={`/dashboard/${credId}/upgrade-plan`} class={`flex items-center gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500 focus:outline-none ${lastToken === "upgrade-plan" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      Package
                    </span>

                    Plans
                  </Link>
                </li>



                {credId && myId && credId===myId && <li>
                  <Link to={`/dashboard/${credId}/billing`} class={`flex items-center gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500 focus:outline-none ${lastToken === "billing" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      receipt_long
                    </span>

                    Billing
                  </Link>
                </li>}

                {credId && myId && credId===myId &&  <li>
                  <Link to={`/dashboard/${credId}/settings`} class={`flex items-center gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500  focus:outline-none ${lastToken === "settings" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                      settings
                    </span>

                    Settings
                  </Link>
                </li>}


                { (myId === '4effe997-892d-4482-98bc-b466af43cc59' || myId==='0c77b554-7b1a-416c-bc90-c4fb846347ef' || myId==='8eb87a66-915b-404e-aaae-5d5cc44578ca' || myId==='4d9c8483-03be-4ffa-98cc-6ef6df356abe') && <li>
                  <Link to={`/dashboard/${credId}/admin-editor`} class={`flex items-center gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500  focus:outline-none ${lastToken === "settings" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                    <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                     edit
                    </span>

                   Admin Editor
                  </Link>
                </li>}









              </ul>



            </nav>
            <div className="px-3">
              <Link to={`/`} class={`flex items-center self-end justify-self-end gap-x-3.5 py-2 px-2.5 hover:bg-gray-100 text-sm text-gray-800 rounded-lg manrope-500  focus:outline-none ${lastToken === "logout" ? ("bg-gray-100") : ("bg-transparent]")}  dark:bg-neutral-700 dark:text-white`} href="#">
                <span className="material-symbols-outlined text-[20px] font-thin" style={iconStyle}>
                  logout
                </span>

                Logout
              </Link>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Sidebar;




{/* <div className=" md:flex flex-col w-[20vw] h-screen top-0 sticky  border-r border-[#E5E5E5] overflow-hidden ">
<div className="flex flex-col flex-1 overflow-y-auto items-center justify-center bg-white">
  <nav
    className="flex flex-col flex-1 overflow-hidden px-6 py-4 gap-10"
  >
    <div className="flex flex-col items-center justify-center gap-1 mt-10">
      <span class="material-symbols-outlined text-[40px]">
        public
      </span>
      <Link
   to={`/dashboard/${credId}/`}
        className={`flex items-center px-4 py-2 font-manrope font-medium text-[18px] leading-[10px] text-gray-900   hover:text-gray-900 mt-1`}
      >

    {    Name}
      </Link>
    </div>
    <div className="flex flex-col flex-1 gap-3 mt-7">
      <Link
        to={`/dashboard/${credId}/`}
        className={`flex items-center px-4 py-2 mt-2 font-inter ${lastToken==="/" ? ("font-bold text-black") : ("font-normal text-[#8E8E8E]")}    hover:text-gray-900 hover:font-bold `}
      >
        <span className="material-symbols-outlined mr-6" style={iconStyle}>
          dashboard
        </span>

        Overview
      </Link>
      <Link
        to={`/dashboard/${credId}/domain`}
        className={`flex items-center px-4 py-1 mt-1 font-inter leading-[26px]  ${lastToken==="domain" ? ("font-bold text-black") : ("font-normal text-[#8E8E8E]")}  hover:text-gray-900`}
      >
         <span className="material-symbols-outlined mr-6" style={iconStyle}>
          globe
        </span>


        Website
      </Link>


      <Link
        to={`/dashboard/${credId}/crm`}
        className={`flex items-center px-4 py-1 mt-1 font-inter ${lastToken==="crm" || lastToken==="contact-details" ? ("font-bold text-black") : ("font-normal")} leading-[26px] text-[#8E8E8E]  hover:text-gray-900`}
      >
        {" "}

        <span className="material-symbols-outlined mr-6" style={iconStyle}>
        library_books
        </span>
        CRM
      </Link>


      <Link
        to={`/dashboard/${credId}/analytics`}
        className={`flex items-center px-4 py-1 mt-1 font-inter  leading-[26px] ${lastToken==="analytics" ? ("font-bold text-black") : ("font-normal text-[#8E8E8E]")} leading-[26px]  hover:text-gray-900`}
      >
      <span className="material-symbols-outlined mr-6" style={iconStyle}>
          trending_up
        </span>
        Analytics
      </Link>

      <Link
      to={`/dashboard/${credId}/settings`}
       
        className={`flex items-center px-4 py-1 mt-1s font-inter  ${lastToken==="settings" ? ("font-bold text-black") : ("font-normal text-[#8E8E8E]")}  leading-[26px]  hover:text-gray-900 `}
      >
        <span className="material-symbols-outlined mr-6" style={iconStyle}>
          settings
        </span>
        Settings
      </Link>

      <Link
      to={`/dashboard/${credId}/upgrade-plan`}
       
        className={`flex items-center px-4 py-1 mt-1s font-inter  ${lastToken==="upgrade-plan" ? ("font-bold text-black") : ("font-normal text-[#8E8E8E]")}  leading-[26px]  hover:text-gray-900 `}
      >
        <span className="material-symbols-outlined mr-6" style={iconStyle}>
        package
        </span>
       Plans
      </Link>


      {/* <Link
      to={`/dashboard/${credId}/usage-details`}
       
        className={`flex items-center px-4 py-1 mt-1s font-inter  ${lastToken==="usage-details" ? ("font-bold text-black") : ("font-normal text-[#8E8E8E]")}  leading-[26px]  hover:text-gray-900 `}
      >
        <span className="material-symbols-outlined mr-6" style={iconStyle}>
        analytics
        </span>
       Usage
      </Link> 


      <Link
      to={`/dashboard/${credId}/billing`}
       
        className={`flex items-center px-4 py-1 mt-1s font-inter  ${lastToken==="billing" ? ("font-bold text-black") : ("font-normal text-[#8E8E8E]")}  leading-[26px]  hover:text-gray-900 `}
      >
        <span className="material-symbols-outlined mr-6" style={iconStyle}>
        receipt_long
        </span>
      Billing
      </Link>

    </div>
    <div>
      <Link
        to="/"
        className="flex items-center px-4 py-1 font-manrope font-medium text-[2vh] leading-[10px] text-gray-900   hover:text-gray-900 mt-1"
      >
        <img src="https://s3-alpha-sig.figma.com/img/5d38/8ef1/4132673483685c0f3db20372e56af47c?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=H~3sn-~v0-laiJhRlcafD9HI5BA2XWcsEdsx9XXCQS3t27yoV-kxYlUg1Tt2NDmtrI3ZzbcLMNHCXtBOcHa1nnCnaKrxiS47wKeiPaipsh6V395VhJUsy8YmxyRyK5BdAiO1sqZJXvRfMmGErCExZuARY0iN8W5Ic1JuM2ZmvkHzliMypBdAPGqQU2rwcVMKJcTwPd9eZCUotPxG8CT--W235FC-9rLvu7wOLsryS8vVPok5q5kHFhxpS6CnBOXUk6uaTLer4TsYI94ldtdislKp0ZrYly5JuxJQ2oN8Zq0zWELRBKqKWpk7ju6aig3YgNrZ25BmwV1oYO6UtvqqNQ__" alt="" className='w-[1.5vw] h-[1.5vw] mr-3' />
        HelloWorld
      </Link>
    </div>
  </nav>
</div>
</div> */}
