import React from "react";
import Overview from "../DashboardComponents/Home/Overview";
import Header from "../DashboardComponents/Home/Header";
import Sidebar from "../DashboardComponents/Sidebar/Sidebar";
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";

const DashboardHome = () => {
  return (
    <div className="flex flex-col w-full h-[100vh] overflow-hidden">
      <HorizontalHeader headerTitle={"Overview"} />
      <div className="flex-1 overflow-auto p-4">
        <Overview />
      </div>
    </div>
  );
};

export default DashboardHome;
