import { createSlice } from '@reduxjs/toolkit';

// Reducer
const userSiteAnalyticsSlice = createSlice({
  name: 'site',
  initialState: {
    id: "uuid",
    title:"title",
    description:"description",
    sitlink:"sitelink",
    analytics:{

    },
    notification:{

    },
   
  },
  reducers: {
    setUserSiteAnalytics: (state, action) => {
      state.id = action.payload.id
      state.title =action.payload.tittle
      state.description = action.payload.description
      state.analytics = action.payload.analytics
      state.notification= action.payload.notification
    },
    
  },
});

// Export the reducer and actions
export default userSiteAnalyticsSlice.reducer;
export const { setColor} = userSiteAnalyticsSlice.actions;