import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PricingPlans from "../components/PricingPlans";
import WaitList from "../components/WaitList";

function PricingPage() {
  return (
    <div>
      <Navbar />
      <div className="mt-20">
        <PricingPlans />
      </div>
      <div className="mt-10"></div>
      <WaitList />
      <Footer />
    </div>
  );
}

export default PricingPage;
