import { createSlice } from '@reduxjs/toolkit';
import { arrayMove } from "@dnd-kit/sortable";

// Reducer
const cleanUpPages = (pages) => {
  const cleanedPages = { ...pages };
  if (cleanedPages && cleanedPages.hasOwnProperty('undefined') && Array.isArray(cleanedPages.undefined) && cleanedPages.undefined?.length === 0) {
    delete cleanedPages.undefined;
  }
  return cleanedPages;
};


const userHtmlSlice = createSlice({
  name: 'userHtml',
  initialState: {
    // data can be used for general data storage, if needed
    data: null,
    // pages is a 2D array where each page contains a dictionary of sections
    pages: null,
    // index tracks the current page index
    index: "Home",
    isSiteCreated: 0,
    history: null,
    pageHtml: null,

  },
  reducers: {

    setPageHtmlState: (state, action) => {

      state.pageHtml = action.payload
    },

    savePageState: (state, action) => {

      console.log("Save Page State = > ", action.payload)
      state.pages[action.payload.pageIndex] = action.payload.pageState
    },

    initalizeUserSlice: (state, action) => {
      console.log(action.payload.data)
      state.data = action.payload.pages["Home"]
      state.isSiteCreated = action.payload.isSiteCreated
      state.index = action.payload.index
      state.history = action.payload.history
      state.pages = action.payload.pages
    },

    setUserHtml: (state, action) => {
      state.data = state.pages["Home"]
    },

    setPages: (state, action) => {
      state.pages = action.payload;
      state.index = 0;
    },

    setPageIndex: (state, action) => {
      state.index = action.payload;
    },

    setIsSiteCreated: (state, action) => {
      state.isSiteCreated = action.payload
    },


    setHistory: (state, action) => {
      state.history = action.payload
    },

    addToHistory: (state) => {
      console.log("Added to the stack", state.history)
      const snapshot = {
        data: state.data,
        pages: state.pages,
        index: state.index,
        isSiteCreated: state.isSiteCreated,
      };
      console.log("Snappy ", snapshot);
      state.history.push(snapshot);
    },



    // Action to revert to previous state
    undo: (state, action) => {
      const { pages } = action.payload;
      console.log("Gotcha", pages)
      state.pages = []
      state.pages = pages
      state.history.pop()

    },



    // Action to add a new page
    addPage: (state, action) => {
      const { pageName } = action.payload;
      if (pageName !== undefined) {
        console.log("The PageName = > ", pageName)
        // Ensure that state.pages is initialized as an object
        if (!state.pages) {
          state.pages = {};
        }
        state.pages[pageName] = []; // Initialize the new page with an empty array
      }
      state.pages = cleanUpPages(state.pages);
    },

    duplicateRenamePage: (state, action) => {
      const { pageName, newName } = action.payload;
      if (pageName !== undefined && newName !== undefined) {
        console.log("The PageName = > ", pageName);
        if (!state.pages) {
          state.pages = {};
        }
        let finalNewName = newName;
        if (state.pages.hasOwnProperty(newName)) {
          // If newName already exists, find a unique name by appending a digit
          let counter = 1;
          while (state.pages.hasOwnProperty(finalNewName)) {
            finalNewName = `${newName}_${counter}`;
            counter++;
          }
        }
        if (state.pages.hasOwnProperty(pageName)) {
          const originalPage = state.pages[pageName];
          // Perform a deep copy of the original page
          const duplicatedPage = JSON.parse(JSON.stringify(originalPage));
          // Rename the duplicated page
          state.pages[finalNewName] = duplicatedPage;
          // Delete the original page
          // delete state.pages[pageName];
        } else {
          console.log(`Page '${pageName}' not found.`);
        }
      }
      state.pages = cleanUpPages(state.pages);
    },



    deletePage: (state, action) => {
      const { pageName } = action.payload;
      if (pageName !== undefined) {
        if (!state.pages) {
          state.pages = {};
        }
        if (state.pages.hasOwnProperty(pageName)) {
          // Delete the original page
          delete state.pages[pageName];
        } else {
          console.log(`Page '${pageName}' not found.`);
        }
      }

    },



    addSectionToPage: (state, action) => {
      const { pageName, section } = action.payload;

      // Check if the page exists in the state
      if (state.pages && state.pages[pageName]) {
        // Add the new section to the page
        state.pages[pageName].push(section);

        // Separate the sections into different categories
        const navbarSections = state.pages[pageName].filter(sec => sec.sectionType === 0);
        const heroSections = state.pages[pageName].filter(sec => sec.sectionType === 1);
        const footerSections = state.pages[pageName].filter(sec => sec.sectionType === 9);
        const otherSections = state.pages[pageName].filter(sec => sec.sectionType !== 0 && sec.sectionType !== 1 && sec.sectionType !== 9);

        // Combine them in the desired order
        state.pages[pageName] = [...navbarSections, ...heroSections, ...otherSections, ...footerSections];
      }
    },


    addUserHtml: (state, action) => {
      if (state.pages[state.index]?.length > 2) {
        // Pop the last element
        const poppedElement = state.pages[state.index].pop();

        // Push the new payload into the array
        state.pages[state.index].push(action.payload);

        const snapshot = {
          data: state.data,
          pages: state.pages,
          index: state.index,
          isSiteCreated: state.isSiteCreated,
        };
        console.log("Snappy ", snapshot);
        state.history.push(snapshot);

        // Push the popped element back into the array
        state.pages[state.index].push(poppedElement);
      } else {
        state.pages[state.index].push(action.payload);

        const snapshot = {
          data: state.data,
          pages: state.pages,
          index: state.index,
          isSiteCreated: state.isSiteCreated,
        };
        console.log("Snappy ", snapshot);
        state.history.push(snapshot);
      }
    },





    updateUserHtml: (state, action) => {
      const { _id, newHtmlTemplate } = action.payload;
      console.log("Payload id = ", _id)
      console.log("PayLoad = ", action.payload)

      const elementIndex = state.pages[state.index].findIndex((element) => element?._id === _id);
      let sectionType = state.pages[state.index][elementIndex].sectionType;

      if (sectionType === 0 || sectionType === 9) {
        const allPages = Object.keys(state.pages)
        console.log("All Pages : ", allPages)
        allPages.forEach(function(page, index) {
          let elementIndex_ =  state.pages[page].findIndex((element) => element?.sectionType === sectionType);
          if(state.pages[page][elementIndex_]?.htmlTemplate){
            state.pages[page][elementIndex_].htmlTemplate = newHtmlTemplate;
          }
         
        })
     
      }
      else {
        if (elementIndex !== -1) {
          state.pages[state.index][elementIndex].htmlTemplate = newHtmlTemplate;
          console.log(state.pages[state.index][elementIndex].htmlTemplate)
        }
      }
      const snapshot = {
        data: state.data,
        pages: state.pages,
        index: state.index,
        isSiteCreated: state.isSiteCreated,
      };
      console.log("Snappy ", snapshot);
      state.history.push(snapshot);
    },


    deleteUserHtml: (state, action) => {
      console.log(" Deleting Payload = ", action.payload);
      const elementIndex = state.pages[state.index].findIndex(
        (element) => element._id === action.payload
      );
      console.log("Deleting Index", elementIndex);
      if (elementIndex !== -1) {
        state.pages[state.index].splice(elementIndex, 1); // Remove the element from the array
        console.log("Deleting Element deleted");
      }



    },


    reorderTasks: (state, action) => {
      const payload = action.payload;
      const newPos = payload.newPos;
      const originalPos = payload.originalPos
      console.log("inside reducer : ", payload)
      // arrayMove(state.pages[state.index], originalPos, newPos);

      // const currentIndex = state.pages[state.index].findIndex((element) => element._id === _id);

      // if (currentIndex !== -1 && currentIndex < state.pages[state.index]?.length - 1) {
      //   const nextIndex = currentIndex + 1;

      //   // Perform the swap using arrayMove
      //   state.pages[state.index] = arrayMove(
      //     state.pages[s tate.index],
      //     currentIndex,
      //     nextIndex
      //   );
      //}
    },




    fetchUserHtml: (state) => {
      return state.pages[state.index];
    },
    emptyUserHtml: (state) => {
      state.pages = null;
      state.index = "Home";
      state.isSiteCreated = 0


    }
  },
});

// Export the reducer and actions
export default userHtmlSlice.reducer;
export const { setUserHtml, setPageIndex, deletePage, setHistory, savePageState, setPageHtmlState, reorderTasks, initalizeUserSlice, addToHistory, duplicateRenamePage, undo, deleteUserHtml, setIsSiteCreated, addSectionToPage, setPages, addPage, swapSectionWithLowerSection, swapSectionWithUpperSection, emptyUserHtml, fetchUserHtml, addUserHtml, updatePlaceholders, updateAnchorUrls, updateColors, updateImageUrls, updateUserHtml } = userHtmlSlice.actions;