import React, { useState } from "react";
import "../../Builder_prompt_components/global_theme/fonts.css";
import supabase from "../../../config/supabse";
import { PlaceholdersAndVanishInput } from "../components/PlaceholdersAndVanishInput";

function WaitList() {
  const [isInserting, setIsInserting] = useState(false);
  const [email, setEmail] = useState("");

  const placeholders = [
    "Enter Your Email",
    "Join the Wishlist",
    "email@domain.com",
  ];

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const onSubmit = (e) => {
    handleButtonClick(e);
  };

  const inputStyles = {
    appearance: "none",
    width: "50%",
    height: "auto",
    outline: "none",
    border: "none",
    padding: "16px 136px 16px 16px",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    letterSpacing: "-0.01em",
    lineHeight: "1.2em",
    background:
      "var(--token-0a0787f4-acc7-4c77-bc44-5077db5742f9, rgb(255, 255, 255))",
    boxShadow:
      "inset 0 0 0 0px rgba(255, 255, 255, 0.1), inset 0 0 0 1px var(--token-75959dab-2c20-4b1a-a94b-e2dfa46fe2e8, rgba(255, 255, 255, 0.1))",
  };

  const checkIfEmailExists = async (email) => {
    const { data, error } = await supabase
      .from("wishlist")
      .select("email")
      .eq("email", email)
      .single(); // Use single() to get a single record or null

    if (error) {
      console.error("Error checking email existence:", error);
      return null; // Error occurred
    }
    return data; // Return the data if found
  };

  const addToWaitList = async (email) => {
    setIsInserting(true);

    const existingEmail = await checkIfEmailExists(email);

    if (existingEmail) {
      alert("This email is already queued in the wishlist.");
      setIsInserting(false);
      return;
    }

    const { data, error } = await supabase.from("wishlist").insert([{ email }]);

    if (error) {
      console.error("Error inserting data:", error);
      setIsInserting(false);
    } else {
      console.log("Insertion successful:", data);
      alert("You have successfully queued in the wishlist.");
      setIsInserting(false);
    }
  };

  const handleButtonClick = () => {
    if (email) {
      addToWaitList(email);
      setEmail(""); // Clear input field after submission
    } else {
      alert("Please enter an email address.");
    }
  };

  return (
    <div className="py-10 flex items-center justify-center md:px-10 px-5">
      <div className="flex max-w-7xl flex-col items-center w-full justify-center bg-white text-center md:p-32 px-[20px] py-[68px] rounded-[30px]">
        <h1 className="text-2xl font-medium md:text-4xl mb-4   md:max-w-[64%] max-w-[80%] mx-auto">
          Join thousands of users building with HelloWorld today.
        </h1>
        <p className="md:max-w-[44%] max-w-[70%] mx-auto text-center mb-8">
          Get early access and help shape the development of our platform,
          making it the best it can be.
        </p>
        <div className="flex flex-col justify-center items-center w-full md:w-[60%] mr-[-4px]">
          <PlaceholdersAndVanishInput
            placeholders={placeholders}
            onChange={handleChange}
            onSubmit={onSubmit}
            buttonText={"Join Waitlist"}
          />

        </div>
      </div>
    </div>
    
  );
}

export default WaitList;
