import React from 'react'
import WebStatus from '../HelpingComponents/WebStatus'

import { useNavigate, useParams } from "react-router-dom";

function SiteCard({ data }) {
    const {credId} = useParams()
    const navigate = useNavigate()
    return (
        <>
            <div className="card flex flex-col relative overflow-hidden bg-white border-2 border-gray-100 rounded-[20px] text-[#000000] w-[26vw] h-[48vh] justify-between items-center ">
                <div className="flex items-center justify- flex-col w-full p-5">
                    <WebStatus data={data} />


                    <div className='bg-white mt-3 border-[#EAEAEA] border-[1px] rounded-[10px] w-full p-4 h-auto flex flex-row items-center justify-between'>

                        <h1 className='text-sm font-medium'>Primary Domain </h1>
                        <p className='text-sm '>{data && data?.primaryDomain}</p>


                    </div>

                    <div className='bg-white mt-3 border-[#EAEAEA] border-[1px] rounded-[10px] w-full p-4 h-auto flex flex-col justify-between'>

                        <h1 className='text-sm font-medium'>Web Preview </h1>

                        <div className='bg-white mt-3 w-full py-2 h-auto flex flex-col'>

                            <h1 className='text-sm text-green-800 font-medium'>https://www.{data && data?.primaryDomain} </h1>
                            <p className='text-sm font-bold text-[#1964AB]'>{data && data.title}</p>
                            <p className='text-sm '>{data && data.description && data.description.slice(0, 190) + '...' }</p>


                        </div>


                    </div>
                </div>

                <div>

                    <div className="card-footer px-10 py-4 flex justify-between items-center bg-gray-50 w-[26vw] rounded-b-[20px] overflow-hidden">
                        <h2 className="font-manrope font-semibold tracking-[-3%] leading-[24px] text-[16px]">
                            Settings
                        </h2>
                        <button
                           onClick={()=>{navigate(`/dashboard/${credId}/settings`)}}
                           className="bg-white border-2 border-gray-200 hover:border-gray-300 px-6 py-1 rounded-lg">
                            View
                        </button>
                    </div>
                </div>
            </div>


        </>
    )
}

export default SiteCard