import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: null,
}
  
//reducer
const LogoSlice = createSlice({
  name: 'Logo',
  initialState,
  reducers: {
    setLogo: (state, action) => {
      state.data = action.payload
    },
    
    setLogoEmpty: (state) => {
      state.data = null
    },


  },
})


// Action creators are generated for each case reducer function
export default  LogoSlice.reducer
export const { setLogo ,setLogoEmpty} = LogoSlice.actions; 