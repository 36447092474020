import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import WaitList from "../components/WaitList";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <div className="font_manrope mt-20 min-h-[80vh] flex-col space-y-8 px-4 max-w-4xl mx-auto flex items-center justify-center">
        <h1 className="text-[65px] text-center">Our Mission</h1>
        <p className="text-center">
          We believe everyone has a creative spark. Our mission is to make
          building applications so simple that anyone can do it, not just
          developers. We want everyone worldwide to feel like a programmer,
          turning ideas into reality effortlessly.
        </p>
        <p className="text-center">
          We see a world where technology is a tool for all, not just a select
          few. Beyond simplifying app development, we're committed to fostering
          a community of creators who support and inspire each other. As we
          continue on this journey, our aim is to evolve alongside the needs of
          our users, consistently innovating to make the world of creating even
          more accessible. Together, let's redefine what's possible and build a
          future where anyone, regardless of background or expertise, can
          contribute to the digital landscape with confidence and creativity.
        </p>
        <p className="max-w-sm text-center">
          Our journey begins with our first product: The Website Builder
        </p>
      </div>
      <WaitList />
      <Footer />
    </div>
  );
};

export default AboutUs;
