import React from 'react'

function WebStatus({data}) {
    if(data)
    {

        console.log("Data 00", data)
        if(data.status===1){
            return(

                <div className='bg-white  border-[#EAEAEA] border-[1px] rounded-[10px] w-full p-4 h-auto flex flex-row items-center justify-between'>
                <div>
                    <h1 className='text-sm font-bold'>Website is currently published </h1>
                    <p className='text-xs'>Only you can see the website</p>
                </div>
                <div className='rounded-full bg-[#a0ff8875] h-[25px] w-auto gap-2 px-2 flex justify-between items-center flex-row'>
                    <div className='rounded-full h-[10px] w-[10px] bg-[#8cff88]'>
                    </div>
                    <p className='text-xs'>Published</p>
                </div>
            </div>

            )

        } else if(data.status===2){
            return(

                <div className='bg-white border-[#EAEAEA] border-[1px] rounded-[10px] w-full p-4 h-auto flex flex-row items-center justify-between'>
                <div>
                    <h1 className='text-sm font-bold'>Website is currently unpublished</h1>
                    <p className='text-xs'>Only you can see the website</p>
                </div>
                <div className='rounded-full bg-[#FFD48875] h-[25px]  w-auto gap-2 px-2 flex justify-between items-center flex-row'>
                    <div className='rounded-full h-[10px] w-[10px] bg-[#FFD488]'>
                    </div>
                    <p className='text-xs'>Unpublished</p>
                </div>
            </div>

            )

        } else if (data.status===3){
            return(

            <div className='bg-white border-[#EAEAEA] border-[1px] rounded-[10px] w-full p-4 h-auto flex flex-row items-center justify-between'>
                <div>
                    <h1 className='text-sm font-bold'>Website is currently disabled</h1>
                    <p className='text-xs'> This website and its features are disabled </p>
                </div>
                <div className='rounded-full bg-[#ff888875] h-[25px]  w-auto gap-2  px-2 flex justify-between items-center flex-row'>
                    <div className='rounded-full h-[10px] w-[10px] bg-[#ff8888]'>
                    </div>
                    <p className='text-xs'>Disabled</p>
                </div>
            </div>

            )

        } else{
            return(
                <h1>Status...</h1>
            )
        }
    }
          
}

export default WebStatus