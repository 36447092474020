import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAssets } from '../../Store/Slices/AssetsSlice';
import './customsb.css';
import { addImages, shuffleImages } from '../../Store/Slices/ImageQuerySlice';
import { createClient } from '@supabase/supabase-js';
import supabase from '../../config/supabse';


const DragAndDropUpload = (props) => {
  const subdomainId = useSelector((state) => state.Name.subdomain);
  const dispatch = useDispatch();
  const [highlight, setHighlight] = useState(false);
  const [images, setImages] = useState([]);

  // Fetch user assets when subdomainId changes
  useEffect(() => {
    const fetchUserAssets = async () => {
      if (!subdomainId) return; // Avoid fetching if subdomainId is not set

      try {
        const { data, error } = await supabase
          .from('histories')
          .select('userAssets')
          .eq('domain', subdomainId);

        if (error) {
          console.error('Error fetching userAssets:', error);
          return;
        }

        if (data.length > 0) {
          setImages(data[0]?.userAssets?.images || []);
        }
      } catch (error) {
        console.error('Error fetching userAssets:', error);
      }
    };

    fetchUserAssets();
  }, [subdomainId]);

  const handleDragStart = (event, src) => {
    event.dataTransfer.setData('text/plain', src);
  };

  const preventDefaults = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    setHighlight(false);
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = async (files) => {
    for (const file of files) {
      const url = await uploadImages(file);
      if (url) {
        dispatch(addImages(url));
        console.log("Image URL = ", url);
      }
    }
  };

  const uploadImages = async (file) => {
    if (!file) return;

    const randomDigits = generateRandomString();
    const newFileName = `ztr07r_0/${randomDigits}_${file.name}`;
    
    try {
      const { data, error } = await supabase.storage
        .from('hello-world')
        .upload(newFileName, file, {
          contentType: "image/*",
        });

      if (error) {
        throw new Error(`Error uploading image: ${error.message}`);
      }

      return `https://tdrdiflbdlftfwudjeim.supabase.co/storage/v1/object/public/${data.fullPath}`;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  };

  function generateRandomString() {
    return Math.random().toString(36).substring(2, 8);
  }

  return (
    <div className='w-full h-full flex flex-col items-center '>
      {/* <p className="text-[#ffffff] text-md font_inter self-start">Assets</p> */}
      <div
        className={`text-center  h-[80px] flex items-center justify-center border-[1px] border-dashed py-2 mt-3 bg-[#f4f4f4] rounded-md w-full  px-2 text-black cursor-pointer ${highlight ? 'border-black' : ''}`}
        onDragEnter={e => {
          setHighlight(true);
          preventDefaults(e);
        }}
        onDragOver={e => {
          setHighlight(true);
          preventDefaults(e);
        }}
        onDragLeave={e => {
          setHighlight(false);
          preventDefaults(e);
        }}
        onDrop={handleDrop}
        onClick={() => document.getElementById('fileInput').click()}
      >
        <input
          type="file"
          id="fileInput"
          multiple
          className="hidden"
          onChange={e => handleFiles(e.target.files)}
        />
        <p className='text-black'>Drag or Click to Upload Images</p>
      </div>

      <div className="mt-4 grid grid-cols-2 w-full gap-4 overflow-y-scroll custom-scrollbar items-center justify-center ">
        {images.length > 0 && images.map((image, index) => (
          <img
            onDragStart={(event) => handleDragStart(event, image)}
            draggable="true"
            key={index}
            src={image}
            alt={`Uploaded ${index + 1}`}
            className="w-full h-[120px] rounded self-center"
          />
        ))}
      </div>
    </div>
  );
};

export default DragAndDropUpload;
