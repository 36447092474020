import { createSlice } from '@reduxjs/toolkit';

// Reducer
const SectionSlice = createSlice({
  name: 'Section',
  initialState: {
    data: [], // Use an array for colorValue
  },
  reducers: {

    setSection: (state, action) => {
      state.data.push(action.payload); // Push the payload into the array
    },
    setSectionArray: (state, action) => {
      state.data = action.payload; // Push the payload into the array
      console.log("In section", state.data)
    },
    setSectionEmpty: (state)=>{
      state.data = []
    }
  },
});

// Export the reducer and actions
export default SectionSlice.reducer;
export const { setSection, setSectionEmpty, setSectionArray } = SectionSlice.actions;