import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./Task.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserHtml, savePageState } from "../../../../Store/Slices/userHtmlSlice";
import { useEffect, useState } from "react";

export const Task = ({ id, title, _id, setData, tasks, setTasks, handleSaveState }) => {
  const dispatch = useDispatch();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const page_index = (useSelector(state => state.userHtml.index));
  const deleteSection = (event) => {
    
    console.log("Clicked")
    const newTask = tasks.filter(task => task._id !== _id)
    console.log("After Deletion : ", newTask)
    setTasks(newTask)
    setData(newTask); // Update tasks in the parent component
    dispatch(deleteUserHtml(_id)); // Dispatch the delete action
    dispatch(savePageState({pageIndex : page_index, pageState: newTask }))
  
  };

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="task"
    >
      <MenuIcon  className="w-6 h-6 text-[#DCDCDC]" />
      {title}
      <button
        onClick={deleteSection}
        className="w-4 h-4 z-[100] ml-auto text-[#696969] hover:text-white cursor-pointer transition-colors mr-2 flex items-center justify-center"
      >
        <TrashIcon className="w-4 h-4 text-[#DCDCDC] hover:text-white cursor-pointer transition-colors" />
      </button>
    </div>
  );
};

function TrashIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18" />
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}
