import React from 'react'

function ManageSubscription() {
  return (
    <div className='w-full h-full flex flex-row'>
        <div className='bg-black w-[30%] h-full'>

        </div>
        <div className='bg-white w-full h-full'>
            

        </div>

    </div>
  )
}

export default ManageSubscription