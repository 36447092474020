import React, { useEffect, useState } from 'react'
import axios from 'axios';

import { MdDone } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";

function DomainRecordForRoot(props) {



    const domainData_ = props.domainData[0]
    const [dataA, setDataA] = useState({ ip: ['123.123.123.123'] })
    const [conflictingARecords, setConflictingARecords] = useState([])
    const [isARecordCorrect, setIsARecordCorrect] = useState(false)
    const [dataAAAA, setDataAAAA] = useState({})
    const [isRefreshing, setIsRefrshing] = useState(false)
    const correctIp = '16.171.182.98';


    const fetchData = async () => {
        setIsRefrshing(true)
        try {
            const response = await axios.get(`https://helloworldbackend.com/checkArecord/${props.customDomain}`);
            setDataA(response.data);

            const ips = response?.data?.ip;
            // Filter out the conflicting IPs
            const conflicts = ips.filter(ip => ip !== correctIp);
            setConflictingARecords(conflicts);

            // Check if the correct IP is present
            const isCorrect = ips.includes(correctIp);
            setIsARecordCorrect(isCorrect);
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching the data', error);
        }

        try {
            const response = await axios.get(`https://helloworldbackend.com/checkAAAArecord/${props.customDomain}`);
            setDataAAAA(response.data);
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching the data', error);
        }
        setIsRefrshing(false)
    };


    useEffect(() => {
        fetchData();
    }, []);





    return (
        <>

            {!dataA && !dataAAAA ? (
                <div className='spinner'></div>) : (<div class="w-full flex flex-col bg-white border shadow-sm rounded-md pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70 ">
                    <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                        <h3 id="hs-vertically-centered-modal-label" class="font-bold text-gray-800 dark:text-white">
                            {props.customDomain}
                        </h3>
                        <div className='flex flex-row items-center justify-center gap-4'>

                            {!isRefreshing ? (<button onClick={() => {
                                fetchData();

                            }} type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" data-hs-overlay="#hs-vertically-centered-modal">

                                Refresh
                            </button>) : (<div className='spinner'>
                            </div>)}
                        </div>
                    </div>

                    {!domainData_?.areRecordsVerified ||  !dataA?.status || !dataAAAA?.status ? (<div class="p-4 overflow-y-auto flex flex-row gap-2">
                        <IoMdCloseCircle className='text-2xl text-red-500' />
                        <p className='font-normal text-red-500'>   Record is Pending for Verification</p>
                    </div>) : (<div class="p-4 overflow-y-auto flex flex-row gap-2">
                        <MdDone className='text-2xl text-green-500' />
                        <p className='font-normal text-green-500'>   Record is Correctly Verified</p>
                    </div>)}

                    <hr className='bg-[#fcfcfc] h-2' />

                    <div className='px-6 py-3 flex flex-col gap-3'>
                        <div className='flex flex-row items-center h-[60px] '>
                            <div className='flex flex-col gap-2'>
                                <p className='font-semibold text-md'>A Record (Recommended)</p>
                                <hr className='bg-zinc-800 border-none h-[2px]' />
                            </div>
                        </div>
                        <p className='text-zinc-700' > Set the following record on your DNS provider to continue:</p>
                        <div className='border-gray-200 border rounded-md p-4 flex flex-row gap-10'>
                            <div className='flex flex-col gap-2'>
                                <h1 className='text-zinc-800 font-semibold'>Type</h1>
                                <p className='text-zinc-700'>A</p>

                            </div>
                            <div className='flex flex-col gap-2'>
                                <h1 className='text-zinc-800 font-semibold'>Name</h1>
                                <p className='text-zinc-700'>@</p>

                            </div>
                            <div className='flex flex-col gap-2'>
                                <h1 className='text-zinc-800 font-semibold'>Value</h1>
                                <p className='text-zinc-700'>16.171.182.98</p>

                            </div>

                        </div>

                        <div className='border-gray-200 border rounded-md p-4 flex flex-row gap-10 mb-4'>
                            <p className='text-zinc-700'>Depending on your provider, it might take some time for the DNS records to propagate globally. Check the DNS propagation by clicking on the following link <a className="text-blue-500 underline underline-offset-1" href={`https://dnschecker.org/#A/${props.customDomain}`}>Here</a></p>
                        </div>


                        <div className='flex flex-col gap-3'>


                        {conflictingARecords?.length>0 && <p className='text-red-500 font-semibold' > Please Remove the following conflicting records</p>}

                            {conflictingARecords && conflictingARecords.map((ip, index) => (
                                <div
                                    key={index}
                                    className='border-gray-200 bg-red-50 border rounded-md p-4 flex flex-row gap-10 mb-4'
                                >
                                    <div className='flex flex-col gap-2'>
                                        <h1 className='text-zinc-800 font-semibold'>Type</h1>
                                        <p className='text-zinc-700'>A</p>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <h1 className='text-zinc-800 font-semibold'>Value</h1>
                                        <p className='text-zinc-700'>{ip}</p>
                                    </div>
                                </div>
                            ))}


                            {/* {dataAAAA?.ip.map((ip, index) => (
                            <div
                                key={index}
                                className='border-gray-200 bg-red-50 border rounded-md p-4 flex flex-row gap-10 mb-4'
                            >
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-zinc-800 font-semibold'>Type</h1>
                                    <p className='text-zinc-700'>AAAA</p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-zinc-800 font-semibold'>Value</h1>
                                    <p className='text-zinc-700'>{ip}</p>
                                </div>
                            </div>
                        ))} */}
                        </div>

                    </div>


                    {/* <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">

                    <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-500 text-white hover:bg-red-400 focus:outline-none disabled:opacity-50 disabled:pointer-events-none">
                        Remove
                    </button>
                </div> */}
                </div>)}
        </>
    )
}

export default DomainRecordForRoot