import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import supabase from "../../../../config/supabse";
import '../../../SignIn/spinner.css'
import Dropdown from "./DropDown";

const StatusCard = ({ status }) => {
  if (status === 1) {
    return (

      <div className='rounded-full bg-green-50 h-[30px] border-2 border-green-100 w-auto gap-2 px-2 flex justify-between items-center flex-row'>
        <div className='rounded-full h-[15px] w-[15px] bg-green-300 border border-green-500'>
        </div>
        <p className='text-sm text-black'>Published</p>
      </div>
    )
  } else if (status === 2) {
    return (
      <div className='rounded-full bg-orange-50 h-[30px] border-2  border-orange-100 w-auto gap-2 px-2 flex justify-between items-center flex-row'>
        <div className='rounded-full h-[15px] w-[15px] bg-orange-300 border border-orange-500'>
        </div>
        <p className='text-sm text-black'>Unpublished</p>
      </div>
    )
  } else if (status === 3) {

    return (
      <div className='rounded-full bg-red-50 h-[30px] border border-red-100 w-auto gap-2 px-2 flex justify-between items-center flex-row'>
        <div className='rounded-full h-[15px] w-[15px] bg-red-300 border border-red-500'>
        </div>
        <p className='text-sm text-black'>Disabled</p>
      </div>
    )

  } else (
    <div className='rounded-full bg-[#ff888875] h-[25px] w-auto gap-2  px-2 flex justify-between items-center flex-row'>
      <div className='rounded-full h-[10px] w-[10px] bg-[#ff8888]'>
      </div>
      <p className='text-xs'>Disabled</p>
    </div>
  )

}

const WebsiteSettings = () => {
  const { credId } = useParams()
  const navigate = useNavigate()

  const Name = useSelector((state) => { return state.Name.data })
  const Pages = useSelector((state) => { return state.userHtml.pages })
  const subDomain = useSelector((state) => { return state.Name.subdomain })
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  console.log("Pages : ", Pages)
  const filteredOptions = Object.keys(Pages).filter(key => key !== 'temp');


  const [changing, setChanging] = useState(false)

  const [selectedOption, setSelectedOption] = useState("");

  const [titleVal, setTitleVal] = useState(" ")
  const [descriptionVal, setDescriptionVal] = useState(" ")
  const [statusCode, setStatusCode] = useState(1)


  const [selectedPage, setSelectedPage] = useState('');

  const handleSelect = (option) => {
    setSelectedPage(option);
    console.log(`Selected page: ${option}`);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const { data: fetchedData, error } = await supabase
          .from('websites') // Replace with your table name
          .select('*')
          .eq('domain', `${subDomain}`); // Replace 'domain' with your column name

        if (error) throw error;
        setData(fetchedData[0]);
        setTitleVal(fetchedData[0].title)
        setDescriptionVal(fetchedData[0].description)
        setStatusCode(fetchedData[0].status)
        setSelectedPage(fetchedData[0].homePage)


        if (fetchedData[0].status === 1) {
          setSelectedOption("Published")
        } else if (fetchedData[0].status === 2) {
          setSelectedOption("Unpublished")
        } else if (fetchedData[0].status === 3) {
          setSelectedOption("Disabled")
        } else {
          setSelectedOption("Disabled")
        }
        console.log("this in settings", fetchedData)
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  function webStatus(data) {
    if (data?.status === 1) {
      return (
        <div className="flex-grow relative bg-white border border-[#f1f1f1] rounded-lg p-4 text-[#6a6a6a] focus:outline-none font-manrope text-[11px] font-medium leading-[20px] tracking-[0.01em]">
          <h3 className="font-manrope text-sm text-[#000000] font-medium leading-[20px] tracking-[0.01em] text-left">
            Website is currently published
          </h3>
          <p className="font-manrope text-xs text-[#636363] font-medium leading-[20px] tracking-[0.01em] text-left">
            Everyone can see your site
          </p>
        </div>

      )

    } else if (data?.status === 2) {
      return (
        <div className="flex-grow relative bg-white border border-[#f1f1f1] rounded-lg p-4 text-[#6a6a6a] focus:outline-none font-manrope text-[11px] font-medium leading-[20px] tracking-[0.01em]">
          <h3 className="font-manrope text-sm text-[#000000] font-medium leading-[20px] tracking-[0.01em] text-left">
            Website is currently unpublished
          </h3>
          <p className="font-manrope text-xs text-[#636363] font-medium leading-[20px] tracking-[0.01em] text-left">
            Only you can see the website
          </p>
        </div>

      )

    } else if (data?.status === 3) {

      return (
        <div className="flex-grow relative bg-white border border-[#f1f1f1] rounded-lg p-4 text-[#6a6a6a] focus:outline-none font-manrope text-[11px] font-medium leading-[20px] tracking-[0.01em]">
          <h3 className="font-manrope text-sm text-[#000000] font-medium leading-[20px] tracking-[0.01em] text-left">
            Website is currently disabled
          </h3>
          <p className="font-manrope text-xs text-[#636363] font-medium leading-[20px] tracking-[0.01em] text-left">
            This website and its features are disabled
          </p>
        </div>

      )

    } else {
      return (
        <></>
      )
    }

  }






  function webStatusText(data) {
    if (data?.status === 1) {
      return (
        <>
          <h3 className="font-manrope text-sm text-[#000000] font-medium leading-[20px] tracking-[0.01em] text-left">
            Website is currently published
          </h3>
          <p className="font-manrope text-xs text-[#636363] font-medium leading-[20px] tracking-[0.01em] text-left">
            Everyone can see your site
          </p>
        </>


      )

    } else if (data?.status === 2) {
      return (
        <>
          <h3 className="font-manrope text-[12px] text-[#000000] font-medium leading-[20px] tracking-[0.01em] text-left">
            Website is currently unpublished
          </h3>
          <p className="font-manrope text-[10px] text-[#636363] font-medium leading-[20px] tracking-[0.01em] text-left">
            Only you can see the website
          </p>
        </>


      )

    } else if (data?.status === 3) {

      return (
        <>
          <h3 className="font-manrope text-sm text-[#000000] font-medium leading-[20px] tracking-[0.01em] text-left">
            Website is currently disabled
          </h3>
          <p className="font-manrope text-xs text-[#636363] font-medium leading-[20px] tracking-[0.01em] text-left">
            This website and its features are disabled
          </p>
        </>


      )

    } else {
      return (
        <></>
      )
    }

  }





  const options = [
    { value: "Published", description: "Anyone can see this website", code: 1 },
    { value: "Unpublished", description: "Only you can see this website", code: 2 },
    {
      value: "Disabled",
      description: "This website and its features are disabled",
      code: 3
    },
  ];







  const upsertWebsite = async (domain, title, description, status, homePage) => {
    const { data, error } = await supabase
      .from('websites')
      .upsert(
        { domain, title, description, status, homePage },
        { onConflict: ['domain'] } // Specify conflict column
      );

    if (error) {
      console.error('Error upserting data:', error);


      setSaving(false)
    } else {
      console.log('Upsert successful:', data);
      console.log("Update code : ", statusCode)
      setSaving(false)
      setTimeout(() => {
        window.location.reload()
      }, 1000);

    }
  };

  const [saving, setSaving] = useState(false)

  const handleButtonClick = () => {
    setSaving(true)
    upsertWebsite(subDomain, titleVal, descriptionVal, statusCode, selectedPage);



  };


  return (
    <>

      {!Loading ? (
        <div className="w-[98%] 2xl:w-[80%] flex flex-col pl-7">
          <div className=" w-full  mx-auto rounded-lg bg-[#fbfbfb] mt-8">
            <div className="p-8 flex justify-between items-center">
              <Link to="/settings-status">
                {" "}
                <h2 className="text-xl font-semibold font-manrope text-[16px] leading-[20px] ">
                  Status
                </h2>
              </Link>

              <StatusCard status={data?.status} />
            </div>

            <div className="border-b border-[#f1f1f1] "></div>
            <div className="flex items-center relative p-8">



              {!changing ? (
                <>
                  {data && webStatus(data)}
                  <button onClick={() => { setChanging(true) }} className="bg-[#ffd488] absolute right-[3.5rem] text-[#000000] font-semibold font-manrope text-[12px] leading-[20px] tracking-[0.01em] px-7 py-2 rounded-lg hover:bg-[#fac468] focus:outline-none">
                    Change
                  </button>
                </>

              ) : (
                <div className="flex items-center relative w-full">
                  <div className="flex-grow relative bg-white border w-full border-[#f1f1f1] rounded-lg p-4 text-[#6a6a6a] focus:outline-none font-manrope text-[11px] font-medium leading-[20px] tracking-[0.01em]">
                    <>
                      {data && webStatusText(data)}
                    </>


                    <div className="rounded-[0.6rem]  w-full mx-auto">
                      {options.map((option) => (
                        <div
                          key={option.value}
                          className={`flex items-center cursor-pointer justify-between p-4 mt-4 rounded-lg  ${selectedOption === option.value
                            ? "bg-gray-50 border border-zinc-200"
                            : "bg-white border border-gray-200 hover:border-gray-400"
                            }`}
                          onClick={() => { setSelectedOption(option.value); setStatusCode(option.code); console.log("The option ", option.code) }}
                        >
                          <div className="flex items-center">
                            <div
                              className={`w-5 h-5 rounded-full border flex items-center ${selectedOption === option.value
                                ? "bg-black border-black"
                                : "border-gray-400"
                                }`}
                            >
                              {selectedOption === option.value && (
                                <div className="w-2 h-2 rounded-full bg-white mx-auto "></div>
                              )}
                            </div>
                            <div className="ml-4">
                              <h3 className="text-sm text-[#000000] font-medium font-manrope leading-[20px] tracking-[0.01em] text-left">
                                {option.value}
                              </h3>

                              <p className="text-[xs text-[#7c7c7c] font-manrope font-medium leading-[20px] tracking-[0.01em] text-left">
                                {option.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="bg-[#FbFbFb]  rounded-lg w-full   mx-auto mt-4">
            <h2 className="text-xl font-semibold font-manrope text-[16px] leading-[20px] p-6">
              Home Page
            </h2>

            <div className="border-b border-[#f1f1f1] "></div>

            <div className="p-6">
              <Dropdown
                options={filteredOptions}
                selectedOption={selectedPage}
                onSelect={handleSelect}
              />
            </div>

            <div className="p-6 border-[#f1f1f1] flex flex-col gap-3">
              <div>
                <label className="block text-md  font-medium font-manrope  text-[#000000] mb-2 ">
                  Title
                </label>
                <div className="flex-grow relative bg-white border border-[#f1f1f1] rounded-lg p-2 text-[#000000] focus:outline-none font-manrope   ">
                  <input value={titleVal} onChange={(e) => { setTitleVal(e.target.value) }} type="text" className='rounded-md px-3 py-2 text-sm bg-transparent border-none w-full h-full text-[#1964AB] font-medium' />

                </div>
              </div>
              <div className="mt-4">
                <label className="block text-md  font-medium font-manrope  text-[#000000] mb-2">
                  Description
                </label>
                <div className="flex-grow relative bg-white border border-[#f1f1f1] rounded-lg p-2 text-[#000000] focus:outline-none font-manrope text-xs  ">
                  <textarea
                    value={descriptionVal}
                    onChange={(e) => { setDescriptionVal(e.target.value) }}
                    className='rounded-md p-3  w-full text-sm bg-transparent border-none min-h-[130px] '
                    style={{ width: '100%' }}
                  ></textarea>
                </div>
                <div className="mt-4">
                  <label className="block text-md font-medium font-manrope text-[#000000] mb-2">
                    Preview
                  </label>
                  <div className="flex-grow relative bg-[#ffffff] border border-[#f1f1f1] rounded-lg p-4 text-[#000000] focus:outline-none font-manrope ">
                    <h3 className="font-inter text-xs font-normal  text-left text-[#1d6b1d]">
                      https://{data?.primaryDomain}
                    </h3>

                    <h2 className="font-inter text-sm font-medium  mt-2 text-left text-[#1964AB] ">
                      {titleVal}
                    </h2>

                    <p className="font-manrope text-sm text-[#000000]  text-left mt-2">
                      {descriptionVal}
                    </p>
                  </div>
                </div>
              </div>


            </div>


          </div>
          <div className="bg-[#FbFbFb]  rounded-lg  w-full h-auto p-4 mx-auto mt-4">
            <h2 className="text-xl font-semibold font-manrope text-[16px] leading-[20px] p-6">
              Save
            </h2>

            <div className="border-b border-[#f1f1f1] "></div>


            <div className="flex flex-row justify-between items-center bg-white border-[#f1f1f1] border mt-4 p-4 rounded-lg">
              <div className="flex-grow relative   text-[#6a6a6a] focus:outline-none font-manrope text-[11px] font-medium leading-[20px] tracking-[0.01em]">
                <h3 className="font-manrope text-sm text-[#000000] font-medium text-left">
                  In order to make the changes live, save the changes.
                </h3>
                <p className="font-manrope text-xs text-[#636363] font-medium text-left">
                  Changes will be lost if not saved.
                </p>
              </div>


              {!saving ? (<button onClick={() => { handleButtonClick() }} className="bg-[#ffd488]  text-[#000000] font-semibold font-manrope text-[12px] leading-[20px] tracking-[0.01em] px-7 py-2 rounded-lg hover:bg-[#fac468] focus:outline-none">
                Save
              </button>) : (<div className="spinner"></div>)}
            </div>


          </div>

        </div>
      ) : (<div className="flex h-full w-full items-center justify-center">

        <div className="spinner">

        </div>

      </div>)}

    </>
  );
};

export default WebsiteSettings;
