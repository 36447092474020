import React, { useEffect, useState } from "react";
import { Line, Pie } from "react-chartjs-2";

import Globe from "../../DashboardAssets/images/globe-2.svg";
import People from "../../DashboardAssets/images/people-2.svg";
import File from "../../DashboardAssets/images/file.svg";
import AnalyticsGraph from "./AnalyticsGraph";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import BarGraph from "./BarGraph";
import { useSelector } from "react-redux";
import supabase from "../../../../config/supabse";
import '../../../SignIn/spinner.css'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);


const WebsiteAnalytics = () => {

  const [data, setData] = useState([]);
  const [uniqueVisitor, setUniqueVisitors] = useState(0)
  const [liveSession, setLiveSessions] = useState(0)
  const [popularPages, setPopularPages] = useState([])
  const [screenPercentages, setScreenPercentages] =useState({
    screen1:30,
    screen3:70
  })

  const [screenCounts, setScreenCounts] =useState({
    screen1:30,
    screen3:70
  })
  const [error, setError] = useState(null);
  const [Loading, setLoading] = useState(false);
  const subDomain = useSelector((state) => { return state.Name.subdomain })
  const [mostScrolledPages, setMostScrolledPages] = useState([]);


  useEffect(() => {


    const findMostScrolledPages = (data) => {
      const pathScrollMap = {};

      data.forEach(row => {
        const { path, scroll } = row;
        if (!pathScrollMap[path] || scroll > pathScrollMap[path]) {
          pathScrollMap[path] = scroll;
        }
      });

      // Convert to array and sort by descending order of scroll value
      return Object.entries(pathScrollMap)
        .sort((a, b) => b[1] - a[1])
        .map(([path, maxScroll]) => ({ path, maxScroll }));
    };

    // Calculate and store the most scrolled pages
  

    const countOpenSessions = (data) => {
      return data.filter(row => row.isClosed === false)?.length;
    };

    const countUniqueIPAddresses = (data) => {
      const uniqueIPs = new Set();

      data.forEach(row => {
        uniqueIPs.add(row.ip_address);
      });

      return uniqueIPs.size;
    };


    const groupByPath = (data) => {
      const pathCounts = data.reduce((acc, row) => {
        acc[row.path] = (acc[row.path] || 0) + 1;
        return acc;
      }, {});

      // Convert to array and sort by descending order
      return Object.entries(pathCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([path, count]) => ({ path, count }));
    };


    const calculateScreenPercentages = (data) => {
      const totalSessions = data?.length;
      const screen1Count = data.filter(row => row.screen === 1)?.length;
      const screen3Count = data.filter(row => row.screen === 3)?.length;

      return {
        screen1: ((screen1Count / totalSessions) * 100).toFixed(2),
        screen3: ((screen3Count / totalSessions) * 100).toFixed(2),
      };
    };


    const calculateScreenCounts = (data) => {
      const screen1Count = data.filter(row => row.screen === 1)?.length;
      const screen3Count = data.filter(row => row.screen === 3)?.length;

      return {
        screen1: screen1Count,
        screen3: screen3Count,
      };
    };



    const fetchData = async () => {
      setLoading(true)
      try {
        const { data: fetchedData, error } = await supabase
          .from('sessions') // Replace with your table name
          .select('*')
          .eq('siteId', `${subDomain}`); // Replace 'domain' with your column name

        if (error) throw error;
        setData(fetchedData);
        setUniqueVisitors(countUniqueIPAddresses(fetchedData))
        setLiveSessions(countOpenSessions(fetchedData))
        setScreenPercentages(calculateScreenPercentages(fetchedData));
        setScreenCounts(calculateScreenCounts(fetchedData))
        setMostScrolledPages(findMostScrolledPages(fetchedData));

        // Calculate and store the popular pages
        setPopularPages(groupByPath(fetchedData));


        console.log("this ---- >", fetchedData)
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();



  }, []);



  const data_ = {
    labels: ["Desktop", "Mobile"],
    datasets: [
      {
        data: [screenPercentages.screen1, screenPercentages.screen3],
        backgroundColor: ["#FFD488", "#000000"],
        hoverBackgroundColor: ["#FBBF24", "#000000"],
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Last 7 Days");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  return (
    <>
    {!Loading ? (<div className="p-8 w-[100%]">
        <h2 className="font-manrope text-[16px] font-semibold leading-[20px] text-[#000000]">
          Activity
        </h2>
        <div className="bg-[#fbfbfb] min-h-[350px] h-auto rounded-[10px] p-4 pb-7 mt-3 ">
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-semibold">Visits per day</h2>
            <div className="text-green-500">
              <span className="text-sm font-semibold">↑ 2.6%</span> All time
            </div>
          </div>
          <div className="mt-4">
            <AnalyticsGraph height={'28vh'} data={data}
            />
          </div>

        </div>
        <div className="flex mt-5 w-full gap-4">
          <div className="max-h-[101px] h-full w-[33%] bg-[#fbfbfb] p-4 rounded-[10px] flex items-center justify-between">
            <div className="">
              <h2 className="font-manrope text-[#000000] font-normal text-[48px] leading-[50px]">
                {liveSession}
              </h2>
              <div className="flex items-center mt-2">
                <p className="font-manrope font-semibold text-[12px] text-[#000000] leading-[12px] mr-2">
                  Online Users
                </p>
                <button className="live-btn bg-[#c4eacc] rounded-full px-2 py-1 font-manrope font-medium text-[9px] leading-[7px] text-[#000000]">
                  Live
                </button>
              </div>
            </div>
            <div className="bg-[#eaeaea] p-4  flex items-center justify-center rounded-full">
              <img src={Globe} alt="" className="h-[14px] w-[14px]" />
            </div>
          </div>
          <div className="max-h-[101px] h-full w-[33%] bg-[#fbfbfb] p-4 rounded-[10px] flex items-center justify-between">
            <div className="">
              <h2 className="font-manrope text-[#000000] font-normal text-[48px] leading-[50px]">
                {uniqueVisitor}
              </h2>
              <div className="flex items-center mt-2">
                <p className="font-manrope font-semibold text-[12px] text-[#000000] leading-[12px] mr-2">
                  Unique Visitors
                </p>
                <button className="live-btn bg-[#c4eacc] rounded-full px-2 py-1 font-manrope font-medium text-[9px] leading-[7px] text-[#000000]">
                  Live
                </button>
              </div>
            </div>
            <div className="bg-[#eaeaea] p-4  flex items-center justify-center rounded-full">
              <img src={People} alt="" className="h-[14px] w-[14px]" />
            </div>
          </div>
          <div className="max-h-[101px] h-full w-[33%] bg-[#fbfbfb] p-4 rounded-[10px] flex items-center justify-between">
            <div className="">
              <h2 className="font-manrope text-[#000000] font-normal text-[48px] leading-[50px]">
                {data && data?.length}
              </h2>
              <div className="flex items-center mt-2">
                <p className="font-manrope font-semibold text-[12px] text-[#000000] leading-[12px] mr-2">
                  Page Views
                </p>
                <button className="live-btn bg-[#c4eacc] rounded-full px-2 py-1 font-manrope font-medium text-[9px] leading-[7px] text-[#000000]">
                  total
                </button>
              </div>
            </div>
            <div className="bg-[#eaeaea] p-4  flex items-center justify-center rounded-full">
              <img src={File} alt="" className="h-[14px] w-[14px]" />
            </div>
          </div>
        </div>
        <div className="flex mt-5 w-full gap-4">
          <div className="bg-[#fbfbfb] p-6 rounded-[10px] w-[32.6%]">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Device views</h2>
              <div className="text-sm text-gray-500">All time</div>
            </div>
            <div className="h-48 relative">
              <Pie data={data_} options={pieOptions} />
            </div>
            <div className="mt-4 flex justify-around items-center">
              <div className="flex items-center">
                <span className="w-3 h-3 bg-yellow-400 inline-block mr-2 rounded-full"></span>
                <span className="text-sm text-gray-600">
                  Desktop {screenPercentages.screen1}% ({screenCounts.screen1})
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-3 h-3 bg-black inline-block mr-2 rounded-full"></span>
                <span className="text-sm text-gray-600">
                  Mobile{screenPercentages.screen3}% ({screenCounts.screen3})
                </span>
              </div>
            </div>
          </div>

          <div className="bg-[#fbfbfb] p-6 rounded-[10px] w-[32.6%]">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Most Viewed Pages</h2>
              <div className="text-sm text-gray-500">All time</div>
            </div>

            {popularPages.map((page, index) => (

              <div key={index} className="flex flex-row items-center justify-between font-medium border-b-2 border-b-[#E8E8E8] py-3">
                <h1>{page.path==='/' ? (`/home`) : (page.path)}</h1>
                <p>{page.count}</p>
              </div>

            ))}


           
          </div>


          <div className="bg-[#fbfbfb] p-6 rounded-[10px] w-[32.6%]">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Most Scrolled Pages</h2>
              <div className="text-sm text-gray-500">All time</div>
            </div>
           
            {mostScrolledPages.map((page, index) => (
           <div key={index} className="flex flex-row items-center justify-between font-medium border-b-2 border-b-[#E8E8E8] py-3">
           <h1>{page.path}</h1>
           <p>{page.maxScroll}</p>
         </div>

        ))}
           
          </div>
        </div>
        <div className="bg-[#fbfbfb] min-h-[350px] h-auto rounded-[10px] p-4 pb-7 mt-5 ">
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-semibold">Peak Hours</h2>
            <div className="text-green-500">
              <span className="text-sm font-semibold">↑ 2.6%</span> All time
            </div>
          </div>
          <div className="mt-4 ">
            <BarGraph height={'28vh'} data={data}
            />
          </div>

        </div>
      </div>) : (<>
      
      <div className="flex items-center justify-center h-full w-full">
        <div className="spinner">

        </div>

      </div>
      </>)}
      
    </>
  );
};

export default WebsiteAnalytics;
