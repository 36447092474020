import React, { useEffect, useRef, useState } from "react";
import "../../Builder_prompt_components/global_theme/fonts.css";

function Explore() {
  const [isPlaying1, setIsPlaying1] = useState(true);
  const iframeRef = useRef(null);

  const togglePlayPause1 = () => {
    setIsPlaying1(!isPlaying1);
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        `{"method":"${isPlaying1 ? "pause" : "play"}"}`,
        "*"
      );
    }
  };


  const [isPlaying2, setIsPlaying2] = useState(true);
  const videoRef = useRef(null);

  const togglePlayPause2 = () => {
    if (videoRef.current) {
      if (isPlaying2) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying2(!isPlaying2);
    }
  };


  return (
    <>
    <section className="py-16 md:px-10 px-5 ">
      <h2 className="text-[2rem] font-medium md:text-[2.563rem] leading-tight text-center text-gray-900 mb-12 px-4">
        Get started with HelloWorld today
      </h2>
      <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 overflow-hidden rounded-[30px]  bg-white">
        <div className="px-8 py-12 md:p-12 flex flex-col justify-center">
          <h3 className="text-3xl font-semibold mb-4">Generate</h3>
          <p className="text-lg text-gray-600 mb-4">
            Instantly create a fully functional website by simply describing
            your vision with a few prompts.
          </p>
        </div>
        <div className="relative aspect-square w-full group ">
          <iframe
            ref={iframeRef}
            src="https://player.vimeo.com/video/1012153811?h=e931dddefd&loop=true&controls=false&autoplay=1&muted=1"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            className="absolute inset-0 w-full h-full"
          />
          <div className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <button
              type="button"
              className="flex items-center justify-center focus:outline-none rounded-full w-12 h-12 bg-black/50 text-white backdrop-blur-xl"
              aria-label={isPlaying1 ? "Pause video" : "Play video"}
              onClick={togglePlayPause1}
            >
              {isPlaying1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="6" y="4" width="4" height="16" />
                  <rect x="14" y="4" width="4" height="16" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polygon points="5 3 19 12 5 21 5 3" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
    <section className="py-16 md:px-10 px-5 ">
      <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 overflow-hidden rounded-[30px]  bg-white">
        <div className="px-8 py-12 md:p-12 flex flex-col justify-center">
          <h3 className="text-3xl font-semibold mb-4">Customize</h3>
          <p className="text-lg text-gray-600 mb-4">
            Fine-tune your site effortlessly by typing in any changes or
            adjustments you need, without any design skills required.
          </p>
        </div>
        <div className="relative aspect-square w-full group">
          <video
            ref={videoRef}
            className="absolute inset-0 w-full h-full object-cover"
            src="/video_2.mp4"
            loop
            muted
            autoPlay
            playsInline
          />
          <div className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <button
              type="button"
              className="flex items-center justify-center focus:outline-none rounded-full w-12 h-12 bg-black/50 text-white backdrop-blur-xl"
              aria-label={isPlaying2 ? "Pause video" : "Play video"}
              onClick={togglePlayPause2}
            >
              {isPlaying2 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="6" y="4" width="4" height="16" />
                  <rect x="14" y="4" width="4" height="16" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polygon points="5 3 19 12 5 21 5 3" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
    <div className="py-16 md:px-10 flex items-center justify-center px-5 ">
      <div className=" max-w-7xl grid md:grid-cols-2 bg-white rounded-[30px] ">
        <div className="px-8 py-12 md:p-12 flex flex-col justify-center">
          <h3 className="text-3xl font-semibold mb-4">Publish</h3>
          <p className="text-lg text-muted-foreground mb-4">
            Launch your polished website with a single click, ready to reach
            your audience online. Instantly optimized for all devices.
          </p>
        </div>
        <div className="relative md:aspect-square">
          <img
            alt="A blurred, abstract photo of trees against a light sky. The motion effect creates streaks of green and brown foliage, with a hint of red in the lower corner, giving the image a sense of movement and energy."
            src="https://images.ctfassets.net/kftzwdyauwt9/6kB8QkrYYMdT5gFfkM4oA2/178a0b3c7edd67f7d85a32c90f682d68/3a.png?w=3840&q=90&fm=webp"
            className="w-full h-full object-cover md:absolute md:inset-0 rounded-tr-[30px] rounded-br-[30px]"
          />
          <div className="absolute inset-0 p-4 flex items-center justify-center">
            <div className="w-full max-w-[90%] max-h-[90%] sm:max-w-[80%] md:max-w-[21.875rem] bg-background/80 backdrop-blur-xl overflow-auto">
              <div className="p-4 sm:p-6">
                <div className="flex gap-2 items-center w-full text-sm mb-2">
                  <div className="flex-none rounded-full bg-primary w-[1.125rem] h-[1.125rem]" />
                  <div>You</div>
                </div>
                <div className="pl-6 max-w-full overflow-x-auto flex flex-col gap-y-2">
                  <div className="w-24 aspect-square relative">
                    <img
                      alt="A side-profile of a black bicycle in a fenced yard."
                      src="https://images.ctfassets.net/kftzwdyauwt9/3AcgEDraljyuixHuPAAX2o/9e999603899bbe094b3a4bdec8d5ae3d/bike.png?w=3840&q=90&fm=webp"
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div className="text-xs">Help me lower my bike seat.</div>
                </div>
                <div className="mt-4">
                  <div className="flex gap-2 items-center w-full text-sm mb-2">
                    <div className="flex-none rounded-full overflow-hidden w-[1.125rem] h-[1.125rem]">
                      <img
                        alt="ChatGPT Logo"
                        src="https://images.ctfassets.net/kftzwdyauwt9/wYvIdCKrY18fJmMC1NG3F/ad406aaec1504f70277547fa3d730774/UI_ChatGPT_Logo.png?w=3840&q=90&fm=webp"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div>ChatGPT</div>
                  </div>
                  <div className="pl-6 text-xs">
                    <p>To lower your bike seat:</p>
                    <ul className="list-disc pl-4 mt-2">
                      <li>
                        Loosen the clamp: Use a lever or allen key to loosen the
                        clamp under the seat.
                      </li>
                      <li>
                        Adjust height: Lower the seat to your desired level.
                      </li>
                      <li>
                        Secure the seat: Tighten the clamp to secure the seat in
                        place.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Explore;
