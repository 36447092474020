import Card from "../Home/Card";

import WebsiteSample from "../../DashboardAssets/images/website-sample.svg";
import FooterBackground from "../../DashboardAssets/images/footer-white.svg";
import FooterShadow from "../../DashboardAssets/images/footer-shadow.svg";

function WebsiteOverviewMain() {
  return (
    <>
      <div className="text-[#000]">
        <h2 className="text-[48px] font-medium font-manrope leading-[60px]">
          Inform, engage, and <br />
          convert your visitors
        </h2>
      </div>
      <div className="flex flex-wrap items-center justify-between mt-12">
        <div className="overview-section relative bg-cover bg-center max-h-[584px] h-full max-w-[1173px] w-full  rounded-2xl ">
          <img
            src={WebsiteSample}
            alt="Website Sample"
            className="rounded-2xl "
          />
          <div className="absolute top-[72%] ">
            <img src={FooterShadow} alt="" className="" />
          </div>
          <div className="absolute top-[88%] ">
            <img src={FooterBackground} alt="" className="" />
          </div>
          <div className="">
            <h2 className="absolute top-[91.5%] left-[4%] font-manrope font-semibold leading-[26px] text-[#000000]">
              Website is <span className="text-blue-700">Live</span>
            </h2>
            <div className="">
              <button
                type="button"
                className="text-[#000000] absolute top-[90.5%] right-[2%] bg-[#fccf82] font-manrope font-medium rounded-lg text-sm px-6 py-2 me-2 mb-2 "
              >
                Preview
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WebsiteOverviewMain;
