import React from 'react';
import HorizontalHeader from '../DashboardComponents/HorizontalHeader/HorizontalHeader';
import AdminEditorMain from '../DashboardComponents/AdminEditor/AdminEditorMain';


const AdminEditor = () => {
    return (
        <div className="flex flex-col w-full h-[100vh] overflow-hidden">
        <HorizontalHeader headerTitle={"Overview"} />
        <div className="flex-1 overflow-auto p-4">
            <AdminEditorMain/>
          
        </div>
      </div>
    );
}

export default AdminEditor;
