import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../../SignIn/spinner.css'
import supabase from '../../../../config/supabse'
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { IoMdCloseCircleOutline } from "react-icons/io";

function Note({ note, index, setTrigger, trigger }) {
    const {credId} =useParams()
    const [editClicked, setEditClicked] = useState(false)
    const [editedText, setEditedText] = useState(note?.noteText)
    const [isUpdating, setIsUpdating] = useState(false)


    const updateContactNote = async () => {
        try {
            setIsUpdating(true)
            const { data, error } = await supabase
                .from('contactNotes')
                .update({ noteText: editedText })
                .eq('noteId', note?.noteId)
               

            if (error) {
                setIsUpdating(false)
                console.error('Error updating data:', error);
            } else {
                setIsUpdating(false)
                setEditClicked(false)
                setTrigger(!trigger)
                console.log('Data updated successfully:', data);
            }
        } catch (error) {
            setIsUpdating(false)
            console.error('Unexpected error:', error);
        }
    };


    const deleteContactNote = async () => {
        try {
            setIsUpdating(true)
            const { data, error } = await supabase
                .from('contactNotes')
                .delete()
                .eq('noteId', note?.noteId);

            if (error) {
                setIsUpdating(false)
                console.error('Error deleting data:', error);
            } else {
                setIsUpdating(false)
                setTrigger(!trigger)
                console.log('Data deleted successfully:', data);
            }
        } catch (error) {
            setIsUpdating(false)
            console.error('Unexpected error:', error);
        }
    };

    return (
        <>
            <div key={index} className='min-w-[15vw] h-full bg-yellow-100 border-yellow-200 border rounded-md flex p-4 flex-col'>
                <div className='flex flex-row gap-2 justify-end'>
                    <MdEdit onClick={() => { setEditClicked(true) }} className='cursor-pointer' />
                    {!isUpdating ? <FaRegTrashAlt onClick={()=>{deleteContactNote()}} className='cursor-pointer' /> : <></>}
                    {editClicked && <IoMdCloseCircleOutline className='cursor-pointer text-xl' onClick={() => { setEditClicked(false) }} />}
                    {editClicked ? (!isUpdating ? (<IoSend onClick={() => { updateContactNote() }} className='hover:text-zinc-700 cursor-pointer' />) : (<div className='spinner'></div>)) : (<></>)}
                </div>
                {!editClicked ? (<div className='overflow-auto mt-5 flex-grow'>
                    <p>{note?.noteText}</p>
                </div>) : (<textarea name="" value={editedText} placeholder='Write something...' className="p-5 w-full text-black h-full bg-transparent" onChange={(e) => { setEditedText(e.target.value) }} id=""></textarea>
                )}

            </div>
        </>
    )
}

export default Note