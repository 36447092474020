import React, { useEffect, useState } from 'react'
import '../../../MainPage/customsb.css'
import HorizontalHeader from '../HorizontalHeader/HorizontalHeader';
import ActivePlan from './ActivePlan';
import SuggestedPlan from './SuggestedPlan';
import UpsertPlan from '../UpsertPlan/UpsertPlan';
import { useParams } from 'react-router-dom';
import supabase from '../../../../config/supabse';



function UpgradePlan() {



  const [myId, setMyId] =useState('0')
  const {credId} =useParams()
  useEffect(()=>{
    const getSession = async () => {
        const { data, error } = await supabase.auth.getSession();
        if (error) {
          console.error("Error retrieving session:", error.message);
          return;
        }
        if (data) {
          if (
            data &&
            data.session &&
            data.session.user.id &&
            data.session.access_token
          ) {
            setMyId(data.session.user.id)
          }
        }
      };
      getSession();
},[])


    // const CurrentPlanfeatures = [
    //     'Includes $5 of usage monthly',
    //     'Priority support included',
    //     'Access to all premium features',
    //     'Custom integrations available',
    //     'Up to 100,000 API calls per month'
    // ];
    return (
        <>
        <UpsertPlan/>
           <HorizontalHeader headerTitle={"Plans"} />
            <div className='flex flex-col gap-10 overflow-auto w-full p-10 custom-scrollbar'>
              
               { credId && myId && credId===myId && <section className="flex flex-col flex-grow w-full 2xl:w-[80%]  first:mt-0 last:mb-0">
                  <ActivePlan/>
                   <SuggestedPlan/>

                </section>}


            </div>

        </>



    )
}

export default UpgradePlan