import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Paywall from "../Paywall/Paywall";
import supabase from "../../../../config/supabse";
import ContactCard from "./ContactCard";
import { useParams } from "react-router-dom";

const ContactMain = () => {
  const ActivePlan = useSelector((state) => state.userDetails.planDetails);
  const ActivePlanId = useSelector((state) => state.userDetails.planId);
  const subDomain = useSelector((state) => state.Name.subdomain);
  const {credId } =useParams()
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);

    // Format date as "August 9, 2024"
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });

    // Format time as "6:00 PM"
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    return {
      formattedDate: dateFormatter.format(date),
      formattedTime: timeFormatter.format(date)
    };
  };

  // Function to filter unique emails
  const filterUniqueEmails = (data) => {
    const seenEmails = new Set();
    return data.filter(item => {
      if (seenEmails.has(item.email)) {
        return false; // Skip duplicate emails
      }
      seenEmails.add(item.email);
      return true;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: fetchedData, error } = await supabase
          .from('userMessages') // Replace with your table name
          .select('*')
          .eq('siteId', `${subDomain}`); // Replace 'domain' with your column name

        if (error) throw error;

        const formattedMessages = fetchedData.map(message => {
          const { formattedDate, formattedTime } = formatDateTime(message.created_at);
          return {
            id: message.id,
            firstName: message.messageBody['first-name'],
            lastName: message.messageBody['last-name'],
            body: message.messageBody['message'],
            email: message.messageBody['email'],
            phone: message.messageBody['phone'],
            stamp: `${formattedDate} at ${formattedTime}`,
            formattedDate, // Add formattedDate
            formattedTime  // Add formattedTime
          };
        });

        const uniqueEmailData = filterUniqueEmails(formattedMessages);
        setData(uniqueEmailData);

   

        console.log("CRM data", uniqueEmailData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [subDomain]);



  useEffect(() => {
    const upsertContacts = async () => {
      try {
        // Fetch existing contacts for the user
        const { data: existingContacts, error } = await supabase
          .from('userContacts')
          .select('*')
          .eq('userId', credId);
  
        if (error) {
          console.error('Error fetching contacts:', error);
          return;
        }
  
        // Extract existing emails
        const existingEmails = existingContacts.map(contact => contact.contactEmail);


        console.log("Exist Emails :", existingEmails)

  
        // Filter out emails that are not in the existing contacts
        const newEmails = data.filter(item => !existingEmails.includes(item.email));

        console.log("New Emails :", newEmails)
  
    
        if (newEmails.length > 0) {
          const { error: insertError } = await supabase
            .from('userContacts')
            .insert(
              newEmails.map(item=> ({
                userId : credId,
                contactEmail : item.email,
                contactPhone :  item.phone,
                contactName : `${item.firstName} ${item.lastName}`
                
              }))
            );
  
          if (insertError) {
            console.error('Error inserting new contacts:', insertError);
          } else {
            console.log('New contacts inserted successfully');
          }
          }
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    };
  
    if (data?.length) {
      upsertContacts();
    } 
  }, [data, credId]);

  return (
    <>
      {!ActivePlan?.isCRMAllowed ? (
        <Paywall panel={"CRM"} />
      ) : (
        <>
          {Loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="p-6">
              <h2 className="text-[24px] font-medium font-manrope">
                Your Contacts
              </h2>

              <div className="flex flex-wrap mt-10 w-full h-full overflow-auto  gap-4">
                {data && data?.length > 0 && data?.map((contact, index) => (
                  <>
                   <ContactCard key={index} data={contact} />
            
                  </>
                 
                  
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ContactMain;
