import { useState } from 'react';
import DomainSetupNav from './DomainSetupNav';
import DomainConfig from './DomainConfig';
import { IoSettingsOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import '../../../../SignIn/spinner.css';
import { useSelector } from 'react-redux';
import supabase from '../../../../../config/supabse';

function CustomDomainConfig({ customDomainData }) {
    // State to track which domain's setup navigation is shown
    const [activeDomain, setActiveDomain] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const subDomain = useSelector((state) => state.Name.subdomain);

    async function deleteDomain(customDomain, subDomain) {
        try {
            const { error: deleteError } = await supabase
                .from('customDomains')
                .delete()
                .eq('domain', customDomain)
                .eq('helloworldDomain', subDomain);

            if (deleteError) {
                console.log(`Error deleting entry: ${deleteError.message}`);
                return false; // Indicate failure
            }

            console.log('Domain deleted successfully');
            return true; // Indicate success
        } catch (error) {
            console.error('Error in deleteDomain:', error.message);
            return false; // Indicate failure
        }
    }

    async function updateDomainInWebsites(customDomain, subDomain) {
        try {
            const { error: updateError } = await supabase
                .from('websites')
                .update({
                    customDomain: null,
                    primaryDomain: `${subDomain}.helloworld.to`
                })
                .eq('customDomain', customDomain);

            if (updateError) {
                console.log(`Error updating entry: ${updateError.message}`);
                return false; // Indicate failure
            }

            console.log('Domain updated successfully');
            return true; // Indicate success
        } catch (error) {
            console.error('Error in updateDomainInWebsites:', error.message);
            return false; // Indicate failure
        }
    }

    const handleRemoveDomain = async (domain) => {
        setIsProcessing(true);
        try {
            const results = await Promise.all([
                deleteDomain(domain, subDomain),
                updateDomainInWebsites(domain, subDomain)
            ]);

            if (results.every(result => result)) {
                console.log('Both functions completed successfully');
                window.location.reload(); // Refresh the page
            } else {
                console.error('One or both functions failed');
            }
        } catch (error) {
            console.error('Error during domain removal:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <>
            {customDomainData?.map((domainData, index) => (
                <div key={index} className='h-full '>
                    <div className='px-5'>
                        <div
                            onClick={() => setActiveDomain(activeDomain === index ? null : index)}
                            className="w-full h-auto px-4 py-4 bg-white border-gray-300 border rounded-md flex flex-row justify-between cursor-pointer hover:border-zinc-400"
                        >
                            <h1 className="font-normal text-md">{domainData.domain}</h1>
                            <div className="flex flex-row items-center gap-2">
                                <motion.svg
                                    initial={{ rotate: 180 }}
                                    animate={{ rotate: activeDomain === index ? 0 : 180 }}
                                    transition={{ duration: 0.3, ease: "easeInOut" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="hs-accordion-active:block ms-auto flex size-4"
                                >
                                    <path d="m18 15-6-6-6 6" />
                                </motion.svg>
                            </div>
                        </div>
                    </div>

                    {/* Conditionally render DomainSetupNav based on whether this domain is active */}
                    {activeDomain === index && (
                        <>
                            <DomainConfig customDomain={`${domainData.domain}`} domainData={customDomainData} />
                            <DomainSetupNav customDomain={`${domainData.domain}`} nav={parseInt(domainData.configurationStep)}  domainData={customDomainData} />
                            <button
                                onClick={() => handleRemoveDomain(domainData.domain)}
                                disabled={isProcessing}
                                className={`bg-black  text-white px-3 py-2 rounded-md ml-5 mt-3 mb-5 ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isProcessing ? 'Removing...' : 'Remove Domain'}
                            </button>
                        </>
                    )}


                </div>
            ))}
        </>
    );
}

export default CustomDomainConfig;
