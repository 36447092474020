import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import supabase from '../../../../config/supabse';
import { useParams } from 'react-router-dom';


function SuggestedPlan() {
    const ActivePlan = useSelector((state) => state.userDetails.planDetails);
    const ActivePlanId = useSelector((state) => state.userDetails.planId);
    const Email = useSelector((state) => state.userDetails.email);
    const {credId} =useParams()

    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlans = async () => {
            const { data, error } = await supabase
                .from('plans')
                .select('*') // Select all rows and columns
                .gt('planId', ActivePlan.planId);

            if (error) {
                console.error("Error fetching plans:", error.message);
                setError(error.message);
            } else {
                const filteredAndSortedPlans = data
                    .filter(plan => plan.planId > ActivePlanId) // Filter plans based on planId
                    .sort((a, b) => a.planId - b.planId);      // Sort plans by planId in ascending order

                setPlans(filteredAndSortedPlans);
                console.log(data)
            }
            setLoading(false);
        };

        fetchPlans();
    }, []);





    return (
        <>
            {ActivePlanId.planId < 4 && <div id="upgrading-options" class="flex flex-col space-y-1 items-center justify-center mt-10">
                <p class="text-lg font-semibold">Ready to go further?</p>
                <p class="text-base text-gray-600">Upgrade and outship the competition</p>

            </div>}

            <div className='flex flex-row gap-5 mt-10 items-center justify-center'>
                {plans.map((plan, index) => (<div key={index} className="flex flex-col h-full space-y-8 p-8 rounded-xl border shadow-sm border-opacity-40 w-full drop-shadow-[0_17px_17px_rgba(255,255,225,0.17)] border-zinc-400 bg-white">
                    <div>
                        <div className="text-[24px] font-bold leading-10 text-black ">{plan.title}</div>
                        <p className="leading-7 font-normal text-black">
                            {plan.tagline}
                        </p>
                    </div>
                    <div className="flex-col gap-[2px] flex text-black">
                        <div className="h-12 gap-[12px] justify-start items-center inline-flex pb-2">
                            <span className="text-[24px] leading-10">&pound;</span>
                            <span className="text-[56px] font-semibold leading-10">{plan.monthlyChargeInGBP}</span>
                            <span className="text-[32px] font-normal leading-10">/month</span>
                        </div>
                        <div className="items-center gap-[12px] inline-flex text-[14px] font-semibold uppercase leading-normal">
                            <svg className="w-4" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M12 5v14m-7-7h14"></path>
                            </svg>
                            Resource usage
                        </div>
                    </div>
                    <div className="justify-center items-center inline-flex my-4">
                        <div className="w-full h-[1px] bg-gray-100 bg-opacity-5"></div>
                    </div>
                    <div className="flex flex-col space-y-4">
                        {[
                            { condition: plan?.isCRMAllowed, feature: 'Customer Relationship Management Panel' },
                            { condition: plan?.isCustomDomainAllowed, feature: 'Custom Domain Configuration' },
                            { condition: plan?.isSEOAllowed, feature: 'Search Engine Optimization for Your Pages' },
                            { condition: plan?.isMobileOptimizationAllowed, feature: 'Fully Responsive Pages' },
                            { condition: true , feature : `Number of Pages Supported ${plan?.numberOfPagesAllowed} `},
                            { condition: true , feature : `Number of Responses on Contact Form Supported ${plan?.numberOfMessageRequestsAllowed} `}
                        ]
                            .filter(item => item.condition)  // Keep only items where the condition is true
                            .map(item => item.feature).map((feature, index) => (
                                <div key={index} className="justify-start items-center gap-3 inline-flex">
                                    <div className="w-5 h-5 p-1 rounded-xl border justify-center items-center flex bg-gray-50 border-gray-200">
                                        <svg className="text-black" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </div>
                                    <div className="grow shrink basis-0 p-0 flex-col justify-start items-start inline-flex text-black">
                                        <div className="self-stretch text-4 leading-normal">{feature}</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <a
                        className="group/button flex items-center justify-center transform transition-transform duration-50 active:scale-95 focus:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50 border-black hover:border-gray-600 disabled:bg-gray-500 disabled:border-gray-500 focus-visible:ring-gray-600 h-[42px] py-2 px-3 rounded-md text-base leading-6 space-x-3 bg-black text-white font-semibold border-0 w-full"
                        href={plan.stripeProductionLinkMonthly + "?prefilled_email=" + Email + '&client_reference_id=' +credId}
                    >
                        <span className="inline-block">Upgrade to Pro</span>
                    </a>
                </div>))}
            </div>


        </>
    )
}

export default SuggestedPlan