import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../Builder_prompt_components/global_theme/fonts.css";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../UI/inputField.css";
import supabase from "../../config/supabse";
import { PlaceholdersAndVanishInput } from "../HelloWorldWeb/components/PlaceholdersAndVanishInput";
import { useDispatch } from "react-redux";
import { setEmail_, setPlanDetails_, setPlanId_ } from "../../Store/Slices/userDetailsSlice";


function EmailLogin(props) {

  const dispatch = useDispatch()
  const formStyles = {
    width: "100%",
    height: "auto",
    display: "flex",
    position: "relative",
    flexDirection: "row",
    color: "var(--token-0a0787f4-acc7-4c77-bc44-5077db5742f9, rgb(10, 10, 10))",
    gap: 0,
  };

  const inputStyles = {
    appearance: "none",
    width: "100%",
    height: "auto",
    outline: "none",
    padding: "16px 136px 16px 16px",
    borderRadius: "50px",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    letterSpacing: "-0.01em",
    lineHeight: "1.2em",
    background: "transparent",
    border: "1px solid #DDDDDD",
    color: "black",
    boxShadow:
      "inset 0 0 0 0px rgba(255, 255, 255, 0.1), inset 0 0 0 1px var(--token-75959dab-2c20-4b1a-a94b-e2dfa46fe2e8, rgba(255, 255, 255, 0.1))",
  };


  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  const [auth, setAuth] = useState(false);
  const [authEmail, setAuthEmail] =useState('')
  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }
      if (data) {
        if (
          data &&
          data.session &&
          data.session.user.id &&
          data.session.access_token
        ) {
          let userId = data.session.user.id;
          let accessToken = data.session.access_token;
          let email = data.session.user.email
          console.log("In func", email)
          console.log(data)
          setId(userId);
          setToken(accessToken);
          setAuth(true);
          dispatch(setEmail_(email))
          
          // Check if user plan exists
          const { data: userPlanData, error: userPlanError } = await supabase
            .from('userPlans')
            .select('*')
            .eq('userId', userId)

          if (userPlanError) {
            console.error("Error checking user plan:", userPlanError.message);
          }
          else if (!userPlanData || userPlanData?.length ===0 ) {
            const { error: upsertError } = await supabase
              .from('userPlans')
              .upsert([{ userId: userId, planId: 1 }]);

            if (upsertError) {
              console.error("Error upserting user plan:", upsertError.message);
            } else {
              console.log("User plan upserted successfully.");
            }
          } 
          else {
            console.log("User plan already exists:", userPlanData);
            fetchPlanById(userPlanData[0].planId)
          }
        }
      }
    };
    getSession();
  }, []);




const fetchPlanById = async (planId) => {
  const { data, error } = await supabase
    .from('plans')
    .select('*')
    .eq('planId', planId)

  if (error) {
    console.error("Error fetching plan:", error.message);
  } else if(data){
    console.log("THe Plan: ", data )
    dispatch(setPlanId_({planId: planId}))
    dispatch(setPlanDetails_({planDetails : data[0]}))
  }
};



  const [email, setEmail] = useState("");
  const [isInserting, setIsInserting] = useState(false);

  const navigate = useNavigate();
  const showSuccessMessage = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: "",
    });
  };

  const showRejectMessage = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [emailSent, setEmailSent] = useState(false);

  async function insertUser() {
    if (email === null) {
      showRejectMessage("Enter Email");
      return;
    }
    setIsInserting(1);

    let { data, error } = await supabase.auth.signInWithOtp({
      email: email,
    });

    if (data) {
      setEmailSent(true);
      setTimeout(() => {
        setEmailSent(false);
      }, 10000);
      console.log(data);
    }
    setIsInserting(0);
  }

  const checkIfEmailExists = async (email) => {
    const { data, error } = await supabase
      .from("wishlist")
      .select("email")
      .eq("email", email)
      .single(); // Use single() to get a single record or null

    if (error) {
      console.error("Error checking email existence:", error);
      return null; // Error occurred
    }
    return data; // Return the data if found
  };

  const addToWishlist = async (email) => {
    setIsInserting(true);

    const existingEmail = await checkIfEmailExists(email);

    if (existingEmail) {
      alert("This email is already queued in the wishlist.");
      setIsInserting(false);
      return;
    }

    const { data, error } = await supabase.from("wishlist").insert([{ email }]);

    if (error) {
      console.error("Error inserting data:", error);
      setIsInserting(false);
    } else {
      console.log("Insertion successful:", data);
      alert("You have successfully queued in the wishlist.");
      setIsInserting(false);
    }
  };

  const handleButtonClick = () => {
    if (email) {
      addToWishlist(email);
      setEmail(""); // Clear input field after submission
    } else {
      alert("Please enter an email address.");
    }
  };


  const placeholders = [
    "Enter Your Email",
    "Join the Wishlist",
    "email@domain.com",
  ];


  const handleChange = (e) => {
    setEmail(e.target.value);
  }
  return (
    <>
      {" "}
      {!auth ? (
        <div className="flex items-center justify-center w-full">
          <div className="w-[100%]">
            <div style={formStyles}>
              {/* <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="email@email.com"
                className="v1 framer-custom-input"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                style={inputStyles}
              />
              <div
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  bottom: "5px",
                }}
              >
                {!isInserting ? (
                  <button
                    onClick={() => {
                      handleButtonClick();
                    }}
                    className="flex items-center justify-start w-full h-full px-6 text-sm text-white bg-black rounded-full font_inter hover:border-white"
                  >
                    Join Waitlist
                  </button>
                ) : (
                  <button className="w-full  h-full flex justify-start px-3 font_inter text-sm items-center bg-[#c2a97f]  text-black rounded-[5px] hover:border-white">
                    Adding...
                  </button>
                )}
              </div> */}

              <PlaceholdersAndVanishInput
                placeholders={placeholders}
                onChange={handleChange}
                onSubmit={handleButtonClick}
                buttonText={"Join Waitlist"}
              />
            </div>

            {emailSent ? (
              <div className="bg-[#000000e2] border-2 border-[#00000062] text-sm w-full h-auto rounded-lg mt-4 px-4 py-5 text-black">
                <h1 className="font_inter">
                  {" "}
                  The confirmation link has been sent to the following email:{" "}
                  <span className="text-[#FFD488]">{email}</span>{" "}
                </h1>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-auto">
          <button
            onClick={() => {
              navigate(`/loading/${id}/${token}/1`);
            }}
            className="flex items-center justify-start w-auto h-auto px-6 py-2 text-white bg-black rounded-full text-md font_inter"
          >
            Start Building
          </button>{" "}
        </div>
      )}{" "}
    </>
  );
}

export default EmailLogin;
