import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import '../Builder_prompt_components/global_theme/fonts.css'
import supabase from '../../config/supabse';

function LogOut(props) {

  const navigate = useNavigate();
  const { credId, token } = useParams();
  const [isPublishing, setIsPublishing] = useState(false);

  const Idea = useSelector((state) => { return state.Idea.data })
  const Name = useSelector((state) => { return state.Name.data })
  const subdomainId = useSelector((state) => { return state.Name.subdomain })
  const Color = useSelector((state) => { return state.Color.data })
  const Hue = useSelector((state) => { return state.Color.hue })
  const siteId = useSelector((state) => { return state.Color.siteId })
  const Fonts = useSelector((state) => { return state.Color.fonts })
  const Section = useSelector((state) => { return state.Section.data })
  const Data = useSelector((state) => state.userHtml.data);
  const Pages = useSelector((state) => state.userHtml.pages);
  const index = useSelector((state) => state.userHtml.index);
  const isSiteCreated = useSelector((state) => state.userHtml.isSiteCreated);
  const history = useSelector((state) => state.userHtml.history);
  const Queries = (useSelector(state => state.Query.data));
  const Images = (useSelector(state => state.Query.images));
  const ImagesUsed = (useSelector(state => state.Query.used));



  async function saveData() {
    setIsPublishing(true)
    const { data, error } = await supabase
      .from('histories')
      .upsert(
        [
          {
            webIdea: Idea,
            webName: Name,
            webColor: Color,
            userData: {
              data: Data,
              pages: Pages,
              index: index,
              history: history,
              isSiteCreated: isSiteCreated
            },
            userAssets:
            {
              images: Images,
              queries: Queries,
              used: ImagesUsed
            },
            userId: credId,
            webSectionType: Section,
            webTheme: {
              hue: Hue,
              fonts: Fonts
            },
            siteId: siteId,
            history: history,
            domain: subdomainId

          }
        ],
        { onConflict: ['domain'] }
      );


    if (error) {
      console.error('Error upserting data:', error);
      setIsPublishing(false)
    } else {
      console.log('Data upserted successfully:', data);
      setIsPublishing(false)
    }
  }

  const pages_ = (useSelector(state => state.userHtml.pages));
  const domain_ = (useSelector(state => state.Name.subdomain))
  console.log("Pages : ", pages_)
  console.log("Domain : ", domain_)


  async function upsertWebsite(subdomain) {
    const { data, error } = await supabase
      .from('websites')
      .upsert([{ domain: subdomain, lastUpdate: new Date() }], { onConflict: ['domain'], returning: 'minimal' });

    if (error) {
      console.error('Error upserting website:', error.message);
      return null;
    }

    console.log('Upsert successful for subdomain:', subdomain);
    return data;
  }



  async function insertWebsite(pages, domain) {

    setIsPublishing(true)
    const folderPath = `${domain}/`;
    const updatedWebsite = parsePages(pages);

    try {
      // First, list all files in the folder
      const { data: files, error: listError } = await supabase.storage
        .from('hello-world')
        .list(folderPath, { limit: 1000 }); // Adjust limit if necessary

      if (listError) {
        console.error('Error listing files:', listError.message);
        throw listError; // Exit early if there’s an error listing files
      }

      // Delete all files in the folder
      const deletePromises = files.map(file =>
        supabase.storage
          .from('hello-world')
          .remove([`${folderPath}${file.name}`])
      );

      const deleteResults = await Promise.all(deletePromises);

      deleteResults.forEach(({ error, data }, index) => {
        if (error) {
          console.error(`Error deleting ${files[index].name}:`, error.message);
        } else {
          console.log(`Deleted ${files[index].name} successfully.`);
        }
      });

      // Upload new files
      const uploadPromises = Object.entries(updatedWebsite).map(([key, htmlCode]) => {
        const fileName = `${key.toLowerCase()}.html`;
        return supabase.storage
          .from('hello-world')
          .upload(`${folderPath}${fileName}`, new Blob([htmlCode], { type: 'text/html' }))
          .then(({ data, error }) => {
            if (error) {
              console.error(`Error uploading ${fileName}:`, error.message);
            } else {
              console.log(`Uploaded ${fileName} successfully.`);
            }
          });
      });

      await upsertWebsite(domain)
      await Promise.all(uploadPromises);

    } catch (error) {
      console.error('Error handling files:', error);
    }

    setIsPublishing(false)


  }

  function mergeHtmlTemplates(page) {
    let mergedHtml = "";
    page.forEach(section => {
      mergedHtml += section.htmlTemplate;
    });
    return mergedHtml;
  }




  function parsePages(pages) {
    const parsedPages = {};
    for (const pageName in pages) {
      if (pages.hasOwnProperty(pageName)) {
        const sections = pages[pageName];
        const mergedHtml = mergeHtmlTemplates(sections);
        const finalHtml = `<!DOCTYPE html>
        <html>
        <head>
        <meta charset="utf-8" />
        <script src="https://cdn.tailwindcss.com"></script>
        <meta name="siteId" content=${subdomainId}>
        <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Manrope&display=swap" rel="stylesheet">
<meta name="viewport" content="width=device-width, initial-scale=1.0">

        <script src="https://cdn.jsdelivr.net/npm/@supabase/supabase-js@2.0.0/dist/umd/supabase.min.js"></script>
      

<script>

document.addEventListener('DOMContentLoaded', function() {
var sendButton = document.getElementById('send-button');

sendButton.addEventListener('click', function() {
var contactSections = document.querySelectorAll('[jsid="contact-us-section"]');

    contactSections.forEach(function(section) {
        var inputs = section.querySelectorAll('input, textarea'); // Include textarea elements
        var formData = {};
        
        inputs.forEach(function(input) {
            formData[input.name] = input.value;
        });
        
        // Add siteId from meta tag if available
        var metaSiteId = document.querySelector('meta[name="siteId"]');
        if (metaSiteId) {
            formData['siteId'] = metaSiteId.content;
        }
        
        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://helloworldai-production.up.railway.app/api/user/email/', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {

                    showCustomAlert('Message Sent!', 'success');
                } else {
                    showCustomAlert('Failed to send message', 'error');
                }
            }
        };
        xhr.send(JSON.stringify(formData));
    });
});

function showCustomAlert(message, type) {
    var alertBox = document.createElement('div');
    alertBox.classList.add('custom-alert', type);
    alertBox.textContent = message;
    
    document.body.appendChild(alertBox);
    
    setTimeout(function() {
        alertBox.style.opacity = '0';
        setTimeout(function() {
            alertBox.parentNode.removeChild(alertBox);
        }, 600);
    }, 3000); // Adjust timing as needed
}
});

</script>
<script>
    function toggleAccordion(index) {
        const items = document.querySelectorAll('.accordion-item');
        const targetItem = items[index];
        const content = targetItem.querySelector('[name="accordion-content"]');
        const chevron = targetItem.querySelector('[name="chevron"]');
        
        // Hide all items
        items.forEach((item, i) => {
            if (i !== index) {
                item.querySelector('[name="accordion-content"]').classList.add('hidden');
                item.querySelector('[name="chevron"]').classList.remove('rotate-180');
            }
        });

        // Toggle the selected item
        content.classList.toggle('hidden');
        chevron.classList.toggle('rotate-180');
    }
</script>
<script>
// Function to generate a 10-character unique ID
function generateVisitorId() {
  return Math.random().toString(36).substr(2, 10);
}

// Function to detect the device type
function getDeviceType() {
  const ua = navigator.userAgent;
  if (/tablet|ipad|playbook|silk|(android(?!.*mobi))/i.test(ua)) {
    return 2; // Tablet
  }
  if (/Mobile|iP(hone|od)|Android.*Mobile|Windows Phone|webOS|BlackBerry/i.test(ua)) {
    return 3; // Mobile
  }
  return 1; // Desktop
}

// Function to get the meta tag content by name
function getMetaContent(name) {
  const meta = document.querySelector(\`meta[name="#33dollar{name}"]\`);
  return meta ? meta.content : '';
}

// Function to get the current path
function getPath() {
  return window.location.pathname;
}

// Function to get the cached visitor ID
function getCachedVisitorId() {
  return localStorage.getItem('visitor_id');
}

// Function to set the cached visitor ID
function setCachedVisitorId(id) {
  localStorage.setItem('visitor_id', id);
}

// When the page loads, track the visitor
window.onload = async function() {
  let visitorId = getCachedVisitorId();

  if (!visitorId) {
    visitorId = generateVisitorId();
    setCachedVisitorId(visitorId);
  }

  const userAgent = navigator.userAgent;
  const screen = getDeviceType();
  const siteId = getMetaContent('siteId');
  const path = getPath();

  try {
    // Get the user's IP address and region
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    const ipAddress = data.ip;

    const geoResponse = await fetch(\`https://ipapi.co/#33dollar{ipAddress}/json/\`);
    const geoData = await geoResponse.json();
    const region = geoData.continent_name;

    // Send data to your server
    const serverResponse = await fetch('https://helloworldai-production.up.railway.app/api/user/session/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        visitor_id: visitorId,
        session_start: new Date().toISOString(),
        userAgent: userAgent,
        screen: screen,
        siteId: siteId,
        path: path,
        region: region,
        ip_address: ipAddress
      })
    });

    if (!serverResponse.ok) {
      throw new Error('Server response was not ok.');
    }

    console.log('Session data sent to server successfully');
  } catch (err) {
    console.error('Error fetching IP, geolocation data, or sending data to server:', err);
  }
}
</script>
<script>
window.addEventListener('beforeunload', async (event) => {
// Retrieve values from local storage and meta tag
const visitorId = localStorage.getItem('visitor_id');
const siteId = document.querySelector('meta[name="siteId"]').getAttribute('content');
const path = window.location.pathname;

// Log values for debugging (optional)
console.log("Visitor ID:", visitorId);
console.log("Site ID:", siteId);
console.log("Path:", path);

if (visitorId) {
// Prepare the body for the API request
const body = JSON.stringify({
  session_end: new Date().toISOString(),
  path: path,
  siteId: siteId
});

try {
  // Send the session end data to the server
  await fetch('https://helloworldai-production.up.railway.app/api/user/session/end', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body
  });
} catch (error) {
  console.error('Failed to send session end data:', error);
}
}

// Required to prompt the user with a confirmation dialog before leaving the page
event.returnValue = '';
});
</script>





      <script>
document.addEventListener('DOMContentLoaded', function() {
const openButton = document.querySelector('[name="menu-open-button"]');
const closeButton = document.querySelector('[name="menu-close-button"]');
const closeButton2 = document.querySelector('[name="menu-close-button-link"]');
const mobileMenu = document.querySelector('[name="mobile-menu"]');

if (openButton) {
    openButton.addEventListener('click', function() {
        mobileMenu.style.display = 'flex';
    });
}

if (closeButton) {
    closeButton.addEventListener('click', function() {
        mobileMenu.removeAttribute('style');
        console.log("Removed")
    });
}

 if (closeButton2) {
    closeButton2.addEventListener('click', function() {
        mobileMenu.removeAttribute('style');
        console.log("Removed")
    });
}
});

</script>

<style>
   

.manrope{
font-family: "Manrope", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
font-style: normal;
}


.body{
font-family: "Manrope", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
font-style: normal;
        }



       
        .custom-alert {
position: fixed;
top: 20px;
left: 50%;
transform: translateX(-50%);
padding: 10px 20px;
border-radius: 5px;
color: #fff;
font-size: 16px;
font-weight: bold;
z-index: 9999;
opacity: 1;
transition: opacity 0.3s ease-out;
}

.custom-alert.success {
background-color: #4CAF50; /* Green */
}



.custom-alert.error {
background-color: #f44336; /* Red */
}
</style>




        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
        <link href="https://tdrdiflbdlftfwudjeim.supabase.co/storage/v1/object/public/hello-world/public/reg_fonts.css" rel="stylesheet">
        </head>
        <body class="custom-scrollbar">
        ${mergedHtml}
        </body>
        </html > `;
        parsedPages[pageName] = finalHtml.replace(/#33dollar/g, '$');;
      }
    }

    return parsedPages
  }

  return (

    <>

      <button onClick={() => {
        insertWebsite(pages_, subdomainId)
        saveData()
        navigate(`/dashboard/${credId}/`)

      }} className="flex items-center gap-2 w-full rounded-lg px-3 py-2 text-zinc-500  group transition-all hover:text-black group justify-center">
        <ArrowLeftIcon className="w-6 h-6 mr-1 text-zinc-500  group-hover:text-black text-xs transition-colors" />
        Back to Dashboard
      </button>

    </>
  )
}

export default LogOut



function ArrowLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m12 19-7-7 7-7" />
      <path d="M19 12H5" />
    </svg>
  )
}

