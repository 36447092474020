import { combineReducers } from 'redux';
import IdeaSlice from './Slices/IdeaSlice';
import NameSlice from './Slices/NameSlice';
import SectionSlice from './Slices/SectionSlice';
import ColorSlice from './Slices/ColorSlice';
import userHtmlSlice from './Slices/userHtmlSlice';
import LogoSlice from './Slices/LogoSlice'
import AssetsSlice from './Slices/AssetsSlice';
import ImageQuerySlice from './Slices/ImageQuerySlice';
import userIdSlice from './Slices/userIdSlice';
import userSiteAnalytics from './Slices/userSiteAnalytics';
import userDetailsSlice from './Slices/userDetailsSlice';


const rootReducer = combineReducers({
   
    Idea: IdeaSlice,
    Name: NameSlice,
    Color: ColorSlice,
    Section: SectionSlice,
    userHtml : userHtmlSlice,
    Logo : LogoSlice,
    Assets : AssetsSlice,
    Query : ImageQuerySlice,
    userId: userIdSlice,
    userSiteAnalytics: userSiteAnalytics,
    userDetails : userDetailsSlice,


});

export default rootReducer;