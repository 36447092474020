import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import supabase from "../../../../config/supabse";
import DomainSetupNav from "./DomainSetupComponents/DomainSetupNav";
import '../../../SignIn/spinner.css'
import CustomDomainConfig from "./DomainSetupComponents/CustomDomainConfig";
import Paywall from "../Paywall/Paywall";

const WebsiteDomain = () => {
  const subDomain = useSelector((state) => state.Name.subdomain);
  const [data, setData] = useState(null);
  const [customDomainData, setCustomDomainData] = useState(null)
  const [customDomain, setCustomDomain] = useState('');
  const [isNewDomainAdded, setIsNewDomainAdded] = useState(false);
  const [isAddingDomain, setIsAddingDomain] = useState(false)
  const [error, setError] = useState('')
   const ActivePlan = useSelector((state) => state.userDetails.planDetails);
  const ActivePlanId = useSelector((state) => state.userDetails.planId);


  useEffect(() => {
    const fetchData = async () => {
      if (!subDomain) return;

      const { data: websites, error } = await supabase
        .from('websites') // Replace with your actual table name
        .select('*')
        .eq('domain', subDomain)
        .single();

      if (error) {
        console.error('Error fetching data:', error);
        return;
      }

      setData(websites);
    };



    const fetchCustomDomainData = async () => {
      if (!subDomain) return;

      const { data: websites, error } = await supabase
        .from('customDomains') // Replace with your actual table name
        .select('*')
        .eq('helloworldDomain', subDomain)


      if (error) {
        console.error('Error fetching data:', error);
        return;
      }

      setCustomDomainData(websites);
    };

    fetchCustomDomainData();
    fetchData()
  }, [subDomain]);


  const addInCustomDomainRelation = async () => {
    const { data, error } = await supabase
      .from('customDomains')
      .insert({ domain: customDomain, helloworldDomain: subDomain })

    if (error) {
      setIsAddingDomain(false)
      console.error("Error adding customDomain:", error);
    } else {
      console.log("Domain uaddition successfully");
    }

  }


  const updateCustomDomainInWebsites = async () => {
    const { data: updatedData, error: updateError } = await supabase
      .from('websites')
      .update({ customDomain: customDomain })
      .eq('domain', subDomain);

    if (updateError) {
      setIsAddingDomain(false)
      console.error("Error updating customDomain:", updateError.message);
    } else {
      console.log("Domain updated successfully", updatedData);
    }
  }

  const handleNewDomainAdded = async () => {
    setError('')
    setIsAddingDomain(true)
    // Step 1: Validate the customDomain
    const isValidDomain = (domain) => {
      const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/;
      return domainRegex.test(domain);
    };

    if (!isValidDomain(customDomain)) {
      console.log("Invalid domain");
      setIsAddingDomain(false)
      setError("Invalid Domain")
      return;
    }

    // Step 2: Check if the customDomain exists in the Supabase "websites" relation
    const { data, error } = await supabase
      .from('websites')
      .select('customDomain')
      .eq('customDomain', customDomain)


    if (error) {
      setIsAddingDomain(false)
      console.error("Error checking domain in Supabase:", error.message);
      return;
    }

    if (data && data?.length === 0) {
      await Promise.all([updateCustomDomainInWebsites(), addInCustomDomainRelation()]);
      setIsNewDomainAdded(true)

    } else {
      setIsAddingDomain(false)
      setError("Domain Already Exists on Helloworld Platform")
      console.log("Domain already exists");
    }

    setIsAddingDomain(false)

  };




  return (
    <>{!ActivePlan.isCustomDomainAllowed ?
      (<Paywall panel={"Custom Domain"}/>):( <div className="p-10 relative">
        <div className="bg-[#FbFbFb] rounded-lg w-[80%]  h-auto">
          <h2 className="text-xl font-semibold font-manrope text-[16px] leading-[20px] p-10">
            Domain
          </h2>
          <div className="border-b border-gray-300"></div>
          <div className="p-10 border-b border-gray-300">
  
            <label className="block text-sm font-medium font-manrope leading-[20px] text-[#000000] mb-2">
              Current Domain
            </label>
  
            <div className="bg-[#ffffff] border border-gray-300 rounded-lg p-4 text-[#000000] font-manrope text-md font-medium leading-[20px] tracking-[0.01em] ">
              {data && `https://${data?.primaryDomain}`}
            </div>
          </div>
  
          {customDomainData && customDomainData?.length===0 && <div className="p-10 border-gray-300">
            <div className="flex flex-row justify-between w-full items-center">
              <label className="block text-sm font-medium text-[#000000] mb-2 font-manrope leading-[20px]">
                Add Custom domain
              </label>
              <p className="text-sm mb-2 text-red-500">
                {error}
  
              </p>
            </div>
  
            <div className="flex items-center relative">
              <div className="flex-grow relative bg-[#ffffff] border border-gray-300 rounded-lg p-4 text-black focus:outline-none font-manrope text-md font-medium leading-[20px] tracking-[0.01em]">
                {isNewDomainAdded ? (
                  <p className="bg-transparent border-none w-full h-full text-md">{customDomain}</p>
                ) : (
                  <input
                    type="text"
                    value={customDomain}
                    onChange={(e) => setCustomDomain(e.target.value)}
                    className="bg-transparent border-none w-full h-full"
                  />
                )}
              </div>
  
              {!isNewDomainAdded && (
  
                isAddingDomain ? (<div className="spinner absolute right-2"></div>) : (<button
                  onClick={() => { handleNewDomainAdded() }}
                  className="bg-[#ffd488] absolute right-2 text-[#000000] font-semibold font-manrope text-[12px] leading-[20px] tracking-[0.01em] px-4 py-2 rounded-lg hover:bg-[#fac468] focus:outline-none"
                >
                  Add
                </button>)
  
  
              )}
            </div>
          </div>}
  
          {isNewDomainAdded && (
            <div className="p-4">
              <DomainSetupNav customDomain={customDomain} nav={1} domainData={customDomainData} />
            </div>
          )}
  
  
  
  
         {customDomainData && customDomainData?.length>0 && <div className="px-5 py-5">
            <p className="mb-2 px-5"> Custom Domains</p>
            <CustomDomainConfig customDomainData={customDomainData}/>
            <div className="h-3 w-full">

            </div>
          </div>}
  
  
  
  
  
  
  
        </div>
      </div>)
    }
    
    </>
   
  );
};

export default WebsiteDomain;
