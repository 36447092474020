import React from "react";
import ContactMain from "../DashboardComponents/Contact/ContactMain"
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";


const ContactDetails = () => {
  return (
    <>
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
      
      <HorizontalHeader headerTitle={"Contacts"} />
        <div className="flex-1 overflow-auto p-4">
          <ContactMain />
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
