import React from "react";
import CRMMain from "../DashboardComponents/CRM/CRMMain";
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";
import Paywall from "../DashboardComponents/Paywall/Paywall";
import { useSelector } from "react-redux";
import UpsertPlan from "../DashboardComponents/UpsertPlan/UpsertPlan";
const CRM = () => {
  const ActivePlan = useSelector((state) => state.userDetails.planDetails);
  const ActivePlanId = useSelector((state) => state.userDetails.planId);

  return (
    <>
      {" "}
      <UpsertPlan/>
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
        <HorizontalHeader headerTitle={"CRM"} />
        <div className="flex-1 overflow-auto p-4">
          {!ActivePlan.isCRMAllowed ?
            (<Paywall panel={"CRM"} />

            ) : (
              <CRMMain />)}
        </div>
      </div>
    </>
  );
};

export default CRM;
