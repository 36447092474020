// import { useEffect, useState } from "react";
import React, { useEffect, useRef, useState } from "react";
import '../Builder_prompt_components/global_theme/fonts.css'
// import profile from '../../assets/profile.svg';
// import settings from '../../assets/settings.svg';
// import cross from '../../assets/cross.svg';
// import menu from '../../assets/menu.svg';
import '../../components/MainPage/Mainpage.css';
import SectionSelector from "../Builder_prompt_components/SectionSelection/SectionSelector";
import { useDispatch, useSelector } from "react-redux";
import { deletePage, deleteUserHtml, setPageIndex, swapSectionWithLowerSection, swapSectionWithUpperSection } from "../../Store/Slices/userHtmlSlice";
import DragAndDropUpload from "./DragAndDropUpload";
import AddNewPage from "./AddNewPage";
import LogOut from "./LogOut";
import './scrollable.css'
import DND from "./DND";
// import SectionEditor from "./SectionEditor";

import { GoSmiley } from "react-icons/go";

const Sidebar = (props) => {
  const dispatch = useDispatch()

  const [subsection, setSubSection] = useState(0)
  const ActivePlan = useSelector((state) => state.userDetails.planDetails);
  const ActivePlanId = useSelector((state) => state.userDetails.planId);


  const [active1, setActive1] = useState(1)
  const [active2, setActive2] = useState(0)
  const [active3, setActive3] = useState(0)

  var data = props.data_section;

  // Add the order attribute to each dictionary
  var data = data.map((item, index) => ({
    ...item,
    id: index + 1,
    title: sectionName(item.sectionType)
  }));


  const [isSectionSelectorVisibile, setIsSectionSelectorVisible] = useState(0)
  function setSectionSelectorVisible(val) {
    setIsSectionSelectorVisible(val)
  }

  function displaySectionSelector() {
    if (isSectionSelectorVisibile)
      setIsSectionSelectorVisible(0)
    else
      setIsSectionSelectorVisible(1)
  }
  function sectionName(type) {
    if (type === 0) return "Navigation"
    else if (type === 1) return "Header"
    else if (type === 2) return "Features"
    else if (type === 3) return "Testimonial"
    else if (type === 4) return "About"
    else if (type === 5) return "Blog"
    else if (type === 6) return "Banner"
    else if (type === 7) return "Contact"
    else if (type === 8) return "Blog"
    else if (type === 9) return "Footer"
    else if (type === 10) return "Header Page"
    else if (type === 11) return "News Letter"
    else if (type === 12) return "Content"
    else if (type === 13) return "Product Catalog"
    else if (type === 14) return "Logo Cloud"
    else if (type === 15) return "Gallery"
    else if (type === 16) return "Blog Cards"
    else if (type === 17) return "Faqs"
  }


  function swapUP(id) {
    const payload = {
      _id: id
    }
    dispatch(swapSectionWithUpperSection(payload))

  }

  function swapBelow(id) {
    const payload = {
      _id: id
    }
    dispatch(swapSectionWithLowerSection(payload))


  }


  const getPageCount = (pages) => {
    return Object.keys(pages).filter(pageName => pageName !== "temp" && pageName !== undefined)?.length;
  };


  const dragItem = useRef(0)
  const draggedOverItem = useRef(0)


  function handleMove() {
    const payload = {
      itemId: dragItem.current,
      swapWithId: draggedOverItem.current
    }

    console.log("ItemId", dragItem.current)
    console.log("Swap:", draggedOverItem.current)
    dispatch(swapSectionWithUpperSection(payload))


  }


  useEffect(() => {
    console.log("Data Changed In Sidebar : ", props.data)
  }, [props.data])


  return (
    <div className="overflow-hidden scrollable h-full bg-white rounded-xl  text-black  w-[100%] flex flex-col md:pr-2 items-center justify-between ">
      <div className={` w-full h-[100%] flex flex-col  items-center  `}>

        <div className="w-[90%] px-1 h-[55px] rounded-[5px] font_inter mt-5 bg-[#F4f4f4] flex flex-row items-center justify-center">
          <div className="flex flex-row w-full h-[55px] justify-between items-center py-1">
            <button onClick={() => { setSubSection(0); setActive1(1); setActive2(0); setActive3(0) }} className={`font_inter  ${active1 === 1 ? ('text-black bg-white shadow-md rounded-md px-2') : ('text-zinc-500 ')} w-[87px] h-full   text-md`}>Sections</button>
            <button onClick={() => { setSubSection(1); setActive1(0); setActive2(1); setActive3(0) }} className={`font_inter  ${active2 === 1 ? ('text-black bg-white shadow-md rounded-md px-2') : ('text-zinc-500 ')}  w-[87px] h-full  text-md`}>Pages</button>
            <button onClick={() => { setSubSection(2); setActive1(0); setActive2(0); setActive3(1) }} className={`font_inter  ${active3 === 1 ? ('text-black bg-white shadow-md rounded-md px-2' ) : ('text-zinc-500')}  w-[87px] h-full  text-md`}>Assets</button>
          </div>

        </div>

        {
          subsection === 0 ?
            (<>
              <div className="w-[94%] flex flex-col overflow-auto max-h-[35vh] mb-3 custom-scrollbar mt-3">

                {/* <p className=" text-[#ffffff] text-md font_inter mt-4 mx-2 py-1 text-left px-4 rounded-md  "> Sections </p> */}
                {data ? (<DND handleSaveState={props.handleSaveState} tasks={props.data_section} setData={props.setData} />) : (<></>)}

              </div>
              <div className="flex flex-col items-center justify-center w-full px-4">
                {isSectionSelectorVisibile == 0 ? (

                  <div className="px-3 w-full">
                    <button className="mt-4   rounded-lg text-black  hover:font-medium duration-100 ease-in-out bg-[#f4f4f4]  hover:bg-[#e7e7e7] transition-colors w-full flex justify-center items-center gap-1  py-4"
                      onClick={() => { displaySectionSelector() }}>
                      <PlusIcon className="w-4 h-4 mr-2 text-lg text-black" />  Add New Section </button>
                  </div>

                ) : ''}



                {isSectionSelectorVisibile == 1 ?
                  (
                    <SectionSelector isVisible={setSectionSelectorVisible} />
                  ) : ''}

              </div>
            </>)
            :
            (

              subsection === 1 ? (
              
              ActivePlanId > 2 ? (
                <div className="mt-3 w-full flex flex-col ">
                {Object.keys(props.pages).map((pageName, index) => (
                  pageName !== undefined && pageName !== "temp" ? (
                    <div key={pageName} className="flex flex-col justify-between w-full pl-3 pr-6 ">
                      <div className={`flex flex-row items-center w-full hover:bg-[#f4f4f4] justify-between px-1 text-white text-md mt-3 rounded-md gap-1.5`}>
                        <button
                          className="text-black text-md  mt-1 mx-1 px-3 py-1.5 rounded-md flex flex-row items-center gap-1.5"
                          onClick={() => { dispatch(setPageIndex(pageName)) }}
                        >
                          <MenuIcon className="w-6 h-6 mr-1 text-[#DCDCDC]" />
                          {pageName}
                        </button>
                        {
                          getPageCount(props.pages) > 1 ? (
                            <TrashIcon onClick={() => { dispatch(deletePage({ pageName: pageName })) }} className="w-4 h-4 ml-auto text-[#dcdcdc] hover:text-white cursor-pointer transition-colors mr-2" />
                          ) : (<></>)
                        }
                      </div>
                    </div>
                  ) : (<React.Fragment key={index} />)
                ))}
                <AddNewPage />
              </div>
               ) : (<div className="flex flex-col items-center justify-center mt-10 ">
                <GoSmiley  className='text-3xl text-zinc-500 '/>
                <p className="text-zinc-500 mt-2">Plase Upgrade Your Plan</p>
               </div>)
             


              ) :
                (<>
                  <div className="h-[100%]  w-[94%] flex flex-col  mb-3 custom-scrollbar">
                    <div className="w-full h-full flex items-center justify-center mt-3 p-2 flex-col overflow-hidden">
                      <DragAndDropUpload />
                    </div>
                  </div>
                </>
                )
            )


        }


      </div>
      <div className="p-3 w-full">
        <LogOut data={data} />
      </div>

    </div>
  )
}


export default Sidebar




function TrashIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18" />
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
    </svg>
  )
}



function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  )
}



function PlusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  )
}