import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Import Chart.js
import '../../../SignIn/spinner.css'
import { useSelector } from 'react-redux';
import supabase from '../../../../config/supabse';


const AnalyticsGraph = ({ height, width, data }) => {

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Visitors',
        data: [],
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, 'rgba(255, 186, 87, 0.8)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
          return gradient;
        },
        borderColor: 'black',
        pointBackgroundColor: 'black',
        borderWidth: 1,
        pointRadius: 2,
        tension: 0.4, // for smooth curves
      },
    ],
  });

  useEffect(() => {
    const generateVisitorData = (sessionData) => {
      if (sessionData) {
        const currentDate = new Date();
        const visitorsPerDay = {};

        // Initialize the last 30 days with zero visitors
        for (let i = 29; i >= 0; i--) {
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - i);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
          visitorsPerDay[formattedDate] = 0;
        }

        // Aggregate visitors based on sessionData
        sessionData && sessionData.forEach(session => {
          const date = new Date(session.created_at);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;

          if (visitorsPerDay.hasOwnProperty(formattedDate)) {
            visitorsPerDay[formattedDate] += 1;
          }
        });

        // Convert visitorsPerDay object to arrays
        const dates = Object.keys(visitorsPerDay);
        const visitors = Object.values(visitorsPerDay);

        return { dates, visitors };
      }
    };



    const { dates, visitors } = generateVisitorData(data);

    setChartData({
      labels: dates,
      datasets: [
        {
          label: 'Visitors',
          data: visitors,
          fill: true,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return null;
            }
            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom
            );
            gradient.addColorStop(0, 'rgba(255, 186, 87, 0.8)');
            gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
            return gradient;
          },
          borderColor: 'black',
          pointBackgroundColor: 'black',
          borderWidth: 1,
          pointRadius: 0,
          tension: 0.4, // for smooth curves
        },
      ],
    });
  }, [data]);

  return (
    <div className="rounded-xl " style={{ width: 'full', height: `${height}` }}>
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false, // Prevents automatic scaling of height
          plugins: {
            legend: {
              display: false,
              position: 'top',
            },
          },
          scales: {
            x: {
              title: {
                display: false,
                text: 'Date',
                color: '#8B8B8B',
              },
              grid: {
                display: false,
              },
              ticks: {
                color: '#8B8B8B',
                maxTicksLimit: 10, // Control the number of x-axis labels
              },
            },
            y: {
              title: {
                display: false,
                text: 'Number of Visitors',
                color: '#8B8B8B',
              },
              grid: {
                display: false,
              },
              ticks: {
                color: '#8B8B8B',
                stepSize: 20, // Increase the gap between intervals
              },
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
};

export default AnalyticsGraph;
