import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: "",
}
  
//reducer
const IdeaSlice = createSlice({
  name: 'Idea',
  initialState,
  reducers: {
    setIdea: (state, action) => {
      state.data = action.payload
     
    },
  },
})


// Action creators are generated for each case reducer function
export default  IdeaSlice.reducer
export const { setIdea } = IdeaSlice.actions; 