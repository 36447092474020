import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import HorizontalHeader from '../HorizontalHeader/HorizontalHeader';
import UpsertPlan from '../UpsertPlan/UpsertPlan';
import { useParams } from 'react-router-dom';
import supabase from '../../../../config/supabse';
import '../../../SignIn/spinner.css'


import { MdArrowOutward } from "react-icons/md";

import { MdOutlineFileDownload } from "react-icons/md";



function Billing() {
  const ActivePlan = useSelector((state) => state.userDetails.planDetails);
  const Email = useSelector((state) => state.userDetails.email);
  const [isLoading,setIsLoading] = useState(true)
  const { credId } = useParams()
  const [myId, setMyId] =useState('0')

  const [billingAddress, setBillingAddress] = useState(null);
  const [billingData, setBillingData] = useState([]);





  useEffect(()=>{
    const getSession = async () => {
        const { data, error } = await supabase.auth.getSession();
        if (error) {
          console.error("Error retrieving session:", error.message);
          return;
        }
        if (data) {
          if (
            data &&
            data.session &&
            data.session.user.id &&
            data.session.access_token
          ) {
            setMyId(data.session.user.id)
          }
        }
      };
      getSession();
},[])

  useEffect(() => {
    const fetchUserBilling = async () => {
      setIsLoading(true)
      const { data: user, error: userError } = await supabase
        .from('users')
        .select('billingAddress')
        .eq('userId', credId)

      if (userError) {
        setIsLoading(false)
        console.error('Error fetching user:', userError);
        return;
      }

      const billingAddress = user[0]?.billingAddress;
      setBillingAddress(billingAddress);

      const { data: userBillings, error: billingsError } = await supabase
        .from('userBillings')
        .select('*')
        .eq('customerId', billingAddress)
        .order('created_at', { ascending: false });

      if (billingsError) {
        setIsLoading(false)
        console.error('Error fetching user billings:', billingsError);
        return;
      }

      const processedUserBillings = userBillings.map((billing) => {
        // Convert the created_at timestamp to a Date object
        const date = new Date(billing.created_at);

        // Format the created_at date to "August 27, 2024" format
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const dateString = date.toLocaleDateString('en-US', options);

        // Add 30 days to the created_at date
        const endDate = new Date(date);
        endDate.setDate(endDate.getDate() + 30);

        // Format the endDate to "September 27, 2024" format
        const endDateString = endDate.toLocaleDateString('en-US', options);

        // Create the subscription duration string "August 27, 2024 - September 27, 2024"
        const subscriptionDuration = `${dateString} - ${endDateString}`;

        // Return the new object with the added attributes
        return {
          ...billing,
          date: dateString,
          subscriptionDuration
        };
      });


      setBillingData(processedUserBillings);
      setIsLoading(false)
    };

    credId===myId && fetchUserBilling();
  }, [credId, myId]);
  const capitalizeFirstWord = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };




  return (
    <>
    {isLoading || (myId!==credId) ? (
      <div className='w-full h-full items-center justify-center flex '> 
        <div className='spinner'></div>
      </div>

      ) : (<>
      <UpsertPlan />
      <HorizontalHeader headerTitle={"Billing"} />
      <div className='flex w-full 2xl:w-[80%] h-full p-10'>
        <div className="flex flex-col space-y-12 w-full">
          {/* <div className="flex justify-between items-center">
            <h2 className="text-[36px] font-medium font-manrope leading-[60px] ">
              Billing
            </h2>
          </div>
          <hr className="my-4 w-full border-t border-gray-100" /> */}
          <div className="flex flex-col space-y-12">
            <div className="flex flex-col space-y-8">
              <h2 className="text-h4 font-semibold">Active Plan</h2>
              <div className="flex flex-col space-y-4">
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 p-6 border border-zinc-200 rounded-lg relative">
                  <img
                    alt=""
                    src="/billing/chart-dark.svg"
                    className="absolute bottom-0 left-1/4 hidden lg:flex"
                    style={{ filter: 'invert(0)' }}
                  />
                  <div className="flex flex-col space-y-3">
                    <p className="text-lg font-semibold text-zinc-600">{ActivePlan.title} Plan</p>
                    <p className="text-sm text-zinc-600">
                      The {ActivePlan.title} plan costs &pound; {ActivePlan.monthlyChargeInGBP} per month .The Plan is Perfect for {ActivePlan.tagline}.
                    </p>
                    <a href="https://billing.stripe.com/p/login/aEUg281Us3Kc2o8aEE" target='_blank' className="group/button flex items-center justify-center border transform transition-transform duration-50 active:scale-95 focus:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50 bg-black border-black text-white hover:bg-zinc-700 hover:border-zinc-600 disabled:bg-zinc-700 disabled:border-zinc-600  h-[34px] py-1.5 px-3 rounded-md text-sm leading-5 space-x-2 max-w-max">
                      <span className="inline-block">Manage Subscription</span>
                    </a>
                  </div>
                  <div className="flex flex-col divide-y divide-gray-50 -mt-2">
                    <div className="flex justify-between items-center py-2">
                      <p className="text-sm text-gray-600">Subscription Type</p>
                      <p className="text-sm font-semibold">Monthly {"("} {billingData[0]?.subscriptionDuration}{")"}</p>
                    </div>
                    <div className="flex justify-between items-center py-2">
                      <p className="text-sm text-gray-600">Pages</p>
                      <p className="text-sm font-semibold">{ActivePlan.numberOfPagesAllowed}</p>
                    </div>
                    <div className="flex justify-between items-center py-2">
                      <p className="text-sm text-gray-600">Search Engine Optimization</p>
                      <p className="text-sm font-semibold">{ActivePlan.isSEOAllowed ? ('Enabled') : ('Disabled')}</p>
                    </div>
                    <div className="flex justify-between items-center py-2">
                      <p className="text-sm text-gray-600">Responses</p>
                      <p className="text-sm font-semibold">{ActivePlan.numberOfMessageRequestsAllowed}</p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="flex flex-col space-y-3 p-6 border border-gray-200 rounded-lg">
                    <div className="flex justify-between items-center">
                      <p className="text-base font-semibold">Payment Method</p>
                      {/* <button className="group/button flex items-center justify-center border transform transition-transform duration-50 active:scale-95 focus:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50 bg-transparent border-gray-200 text-foreground dark:text-white hover:bg-gray-100 hover:border-gray-200 disabled:bg-transparent disabled:border-gray-200 disabled:text-foreground focus-visible:ring-gray-600 focus-visible:bg-gray-100 h-[34px] py-1.5 px-3 rounded-md text-sm leading-5 space-x-2">
                    <span className="inline-block">Replace</span>
                  </button> */}
                    </div>
                    <div className="flex flex-col space-y-3">
                      <p className="text-sm text-gray-600">Subscription fees and usage will be billed to this card</p>
                      <div className="flex flex-col bg-gray-50 rounded-md py-2 px-4">
                        <p className="text-base text-gray-600">Card ending in 9286</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-3 p-6 border border-gray-200 rounded-lg">
                    <div className="flex justify-between items-center">
                      <p className="text-base font-semibold">Billing Email</p>

                    </div>
                    <div className="flex flex-col space-y-3">
                      <p className="text-sm text-gray-600">All receipts will be sent here</p>
                      <div className="flex flex-col bg-gray-50 rounded-md py-2 px-4">
                        <p className="text-base text-gray-600">{billingAddress}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-6">
              <h2 className="text-h4 font-semibold">Billing History</h2>
             { billingData && billingData?.length>0 ? (<div className="flex flex-col">
                { billingData && billingData?.length>0 && billingData.map((billing, index) => (<div key={index} className="group grid grid-cols-[1fr_1fr_1fr] md:grid-cols-[1fr_2fr_1fr_1fr] gap-4 items-center px-6 py-4 text-right border-t border-gray-100 hover:bg-secondaryBg">
                  <div className="flex space-x-3 items-center">
                    <div className="icon-container icon-sm text-lg text-green-500" aria-hidden="true">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check-circle"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <path d="M22 4 12 14.01l-3-3"></path>
                      </svg>
                    </div>
                    <p className="text-base whitespace-nowrap">{billing.date}</p>
                  </div>
                  <p className="text-base hidden md:block whitespace-nowrap truncate">{capitalizeFirstWord(billing.interval)}ly</p>
                  <p className="text-base">{" "}{capitalizeFirstWord(billing.type)}{" "}</p>
                  <div className="flex space-x-4 justify-end flex-wrap">
                    <a
                      href={billing.invoiceHosted}
                      rel="noreferrer"
                       target='_blank'
                      name="View Invoice"
                      className="focus:outline-none focus-visible:ring-2 cursor-pointer focus-visible:ring-yellow-700 text-gray-500 group-hover:underline hover:text-yellow-600 flex flex-row items-center justify-center gap-2"
                    >


                      <MdArrowOutward className='text-2xl hover:text-yellow-500 ' />
                      
                    </a>
                    <a
                      href={billing.invoicePDF}
                     
                      rel="noreferrer"
                      name="View Invoice"
                      className="focus:outline-none focus-visible:ring-2 cursor-pointer focus-visible:ring-yellow-700 text-gray-500 group-hover:underline hover:text-yellow-600 flex flex-row items-center justify-center gap-2"
                    >


                      <MdOutlineFileDownload className='text-2xl hover:text-yellow-500' />
                    
                    </a>
                  </div>
                </div>))}
                {/* Repeat similar blocks for other billing history entries */}
              </div>) : (<div className='flex items-center justify-center w-full h-[100px]'>
                No Billing History
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </>)}
      
    </>
  )
}

export default Billing