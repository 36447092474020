import { useState, useRef } from "react";
import { Play, Pause, VolumeX, Volume2 } from "lucide-react";
import { Button } from "./ui/button";

export default function Video() {
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <section className="py-20 px-4 sm:px-6 lg:px-8 rounded-[30px]">
      <div className="max-w-6xl mx-auto flex flex-col gap-2 justify-center ">
        <div className="relative w-full overflow-hidden shadow-lg rounded-[30px]">
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            loop
            muted
            playsInline
          >
            <source src="/appvid.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute bottom-4 right-4 flex gap-2">
            <Button
              variant="secondary"
              size="icon"
              onClick={togglePlay}
              aria-label={isPlaying ? "Pause video" : "Play video"}
            >
              {isPlaying ? (
                <Pause className="h-4 w-4 text-white " />
              ) : (
                <Play className="h-4 w-4  text-white " />
              )}
            </Button>
            <Button
              variant="secondary"
              size="icon"
              onClick={toggleMute}
              aria-label={isMuted ? "Unmute video" : "Mute video"}
            >
              {isMuted ? (
                <VolumeX className="h-4 w-4 text-white " />
              ) : (
                <Volume2 className="h-4 w-4 text-white " />
              )}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}