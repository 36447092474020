import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import supabase from '../../../../../config/supabse';
import '../../../../SignIn/spinner.css'

function DomainOption(props) {
  const subDomain = useSelector((state) => state.Name.subdomain);
  const customDomain = props.customDomain;
  const handleRadioChange = props.handleRadioChange;
  const setSelectedOption = props.setSelectedOption;
  const selectedOption = props.selectedOption;
  const [isConfiguringOption, setIsConfiguringOption] =useState(false)




  async function handleConfigurationOptionChange() {
    setIsConfiguringOption(true); // Set the configuring option state
  
    try {
      // Perform the update operation with both customDomain and subDomain conditions
      const { error: updateError } = await supabase
        .from('customDomains')
        .update({
          configurationStep: 2,
          configurationDomainOption: 1,
        })
        .eq('domain', customDomain)
        .eq('helloworldDomain', subDomain);
  
      if (updateError) {
        console.log(`Error updating row: ${updateError.message}`);
        return; // Exit early if there is an error
      }
  
      console.log('Row updated successfully');
      // props.setNav(2); 
      // setIsConfiguringOption(false)
      window.location.reload()
    } catch (error) {
      console.error('Error in handleConfigurationOptionChange:', error.message);
    } finally {
      setIsConfiguringOption(false); // Reset the configuring option state
    }
  }
  // Animation variants for the container
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }, // Exit animation matches the entry animation but in reverse
  };

  return (
    <AnimatePresence>
      <motion.div
        className="px-5 pb-5"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={variants}
        transition={{ duration: 0.1, ease: "easeInOut" }}
        style={{
          width: '100%', // Full width
          height: 'auto', // Auto height to fit content
        }}
        id="projects-accordion-child"
        role="region"
        aria-labelledby="projects-accordion"
      >
        <div className="w-full flex flex-col bg-white border shadow-sm rounded-md pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
            <h3 id="hs-vertically-centered-modal-label" className="font-bold text-gray-800 dark:text-white">
              {customDomain}
            </h3>
          </div>
          <div className="p-4 overflow-y-auto flex flex-col gap-5">
            <div className="flex flex-row items-center gap-4">
              <input
                type="radio"
                id="option1"
                className="grayscale"
                name="domainOption"
                value={1}
                checked={selectedOption === 1}
                onChange={handleRadioChange}
              />
              <label htmlFor="option1" className="text-md">
                Add <span className="font-bold">{customDomain}</span> and redirect <span className="font-bold">www.{customDomain}</span> to it
              </label>
            </div>

            <div className="flex flex-row items-center gap-4">
              <input
                type="radio"
                className="grayscale"
                id="option3"
                name="domainOption"
                value={2}
                checked={selectedOption === 2}
                onChange={handleRadioChange}
              />
              <label htmlFor="option3" className="text-md">
                Add <span className="font-bold">{customDomain}</span>
              </label>
            </div>
          </div>
          <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
            <button
              onClick={() => {
                props.setSelectedOption(''); // Reset selected option
              }}
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            >
              Close
            </button>
            {!isConfiguringOption ? ( <button
              onClick={() => { handleConfigurationOptionChange() }}
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-200 text-gray-800 hover:bg-primary-200 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
            >
              Next
            </button>):(<div className='spinner'></div>)}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default DomainOption;
