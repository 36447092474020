import React, { useEffect, useState } from 'react'
import supabase from '../../../../../config/supabse';
import { useSelector } from 'react-redux';
import { MdFileDownloadDone } from "react-icons/md";
import { MdDone } from "react-icons/md";
import axios from 'axios';
import { IoMdCloseCircle } from "react-icons/io";

function DomainConfig(props) {
    const subDomain = useSelector((state) => state.Name.subdomain);
    const [isRefreshing, setIsRefrshing] = useState(false)
    const [customDomainData, setCustomDomainData] = useState({})
    const customDomain  = props.customDomain

    const fetchCustomDomainData = async () => {
        setIsRefrshing(true)
        if (!subDomain) {
            setIsRefrshing(false)
            return;
        }
        const { data: cusData, error } = await supabase
            .from('customDomains') // Replace with your actual table name
            .select('*')
            .eq('helloworldDomain', subDomain)
            .eq('domain', props.customDomain)
            .single();


        if (error) {
            setIsRefrshing(false)
            console.error('Error fetching data:', error);
            return;
        } else {
            console.log("Cus", cusData)
            setIsRefrshing(false)
            
        setCustomDomainData(cusData);


       
        }


    };

    useEffect(() => {
        fetchCustomDomainData()
    }, [props])




    const verifyRecords = async () => {
        try {
            // Fetch A Record
            const responseA = await axios.get(`https://helloworldbackend.com/checkArecord/${customDomain}`);
            const isARecordCorrect = responseA.data.status;
            console.log('A Record Status:', isARecordCorrect);
    
            // Fetch AAAA Record
            const responseAAAA = await axios.get(`https://helloworldbackend.com/checkAAAArecord/${customDomain}`);
            const isAAAARecordCorrect = responseAAAA.data.status;
            console.log('AAAA Record Status:', isAAAARecordCorrect);
    
            // Fetch CNAME Record
            const responseCNAME = await axios.get(`https://helloworldbackend.com/checkCNAME/${customDomain}`);
            const isCNAMERecordCorrect = responseCNAME.data.status;
            console.log('CNAME Record Status:', isCNAMERecordCorrect);
    
            // Check if all statuses are true
            if (isARecordCorrect && isAAAARecordCorrect && isCNAMERecordCorrect) {
                // If all records are verified, update the areRecordsVerified attribute in Supabase
                const { data, error } = await supabase
                    .from('customDomains')
                    .update({ areRecordsVerified: true })
                    .eq('domain', customDomain)
                    .eq('helloworldDomain', subDomain);
    
                if (error) {
                    console.error('Error updating record verification status in Supabase:', error);
                } else {
                    console.log('Record verification status updated successfully in Supabase.');
                }
            } else {
                console.log('One or more records are not correct.');
            }
        } catch (error) {
            console.error('Error verifying records:', error);
        }
    };



    return (

        <div className='p-5 mt-2'>
            <div class="w-full flex flex-col bg-white border shadow-sm rounded-md pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70 ">
                <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                    <h3 id="hs-vertically-centered-modal-label" class="font-bold text-gray-800 dark:text-white">
                        Domain Configuration
                    </h3>
                    <div className='flex flex-row items-center justify-center gap-4'>

                        {!isRefreshing ? (<button onClick={() => {
                            fetchCustomDomainData();
                            verifyRecords()


                        }} type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" data-hs-overlay="#hs-vertically-centered-modal">

                            Refresh
                        </button>) : (<div className='spinner'>
                        </div>)}
                    </div>
                </div>

                {!customDomainData.isPassed ? (<div class="p-4 overflow-y-auto flex flex-row gap-2">
                    {/* <IoMdCloseCircle className='text-2xl text-blue-500' /> */}
                    <p className='font-normal text-blue-500'>   Domain is Pending for Configuration by Helloworld....</p>
                </div>) : (<div class="p-4 overflow-y-auto flex flex-row gap-2">
                    <MdDone className="text-2xl text-green-500" />
                    <p className='font-normal text-green-500'>   Records are Correctly Configured by Helloworld.</p>
                </div>)}

                <hr className='bg-[#fcfcfc] h-2' />

                <div className='px-6 py-6 flex flex-col gap-3'>
                    {
                        !customDomainData?.areRecordsVerified ? (<div class=" overflow-y-auto flex flex-row gap-2">
                            <IoMdCloseCircle className='text-2xl text-red-500' />
                            <p className='text-zinc-700 font-semibold' > Please add/remove the specified records in order for domain to be configured. </p>


                        </div>) : (<div class=" overflow-y-auto flex flex-row gap-2">
                            <MdDone className="text-2xl text-blue-500" />
                            <p className='text-zinc-700 font-semibold' > As all the records have been verified, your domain will be configured after a while. </p>

                        </div>)
                    }



                    <div className='border-gray-200 border rounded-md p-4 flex flex-row gap-10'>
                        <p className='text-zinc-700'>DNS propagation changes can take upto 48 hours. In case of delay more than specified time email us at <span className='text-blue-500'>support@helloworld.com </span> </p>
                    </div>

                </div>



            </div>

        </div>

    )
}

export default DomainConfig