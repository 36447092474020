import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    data: "",
    subdomain: ""
}
  
//reducer
const NameSlice = createSlice({
  name: 'Name',
  initialState,
  reducers: {
    setName_: (state, action) => {
      state.data = action.payload
    },

    setDomain : (state, action) =>{
      state.subdomain = action.payload
    }
  },
})


// Action creators are generated for each case reducer function
export default  NameSlice.reducer
export const { setName_ , setDomain} = NameSlice.actions; 