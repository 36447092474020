import React, { useEffect, useState } from 'react'
import DomainOption from './DomainOption'
import DomainRecords from './DomainRecords';

function DomainSetupNav(props) {
    const [nav, setNav] = useState(1)
    const [selectedOption, setSelectedOption] = useState(1); // New state for radio button selection

    useEffect(()=>{
        setNav(props.nav)
    },[props.nav])

    const handleRadioChange = (e) => {
        setSelectedOption(parseInt(e.target.value));
    };
    if (nav === 1) {
        return (
            <DomainOption domainData={props.domainData} setNav={setNav} customDomain={props.customDomain} handleRadioChange={handleRadioChange} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        )
    } else if (nav === 2) {
        return ( 
            <DomainRecords  domainData={props.domainData} setNav={setNav} customDomain={props.customDomain} handleRadioChange={handleRadioChange} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        )
       
    }

}

export default DomainSetupNav