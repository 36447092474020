import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import supabase from '../../../../config/supabse';
import { setPlanDetails_, setPlanId_ } from '../../../../Store/Slices/userDetailsSlice';

function UpsertPlan() {
    const dispatch = useDispatch()

    const fetchPlanById = async (planId) => {
        const { data, error } = await supabase
            .from('plans')
            .select('*')
            .eq('planId', planId)

        if (error) {
            console.error("Error fetching plan:", error.message);
        } else if (data) {
            console.log("THe Plan: ", data)
            dispatch(setPlanId_({ planId: planId }))
            dispatch(setPlanDetails_({ planDetails: data[0] }))
        }
    };



    useEffect(()=>{
        const getSession = async () => {
            const { data, error } = await supabase.auth.getSession();
            if (error) {
              console.error("Error retrieving session:", error.message);
              return;
            }
            if (data) {
              if (
                data &&
                data.session &&
                data.session.user.id &&
                data.session.access_token
              ) {
                let userId = data.session.user.id;
                let accessToken = data.session.access_token;
                let email = data.session.user.email
            
                // Check if user plan exists
                const { data: userPlanData, error: userPlanError } = await supabase
                  .from('userPlans')
                  .select('*')
                  .eq('userId', userId)
      
                if (userPlanError) {
                  console.error("Error checking user plan:", userPlanError.message);
                }
                else if (!userPlanData || userPlanData?.length ===0 ) {
                  const { error: upsertError } = await supabase
                    .from('userPlans')
                    .upsert([{ userId: userId, planId: 1 }]);
      
                  if (upsertError) {
                    console.error("Error upserting user plan:", upsertError.message);
                  } else {
                    console.log("User plan upserted successfully.");
                  }
                } 
                else {
                  console.log("User plan already exists:", userPlanData);
                  fetchPlanById(userPlanData[0].planId)
                }
              }
            }
          };
          getSession();

    },[])

   
    return (
        <></>
    )
}

export default UpsertPlan