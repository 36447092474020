import React, { useState } from 'react'
import '../../Builder_prompt_components/global_theme/fonts.css'
import { useSelector, useDispatch } from 'react-redux'
import { setName_ } from '../../../Store/Slices/NameSlice';
import { setIdea } from '../../../Store/Slices/IdeaSlice';
import { setLogo } from '../../../Store/Slices/LogoSlice';
import supabase from '../../../config/supabse';
import { useNavigate, useParams } from 'react-router-dom';
import favicon from "../../../assets/blackborderfavicon.svg"
function UserPlan() {
  const {credId, token} =useParams()
  const dispatch = useDispatch()
  const Idea = useSelector((state) => { return state.Idea.data })
  const Name = useSelector((state) => { return state.Name.data })
  const Logo = useSelector((state) => { return state.Logo.data })
  const navigate = useNavigate()

  const [ideaVal, setIdeaVal] = useState(Idea)
  const [ideaEdit, setIdeaEdit] = useState(false)


  const [logoEdit, setLogoEdit] = useState(false)


  const [nameVal, setNameVal] = useState(Name)
  const [nameEdit, setNameEdit] = useState(false)
  const [logoSetting, setLogoSetting] = useState(false)

  const handleFileChange = async (e) => {
    setLogoSetting(true)
    const file = e.target.files[0]; // Get the first selected file
    if (!file) return;

    const chunkSize = 1024 * 1024; // 1MB chunk size, adjust as needed

    // Generate a random string of 6 digits
    function generateRandomString() {
      return Math.random().toString(36).substring(2, 8);
    }

    // Function to upload chunks sequentially
    async function uploadChunks(file) {
      const randomDigits = generateRandomString();
      const newFileName = `ztr07r_${randomDigits}_${file.name}`;
      var imageUrl = ''

      const fileSize = file.size;
      const chunks = Math.ceil(fileSize / chunkSize);
      let start = 0;
      let end = Math.min(chunkSize, fileSize);
      let currentChunk = 1;

      while (start < fileSize) {
        const chunk = file.slice(start, end);
        try {
          const { data, error } = await supabase.storage
            .from('hello-world')
            .upload(newFileName, chunk, {
              cacheControl: '3600',
              upsert: currentChunk === 1, // Upsert only on the first chunk
              contentType: "image/*",
            });
          if (data) {
            console.log(data);
            console.log(data.fullPath)
            // Retrieve the image URL from the data object
            imageUrl = `https://tdrdiflbdlftfwudjeim.supabase.co/storage/v1/object/public/${data.fullPath}`;
            console.log("Image URL:", imageUrl);

            console.log(`Chunk ${currentChunk} uploaded`);
          }
          if (error) {
            throw new Error(`Error uploading chunk ${currentChunk}: ${error.message}`);
          }
        } catch (error) {

          console.error(error.message);
          return; // Abort upload on error
        }
        // Move to the next chunk
        start = end;
        end = Math.min(start + chunkSize, fileSize);
        currentChunk++;
      }

      console.log("All chunks uploaded successfully");
      return imageUrl;

    }



    if (file) {

      const url = await uploadChunks(file);
      dispatch(setLogo(url))
      console.log(url)
      setLogoSetting(false)

    }

    setLogoSetting(false)
  };



  return (
    <div className='flex items-center justify-center w-full h-screen'>
      <div className='absolute top-[10%] flex items-center justify-center flex-col'>
        <img 
         src={favicon}
         alt="" 
         className='w-[60px] h-[60px]' />
        <h3 className=' text-5xl max-[400px]:text-xl font_inter  mt-4'>HelloWorld</h3>
      </div>
      <div className='mt-10'>
        <h1 className='manrope-500 w-[500px] text-[24px]'>Let's review the plan real quick</h1>
        <div className='mt-8'>
          <div className='flex flex-row justify-between manrope-400'>
            <h1 className='text-[20px] manrope-500'>Building</h1>
            {!ideaEdit ? (<button onClick={() => { setIdeaEdit(true) }} className='text-[16px] manrope-500 text-[#A9A9A9] hover:text-black'>Edit</button>) : (<button onClick={() => { dispatch(setIdea(ideaVal)); setIdeaEdit(false) }} className='text-[16px] manrope-500 text-[#A9A9A9] hover:text-black'>Done</button>)}
          </div>
          {!ideaEdit ? (<h1 className='text-[17px] manrope-400'>{ideaVal}</h1>) : (<form action=""> <input type="text" className='rounded-lg bg-[#EDEDED] border-none py-2 px-3 w-full manrope-400 mt-3' value={ideaVal} onChange={(e) => { setIdeaVal(e.target.value) }} /></form>)}

        </div>


        <div className='mt-8'>
          <div className='flex flex-row  justify-between manrope-400'>
            <h1 className='text-[20px] manrope-500'>Name</h1>
            {!nameEdit ? (<button onClick={() => { setNameEdit(true) }} className='text-[16px] manrope-500 text-[#A9A9A9] hover:text-black'>Edit</button>) : (<button onClick={() => { dispatch(setName_(nameVal)); setNameEdit(false) }} className='text-[16px] manrope-500 text-[#A9A9A9] hover:text-black'>Done</button>)}
          </div>
          {!nameEdit ? (<h1 className='text-[17px] manrope-400'>{nameVal}</h1>) : (<form action=""> <input type="text" className='rounded-lg bg-[#EDEDED] border-none py-2 px-3 w-full manrope-400 mt-3' value={nameVal} onChange={(e) => { setNameVal(e.target.value) }} /></form>)}

        </div>


        <div className='mt-8'>
          <div className='flex flex-row justify-between manrope-400'>
            <h1 className='text-[20px] manrope-500'>Logo</h1>
            {!logoEdit ? (<button onClick={() => { setLogoEdit(true) }} className='text-[16px] manrope-500 text-[#A9A9A9] hover:text-black'>Edit</button>) : (<button onClick={() => { setLogoEdit(false) }} className='text-[16px] manrope-500 text-[#A9A9A9] hover:text-black'>Done</button>)}
          </div>
          {!logoEdit ? (<h1 className='text-[17px] manrope-400'>{'...' + Logo?.slice(58, 125)}</h1>) : (<form action="" className='mt-2'>  <div className='border-[1px] border-dashed w-full border-[#8F8F8F] divide-dashed rounded-lg flex flex-row items-center py-2 justify-between px-2  mt-2 '>
            <form className=" flex items-center space-x-6">
              <label className="block">
                <input type="file" onChange={handleFileChange} className="block w-full text-sm text-[#878787] border-none font_inter bg-transparent
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm 
                    file:bg-transparent
                    file:text-[#878787]
                    hover:file:text-black
                    hover:file:cursor-pointer
                    "/>

              </label>

            </form>
            {!logoSetting ? (<></>) : (<div className='spinner'></div>)}

          </div></form>)}

        </div>

        <button onClick={()=>{navigate(`/progress/${credId}/${token}`)}} className='bg-black text-white w-full mt-7 py-3 px-3 rounded-lg'>Build Website</button>
      </div>
    </div>
  )
}

export default UserPlan