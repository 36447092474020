import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Explore from "../components/Explore";
import WaitList from "../components/WaitList";
import Video from "../components/Video";
import Pricing from "../components/Pricing";
function HomePage(props) {
  return (
    <div className="w-full overflow-x-hidden h-auto bg-[#f9f9f9] ">
      <Navbar credId={props.credId} token={props.token} />
      <Hero />
      <Video/>
      <Explore />
      <Pricing/>
      <WaitList />
      <Footer />
    </div>
  );
}

export default HomePage;
