import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    images: null,
    used: null // Initialize as an empty array instead of null
};

// Reducer
const ImageQuerySlice = createSlice({
    name: 'Query',
    initialState,
    reducers: {
        setImageQuery: (state, action) => {
            state.data = action.payload;
            console.log("setting queries ", action.payload)
        },
        setImages: (state, action) => {
            console.log("Set the images =>", action.payload);
            // Remove duplicates
            const uniqueImages = Array.from(new Set(action.payload));

            // Shuffle the array
            const shuffledImages = uniqueImages.sort(() => Math.random() - 0.5);

            // Update the state with the shuffled unique images
            state.images = shuffledImages;
        },
        shuffleImages: (state, action) => {
            const uniqueImages = Array.from(new Set(action.payload));
            const shuffledImages = shuffleArray(uniqueImages);
            state.images = shuffledImages;
        },

        addImages: (state, action) => {
            console.log("Appending images =>", action.payload);
            state.images = [...state.images, action.payload];
        },

        setUsed: (state, action) => {
            state.used = action.payload;
        },



        setUsedImagesState: (state, action) => {
            console.log("Appending images =>", action.payload);
            state.used = [...state.used, action.payload];
            console.log("State used = > ", state.used)
        },
        clearUsedImages: (state) => {
            state.used = [];
        }
    }
});

const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle
    while (currentIndex !== 0) {
        // Pick a remaining element
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // Swap it with the current element
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};


// Action creators are generated for each case reducer function
export const { setImageQuery, setImages, setUsedImagesState, shuffleImages, clearUsedImages, addImages, setUsed } = ImageQuerySlice.actions;
export default ImageQuerySlice.reducer;
