import { createSlice } from '@reduxjs/toolkit';

// Reducer
const ColorSlice = createSlice({
  name: 'Color',
  initialState: {
    data: {
      "clr1": "#ffffff",
      "clr2" : "#28b4ad",
      "clr3" : "#202f4d",
      "clr4": "#e9f3ea", 
      "clr5" : "#839295"
    },
    siteId: 1234,
    hue:"blue",
    fonts:{
      "font1":"font-roboto"
    },
    title:"h",
    description:"m"
  },
  reducers: {
    setColorArray: (state, action) => {
      state.data = action.payload
    },
    setColor: (state, action) => {
      state.data = action.payload; // Push the payload into the array
    },
    setFonts:(state, action) =>{
      state.fonts = action.payload;

    },
    setTitle:(state,action) =>{
      state.title =action.payload
    },
    setDescription:(state,action)=>{
      state.description = action.payload
    },
    setHue : (state, action) =>{
      state.hue =action.payload
    },
    setSiteId: (state, action)=> {
      state.siteId = action.payload

    },
    setColorEmpty: (state) => {
      state.data= {}
    }
  },
});

// Export the reducer and actions
export default ColorSlice.reducer;
export const { setColor, setColorEmpty, setHue, setFonts, setColorArray, setSiteId, setDescription, setTitle } = ColorSlice.actions;