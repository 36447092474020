import React from 'react'

import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';


function ContactCard(props) {
    const navigate = useNavigate()
    const {credId} =useParams()
    return (
        <div
        onClick={()=>{
            navigate(`/dashboard/${credId}/contact-details/${props?.data?.email}`)
        }} 
         className='flex w-[20vw] min-w-[300px] h-auto rounded-md bg-white border-2 border-gray-200 px-7 py-12 hover:shadow-md hover:border-gray-300 cursor-pointer'>
            <div   className='flex items-center w-full flex-col'>
                <span
                    className="inline-block rounded-full overflow-hidden border-4 border-gray-100"

                    style={{
                        width: '80px',
                        height: '80px',
                        minWidth: '100px',
                        minHeight: '100px',
                    }}
                >
                    <div
                        className="w-full h-full rounded-full bg-cover bg-no-repeat bg-center"
                        style={{
                            backgroundImage:
                                `url(https://api.dicebear.com/9.x/initials/svg?seed=4${props?.data?.firstName}${"+"}${props?.data?.lastName}})`,
                        }}
                    ></div>
                </span>


                <h1 className='text-zinc-600 mt-4 font-bold text-2xl text-center'>{props?.data?.firstName} {" "} {props?.data?.lastName}</h1>

                <div className='flex flex-row gap-2 mt-2 items-center'>
                  
                        <MdOutlineMail className='text-zinc-500  text-lg ' />
                    

                    <p className='text-zinc-500  text-lg '>{props?.data?.email}</p>
                </div>

                {props?.data?.phone?.length > 5 && <div className='flex flex-row gap-2 items-center'>
                  
                        <MdOutlinePhoneEnabled className='text-zinc-500 text-lg ' />
            

                    <p className='text-zinc-500  text-lg '>{props?.data?.phone}</p>
                </div>}


            </div>



        </div>


    )
}

export default ContactCard