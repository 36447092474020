import { createSlice } from '@reduxjs/toolkit';

// Reducer
const AssetsSlice = createSlice({
  name: 'Assets',
  initialState: {
    data: [], // Use an array for colorValue
  },
  reducers: {
    setAssets: (state, action) => {
      state.data.push(action.payload); 
      console.log("STATE : ",action.payload)
    },
    setAssetsEmpty: (state) => {
      state.data= []
    }
  },
});

// Export the reducer and actions
export default AssetsSlice.reducer;
export const { setAssets, setAssetsEmpty } = AssetsSlice.actions;