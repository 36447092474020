import React from 'react'
import { useSelector } from 'react-redux'
import { IoSettingsOutline } from "react-icons/io5";
function ActivePlan() {
    const ActivePlan = useSelector((state) => state.userDetails.planDetails);
    const Email = useSelector((state) => state.userDetails.email);

    const CurrentPlanfeatures = [
        { condition: ActivePlan.isCRMAllowed, feature: 'Customer Relationship Management Panel' },
        { condition: ActivePlan.isCustomDomainAllowed, feature: 'Custom Domain Configuration' },
        { condition: ActivePlan.isSEOAllowed, feature: 'Search Engine Optimization for Your Pages' },
        { condition: ActivePlan.isMobileOptimizationAllowed, feature: 'Fully Responsive Pages' }
    ]
    .filter(item => item.condition)  // Keep only items where the condition is true
    .map(item => item.feature);  // Extract the feature names




    return (
        <div className="flex flex-col space-y-12 w-full">
            {/* <div className="flex justify-between items-center">
                <h2 className="text-[36px] font-medium font-manrope leading-[60px] ">
                    Plans
                </h2>
            </div>
            <hr className="my-4 w-full border-t border-gray-100" /> */}
            <div className="flex flex-col space-y-8">
                <div className="flex justify-between items-center">
                    <p className="text-xl font-semibold">Active Plan</p>
                    <button
                        href=""
                        target="_blank"
                        rel="noreferrer"
                        as="button"
                        className="focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-700"
                    >
                        <a href='#upgrading-options' className="flex space-x-2 items-center">
                            <div
                                className="icon-container icon-sm text-lg text-yellow-500"
                                aria-hidden="true"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-up-circle"
                                >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <path d="m16 12-4-4-4 4m4 4V8"></path>
                                </svg>
                            </div>
                            <p className="text-sm">View Upgrade Options</p>
                        </a>
                    </button>
                </div>
                <div className="flex flex-col items-center justify-center relative pt-16 pb-8 px-8 rounded-xl border border-zinc-300">
                    <div className="flex flex-col space-y-8 items-center justify-center w-full">
                        <div className="flex flex-col space-y-6 items-center justify-center">
                            <div className="flex flex-col max-w-max rounded-full border-8 border-gray-50">
                                <span
                                    className="inline-block rounded-full overflow-hidden"
                                    title={Email}
                                    style={{
                                        width: '124px',
                                        height: '124px',
                                        minWidth: '124px',
                                        minHeight: '124px',
                                    }}
                                >
                                    <div
                                        className="w-full h-full rounded-full bg-cover bg-no-repeat bg-center"
                                        style={{
                                            backgroundImage:
                                                `url(https://api.dicebear.com/9.x/initials/svg?seed=4${Email})`,
                                        }}
                                    ></div>
                                </span>
                            </div>

                            <div className="flex flex-col space-y-2 items-center justify-center">
                                <p className="text-h1 font-semibold text-center text-black">
                                    You're on the {ActivePlan.title} Plan
                                </p>

                                {ActivePlan?.planId === 1 ? (
                                    <p className="text-base text-gray-600 text-center max-w-lg">
                                        You are currently on Free Plan. Upgrade to paid plan to unlock various features.
                                    </p>
                                ) : (
                                    <p className="text-base text-gray-600 text-center max-w-lg">
                                        Thanks for subscribing to the {ActivePlan.title} Plan. As a part of this
                                        plan, you have access to:
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                            {CurrentPlanfeatures.map((feature, index) => (
                                <div
                                    key={index}
                                    className="flex space-x-4 items-center py-3 px-4 rounded-md bg-gray-50"
                                >
                                    <div
                                        className="icon-container icon-md text-2xl text-zinc-800"
                                        aria-hidden="true"
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-check-circle"
                                        >
                                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                            <path d="M22 4L12 14.01l-3-3"></path>
                                        </svg>
                                    </div>
                                    <p className="text-sm text-zinc-800">{feature}</p>
                                </div>
                            ))}


                            <div

                                className="flex space-x-4 items-center py-3 px-4 rounded-md bg-gray-50"
                            >
                                <div
                                    className="icon-container icon-md text-2xl text-zinc-800"
                                    aria-hidden="true"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check-circle"
                                    >
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                </div>
                                <p className="text-sm text-zinc-800"> Number of Pages Allowed  <span className='font-bold'>{" "}{ActivePlan.numberOfPagesAllowed}{" "}</span> 
                               </p>
                            </div>


                            <div

                                className="flex space-x-4 items-center py-3 px-4 rounded-md bg-gray-50"
                            >
                                <div
                                    className="icon-container icon-md text-2xl text-zinc-800"
                                    aria-hidden="true"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check-circle"
                                    >
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                </div>
                                <p className="text-sm text-zinc-800"> Up to  <span className='font-bold'>{" "}{ActivePlan.numberOfMessageRequestsAllowed}{" "}</span> Number Responses on Contact Form
                               </p>
                            </div>




                        </div>
                        <div className="w-full flex justify-between items-center pt-6">
                            <p className="text-sm text-gray-600">
                                For more information, contact us at {' '}
                                <span
                                    className="text-yellow-500 font-medium"
                                >
                                    support@helloworld.to
                                </span>{' '}

                            </p>
                            {/* <a
                                href="/dashbo"
                                target="_blank"
                                rel="noreferrer"
                                as="button"
                                className="focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-700"
                            >
                                <div className="flex space-x-2 items-center">
                                    <p className="text-sm text-yellow-500 font-medium">Manage</p>

                                    <IoSettingsOutline className='text-xl text-yellow-500' />

                                </div>
                            </a> */}
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default ActivePlan