import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: null,
    access_token:null,
    email: null
    
}
  
// Reducer for userId
const UserIdSlice = createSlice({
  name: 'userId',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.data = action.payload
    },

    setUserEmail: (state, action) => {
      state.email = action.payload
    },
    set_access_token :(state,action) =>{
      state.access_token = action.payload

    },
    clearUserId: (state) => {
      state.data = null
    }
  },
})

export default  UserIdSlice.reducer
export const { setUserId, clearUserId, set_access_token, setUserEmail } = UserIdSlice.actions;
