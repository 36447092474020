import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIdea } from "../../../Store/Slices/IdeaSlice";
import "../global_theme/fonts.css";

import favicon from "../../../assets/blackborderfavicon.svg"
import { setSectionEmpty } from "../../../Store/Slices/SectionSlice";
import { setColorEmpty } from "../../../Store/Slices/ColorSlice";
import {
  emptyUserHtml,
  setHistory,
  setIsSiteCreated,
} from "../../../Store/Slices/userHtmlSlice";
import { setAssetsEmpty } from "../../../Store/Slices/AssetsSlice";
import { setLogoEmpty } from "../../../Store/Slices/LogoSlice";
import { clearUsedImages } from "../../../Store/Slices/ImageQuerySlice";
import supabase from "../../../config/supabse";
import { useLocation } from "react-router-dom";
import { PlaceholdersAndVanishInput } from "../../HelloWorldWeb/components/PlaceholdersAndVanishInput";

function Idea() {
  const { credId, token } = useParams();
  const dispatch = useDispatch();
  const idea = useSelector((state) => state.Idea.data);

  const [textAreaValue, setTextAreaValue] = useState(idea);
  const navigate = useNavigate();
  const textAreaRef = useRef(null);

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
  };

  useEffect(() => {
    dispatch(setIdea(textAreaValue));
  }, [textAreaValue]);

  useEffect(() => {
    dispatch(emptyUserHtml());
    dispatch(setColorEmpty());
    dispatch(setSectionEmpty());
    dispatch(setAssetsEmpty());
    dispatch(setLogoEmpty());
    dispatch(setIsSiteCreated(0));
    dispatch(clearUsedImages());
    dispatch(setHistory([]));
    //comment
  }, []);

  const location = useLocation();

  useEffect(() => {
    const handleMagicLink = async () => {
      const params = new URLSearchParams(location.search);
      const access_token = params.get("access_token");

      if (access_token) {
        const { user, error } = await supabase.auth.setSession(access_token);

        if (error) {
          console.error("Error setting session:", error.message);
        } else {
          console.log("User logged in:", user);
        }
      }
    };

    handleMagicLink();
  }, [location.search]);

  const handleClick = () => {
    navigate(`/name/${credId}/${token}`);
    //   navigate(`/name`);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [textAreaValue]);

  function cardVal(val) {
    dispatch(setIdea(val));
    navigate(`/name/${credId}/${token}`);
  }

  const placeholders = [
    "Build me a portfolio website for a graphic designer",
    "Build me a business website for financial advisory service",
    "Build me a startup website for an online business",
  ];

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="absolute top-[10%] flex items-center justify-center flex-col">
        <img
          src={favicon}
          alt=""
          className="w-[60px] h-[60px]"
        />
        <h3 className=" text-5xl max-[400px]:text-xl font_inter  mt-4">
          HelloWorld
        </h3>
      </div>

      <div className="flex items-center justify-center flex-col p-2 w-auto">
        <div className="flex items-center font_new_spirit justify-center flex-col p-6 mt-6 w-[40vw] mx-7   ">
          <div className="w-full  rounded-lg flex flex-col justify-between items-start px-2 py-1   ">
            {/* <textarea
              ref={textAreaRef}
              rows="1"
              cols="50"
              placeholder="What do you want to build?"
              value={textAreaValue}
              onChange={handleTextAreaChange}
              className='border-black text-sm h-auto font_inter resize-none rounded-md placeholder-grey_color-500 px-4 py-2 w-[90%] bg-white'
            ></textarea> */}

            <h3 className="text-xl max-[500px]:ml-1 max-[500px]:text-md font-normal font_inter mb-3 ml-2">
              What do you want to build?
            </h3>

            <PlaceholdersAndVanishInput
              placeholders={placeholders}
              onChange={handleTextAreaChange}
              onSubmit={handleClick}
            />

            {/*    

            <button onClick={handleClick} className='flex items-center justify-center w-[30px] h-[30px] text-white bg-black rounded-md font_new_spirit'><span class="material-symbols-outlined">
              arrow_upward
            </span></button> */}
          </div>
        </div>
      </div>
      <div className={`jnjknkj`}></div>
    </div>
  );
}

export default Idea;
