import React from "react";
import SettingStatus from "../DashboardComponents/WebsiteSettings/SettingsStatus/SettingStatus";
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";
const Status = () => {
  return (
    <>
      {" "}
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
      <HorizontalHeader headerTitle={"Status"} />
        <div className="flex-1 overflow-auto p-4">
          {/* <SettingStatus /> */}
        </div>
      </div>
    </>
  );
};

export default Status;
