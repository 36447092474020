import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../DashboardComponents/Sidebar/Sidebar";
import Header from "../DashboardComponents/Home/Header";

const RootLayout = () => {
  return (
    <div className="flex bg-white h-[100vh] overflow-hidden">
      <Sidebar />
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default RootLayout;
