import React, { useEffect, useState } from "react";
import "../../Builder_prompt_components/global_theme/fonts.css";
import "../css/animation.css";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../../../config/supabse";
import favicon from "../../../assets/blackborderfavicon.svg";
import { useSelector } from "react-redux";
import { Button } from "./ui/button";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";
import { Menu } from "lucide-react";
function Navbar() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  const [auth, setAuth] = useState(false);
  const subDomain = useSelector((state) => {
    return state?.Name?.subdomain;
  });

  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }
      if (data && data.session?.user?.id && data.session.access_token) {
        setId(data.session.user.id);
        setToken(data.session.access_token);
        setAuth(true);
      }
    };
    getSession();
  }, []);

  return (
    <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Link
            href="/"
            className="text-[0.9rem] pt-1 font-semibold text-[#A2A2A2]"
          >
            <img src="/Logo.png" className="w-[110px] h-[23px]" alt="logo" />
          </Link>
        </div>
        <div className="hidden md:flex md:space-x-8 items-center justify-center">
          <NavLink href="/about">About</NavLink>
          <NavLink href="/pricing">Pricing</NavLink>
          <NavLink href="/login">Log In</NavLink>
          {auth ? (
            <button
              onClick={() => {
                navigate(`/loading/${id}/${token}/2`);
              }}
              className="flex items-center justify-start px-6 py-2 text-sm text-white bg-black rounded-full font_inter"
            >
              dashboard {"->"}
            </button>
          ) : (
            <Button
              to="/"
              className="flex items-center justify-start px-6 py-2 text-sm text-white bg-black rounded-full font_inter"
            >
              Join Waitlist
            </Button>
          )}
        </div>

        <Sheet>
          <SheetTrigger asChild>
            <Button variant="outline" size="icon" className="md:hidden">
              <Menu className="h-6 w-6" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="top">
            <nav className="flex flex-col space-y-7 mt-4">
              <Link
                href="/"
                className="text-[0.9rem] pt-1 font-semibold text-[#A2A2A2]"
              ></Link>
              <div className="flex flex-col space-y-4">
                <Link href="/about" className="text-base">
                  About
                </Link>
                <Link href="/pricing" className="text-base">
                  Pricing
                </Link>
                <Link href="/login" className="text-base">
                  Log In
                </Link>
                <Button className="bg-black text-white rounded-full px-5 py-2 flex items-center gap-2 hover:bg-gray-800 transition duration-500">
                  Get Started
                </Button>
              </div>
            </nav>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
}

export default Navbar;

function NavLink({ href, children }) {
  return (
    <Link
      href={href}
      className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-900 whitespace-nowrap"
    >
      {children}
    </Link>
  );
}
