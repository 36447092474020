import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPage, duplicateRenamePage } from '../../Store/Slices/userHtmlSlice';
import '../Builder_prompt_components/global_theme/fonts.css'

function AddNewPage() {
    const dispatch = useDispatch();
    const [isAddingPage, setIsAddingPage] = useState(false);
    const [pageName, setPageName] = useState('');

    const handleInputChange = (event) => {
        setPageName(event.target.value);
    };

    const handleAddPage = () => {
        if (pageName.trim() !== '') {
            // Dispatch an action here to add the page
            // For example: dispatch(addPage(pageName));
            // You need to define the addPage action creator in your Redux setup
            setTimeout(() => {
                dispatch(duplicateRenamePage({ pageName: "temp", newName: pageName }));
            }, 1000);


            setPageName('');
            setIsAddingPage(false); // Hide the input field and buttons after adding the page
        }
    };

    const handleCancel = () => {
        setPageName('');
        setIsAddingPage(false); // Hide the input field and buttons without adding the page
    };

    return (
        <div className="w-full px-4 mt-3">
            {!isAddingPage ? (


                <div className="px-3 w-full">
                    <button className="mt-6  bg-[#f4f4f4] rounded-md hover:border-gray-400 hover:text-white transition-colors w-full flex justify-center items-center gap-1  py-4"
                        onClick={() => setIsAddingPage(true)}>
                        <PlusIcon className="w-4 h-4 mr-2 text-lg" />  Add New Page </button>
                </div>
                // <button
                //     className="mt-4 border-dotted border-2 border-gray-400 text-gray-400 hover:text-gray-200 transition-colors w-full flex justify-center items-center gap-1 font-medium rounded-lg font_inter py-2 hover:bg-gray-400 bg-[#272727]"
                //     onClick={() => setIsAddingPage(true)}
                // >
                //     <PlusIcon className="w-4 h-4 mr-2" /> Add New Page
                // </button>
            ) : (
                <div className="mt-4 p-4 flex flex-col gap-3 justify-between items-center w-full">
                    <input
                        type="text"
                        className="border font_inter w-full border-zinc-100 px-4 py-2 rounded-md bg-[#F4f4f4] placeholder:text-zinc-400 text-white"
                        placeholder="Enter page name"
                        value={pageName}
                        onChange={handleInputChange}
                    />
                    <div className='w-full font_inter'>
                        <button
                            className="w-[48%] px-4 py-2 rounded-md mr-2 bg-[#FFD488] text-black hover:bg-green-600"
                            onClick={handleAddPage}
                        >
                            Add
                        </button>
                        <button
                            className="w-[48%] px-4 py-2 rounded-md bg-[#f4f4f4] text-black hover:bg-gray-500"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddNewPage;


function PlusIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M5 12h14" />
            <path d="M12 5v14" />
        </svg>
    )
}