import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    email: "",
    planId: 1, 
    planDetails: {plan:"plan"},
    billing: "",
    usage: ""
}

//reducer
const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        setEmail_: (state, action) => {
            console.log("Setting email", action.payload)
            state.email = action.payload
        },
        setPlanId_: (state, action) => {
            const {planId} =action.payload
            console.log("PlanId" , planId)
            state.planId = planId
        },
        setPlanDetails_: (state, action) => {
          
            const {planDetails} =action.payload
            state.planDetails = planDetails
        },
        setBilling_: (state, action) => {
            // state.data = action.payload
        },
        setUsage_: (state, action) => {
            // state.data = action.payload
        },
    },
})


// Action creators are generated for each case reducer function
export default userDetailsSlice.reducer
export const { setEmail_, setBilling_, setPlanDetails_, setPlanId_, setUsage_ } = userDetailsSlice.actions; 