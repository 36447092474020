import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Import Chart.js

const BarGraph = ({ height, width, data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Visitors',
        data: [],
        backgroundColor: '#FFD488',
        borderRadius: 20, // Set top corners rounded
        borderSkipped: 'bottom', // Skip rounding the bottom corners
      },
    ],
  });

  useEffect(() => {
    // Function to generate actual data for peak hours
    const generatePeakHourData = () => {
      const hourCounts = Array(24).fill(0); // Array to store visitor counts for each hour

      data.forEach(entry => {
        const date = new Date(entry.created_at);
        const hour = date.getHours();
        hourCounts[hour]++;
      });

      return hourCounts;
    };

    const visitorsByHour = generatePeakHourData();

    const labels = Array.from({ length: 24 }, (_, i) => `${i}:00 - ${i + 1}:00`);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Visitors',
          data: visitorsByHour,
          backgroundColor: '#FFD488',
          borderRadius: 20, // Top corners rounded
          borderSkipped: 'bottom', // Ensure the bottom corners are not rounded
        },
      ],
    });
  }, [data]);

  return (
    <div className="rounded-xl" style={{ width: width, height: height }}>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false, // Prevents automatic scaling of height
          plugins: {
            legend: {
              display: false,
              position: 'top',
            },
          },
          scales: {
            x: {
              title: {
                display: false,
                text: 'Date',
                color: '#8B8B8B',
              },
              grid: {
                display: false,
              },
              ticks: {
                color: '#8B8B8B',
                maxTicksLimit: 10, // Control the number of x-axis labels
              },
            },
            y: {
              title: {
                display: false,
                text: 'Number of Visitors',
                color: '#8B8B8B',
              },
              grid: {
                display: false,
              },
              ticks: {
                color: '#8B8B8B',
                stepSize: 20, // Increase the gap between intervals
              },
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
};

export default BarGraph;
