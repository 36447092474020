import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { Task } from "../Task/Task";
import "./Column.css";

export const Column = ({ tasks, setData, setTasks, handleSaveState
 }) => {
 console.log("tid :",tasks)
  return (
    <div className="column">
      <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
        {tasks?.map((task) => (
          <Task handleSaveState={handleSaveState} key={task.id} id={task.id}  _id={task._id} title={task.title}  setData={setData} tasks={tasks} setTasks={setTasks}/> 
        ))}
      </SortableContext>
    </div>
  );
};
