import { useScroll } from 'framer-motion'
import React, { useState } from 'react'

import '../../../../SignIn/spinner.css'
import DomainRecordForRoot from './DomainRecordForRoot'
import DomainRecordForWWW from './DomainRecordForWWW'

function DomainRecords(props) {
  return (
    <div class="p-5">

      {
        props.selectedOption === 1 ? (
          <div className='flex flex-col items-center justify-center gap-10'>
            <DomainRecordForRoot  domainData={props.domainData} customDomain={props.customDomain}/>
            <DomainRecordForWWW  domainData={props.domainData} customDomain={props.customDomain}/>
          </div>

        ) : (
          <DomainRecordForRoot   domainData={props.domainData} customDomain={props.customDomain}/>
        )
      }




    </div>
  )
}

export default DomainRecords