import React, { useEffect, useState } from 'react'
import '../global_theme/theme.css'; // Replace with your theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../global_theme/fonts.css'
import { setLogo } from '../../../Store/Slices/LogoSlice';
import '../../SignIn/spinner.css'
import favicon from "../../../assets/blackborderfavicon.svg"

import supabase from '../../../config/supabse';
function LogoUpload() {
  const [logoSetting, setLogoSetting] =useState(false);
  const { credId, token } = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImageUrl] = useState(null)


  function getDarknessIntensity(color) {
    // Convert hex color to RGB
    var r = parseInt(color.slice(1, 3), 16);
    var g = parseInt(color.slice(3, 5), 16);
    var b = parseInt(color.slice(5, 7), 16);

    // Calculate perceived brightness
    var perceivedBrightness = Math.sqrt(
      0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
    );

    // Normalize perceived brightness to range [0, 1]
    return perceivedBrightness / 255;
  }



  const handleClick = () => {

    navigate(`/yourplan/${credId}/${token}`);
  };



  const handleFileChange = async (e) => {
    setLogoSetting(true)
    const file = e.target.files[0]; // Get the first selected file
    if (!file) return;

    const chunkSize = 1024 * 1024; // 1MB chunk size, adjust as needed

    // Generate a random string of 6 digits
    function generateRandomString() {
      return Math.random().toString(36).substring(2, 8);
    }

    // Function to upload chunks sequentially
    async function uploadChunks(file) {
      const randomDigits = generateRandomString();
      const newFileName = `ztr07r_${randomDigits}`;
      var imageUrl = ''

      const fileSize = file.size;
      const chunks = Math.ceil(fileSize / chunkSize);
      let start = 0;
      let end = Math.min(chunkSize, fileSize);
      let currentChunk = 1;

      while (start < fileSize) {
        const chunk = file.slice(start, end);
        try {
          const { data, error } = await supabase.storage
            .from('hello-world')
            .upload(newFileName, chunk, {
              cacheControl: '3600',
              upsert: currentChunk === 1, // Upsert only on the first chunk
              contentType: "image/*",
            });
          if (data) {
            console.log(data);
            console.log(data.fullPath)
            // Retrieve the image URL from the data object
            imageUrl = `https://tdrdiflbdlftfwudjeim.supabase.co/storage/v1/object/public/${data.fullPath}`;
            console.log("Image URL:", imageUrl);

            console.log(`Chunk ${currentChunk} uploaded`);
          }
          if (error) {
            throw new Error(`Error uploading chunk ${currentChunk}: ${error.message}`);
          }
        } catch (error) {
        
          console.error(error.message);
          return; // Abort upload on error
        }
        // Move to the next chunk
        start = end;
        end = Math.min(start + chunkSize, fileSize);
        currentChunk++;
      }

      console.log("All chunks uploaded successfully");
      return imageUrl;

    }

  

    if (file) {

      const url = await uploadChunks(file);
      dispatch(setLogo(url))
      console.log(url)
    setLogoSetting(false)
 
    }

    setLogoSetting(false)
  };


  useEffect(() => {
    console.log(image)
  }, [image])



  const [color1, setColor1] = useState('0085ff')
  const [color2, setColor2] = useState('ff9688')
  const [color3, setColor3] = useState('ff9688')
  const [color4, setColor4] = useState('ff9688')
  const [color5, setColor5] = useState('ff9688')
  const [color6, setColor6] = useState('ff9688')
  const [color7, setColor7] = useState('ff9688')
  const [color8, setColor8] = useState('ff9688')




  
  return (

    <div className='flex items-center justify-center min-h-screen '>
        <div className='absolute top-[10%] flex items-center justify-center flex-col'>

<img
src={favicon}  alt="" className='w-[60px] h-[60px]' />
<h3 className=' text-5xl max-[400px]:text-xl font_inter  mt-4'>HelloWorld</h3>
</div>
      <div className='flex items-center justify-center flex-col p-2 w-auto' >
        <div className='flex items-start justify-center flex-col p-6 mt-6 w-[40vw] max-[600px]:mx-7   '>
          <h3 className='text-xl max-[500px]:ml-1 max-[500px]:text-md font-normal font_inter mt-2 ml-3'>Do you have a logo?</h3>

          <div className='border-[1px] border-dashed w-full border-[#8F8F8F] divide-dashed rounded-full flex flex-row items-center py-2 justify-between px-2  mt-2 '>
            <form className=" flex items-center space-x-6">
              <label className="block">
                <input type="file" onChange={handleFileChange} className="block w-full text-sm text-[#878787] border-none font_inter bg-transparent
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm 
                    file:bg-transparent
                    file:text-[#878787]
                    hover:file:text-black
                    hover:file:cursor-pointer
                    "/>

              </label>

            </form>
            {!logoSetting ?  (<button onClick={handleClick} className='flex items-center justify-center w-[30px] h-[30px] text-white bg-black hover:bg-black rounded-full font_new_spirit mr-1'><span class="material-symbols-outlined font-thin rotate-90">
              arrow_upward
            </span></button>) : (<div className='spinner'></div>)}

          </div>

         <p onClick={() => { navigate(`/idea/${credId}/${token}`) }} className='font-medium mt-2 font_inter text-md cursor-pointer ml-3 hover:text-zinc-600 '>&lt; Back</p>

        </div>
      </div>
    </div>
  )
}

export default LogoUpload
