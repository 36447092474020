import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../../SignIn/spinner.css'
import supabase from '../../../../config/supabse'
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import './customscroll.css'
import Note from './Note';


function ContactNotes() {

    const { credId, email } = useParams()
    const [notes, setNotes] = useState([])
    const [noteText, setNoteText] = useState('')
    const [isAddClicked, setIsAddClicked] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [trigger, setTrigger] =useState(false)
    const insertNote = async () => {
        try {
            setIsUpdating(true)
            const { data, error } = await supabase
                .from('contactNotes')
                .insert([
                    { noteText: noteText, contactEmail: email, userId:credId }
                ]);

            if (error) {
                setIsUpdating(false)
                console.error('Error inserting data:', error);
            } else {
                setIsUpdating(false)
                setIsAddClicked(false)
                setTrigger(!trigger)
                setNoteText('')
                console.log('Data inserted successfully:', data);
            }
        } catch (error) {
            setIsUpdating(false)
            console.error('Unexpected error:', error);
        }

    }


    useEffect(() => {
        const fetchNotes = async () => {
            try {
                setIsLoading(true)
                const { data, error } = await supabase
                    .from('contactNotes')
                    .select('*')
                    .eq('contactEmail', email)
                    .eq('userId', credId)

                if (error) {
                    setIsLoading(false)
                    console.error('Error inserting data:', error);
                } else {
                    setIsLoading(false)
                    setNotes(data)
                    console.log('Data fetched successfully:', data);
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Unexpected error:', error);
            }

        }

        fetchNotes()


    }, [email, trigger])

    return (
        <div className='w-[74%] bg-white h-[35vh] border border-gray-200 rounded-md p-5 flex flex-col'>
            <p className='font-semibold'>Notes</p>
            <div className='flex flex-row mt-4 h-full gap-3 overflow-x-auto w-full'>
                {!isAddClicked ? (<div className='min-w-[15vw] h-full bg-white border-gray-300 border-dashed border rounded-md flex items-center justify-center '>
                    <button onClick={() => { setIsAddClicked(true) }} className='w-12 h-12 rounded-full bg-gray-50 flex items-center justify-center group hover:bg-gray-200'>
                        <IoMdAdd className='text-4xl text-zinc-400 group-hover:text-zinc-700 cursor-pointer' />
                    </button>

                </div>) : (
                    <div className='min-w-[15vw] h-full border-gray-300 bg-yellow-100 border-dashed border rounded-md flex flex-col'>
                        {!isUpdating ? (<button onClick={() => { insertNote() }} className='w-full flex justify-end px-5 pt-5'>
                            <IoSend className='hover:text-zinc-700 cursor-pointer' />
                        </button>) : (
                            <div className='w-full flex justify-end px-3 pt-3'>
                                <div className='spinner'></div>
                            </div>


                        )}
                        <textarea name="" value={noteText} placeholder='Write something...' className="p-5 w-full text-black h-full bg-transparent" onChange={(e) => { setNoteText(e.target.value) }} id=""></textarea>
                    </div>
                )}

                <div className='flex flex-row overflow-x-scroll custom-scrollbar  gap-3'>
                    {notes && notes.length > 0 && notes.map((note, index) => (
                        <Note note={note} index={index} setTrigger={setTrigger} trigger={trigger}/>
                    ))}
                </div>


            </div>


        </div>
    )
}

export default ContactNotes