import React from "react";
import WebsiteDomain from "../DashboardComponents/Domain/WebsiteDomain";
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";

const Domain = () => {
  return (
    <>
      {" "}
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
      <HorizontalHeader headerTitle={"Domain"} />
        <div className="flex-1 overflow-auto p-4">
          <WebsiteDomain />
        </div>
      </div>
    </>
  );
};

export default Domain;
