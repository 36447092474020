import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-black text-white py-4 px-6 w-full">
    <div className="max-w-7xl mx-auto flex justify-between items-center">
      <div className="text-sm">HelloWorld, Inc</div>
      <div className="md:space-x-6 space-x-3 text-sm">
        <a href="#" className="hover:underline">
          Privacy Policy
        </a>
        <a href="#" className="hover:underline">
          Terms of Service
        </a>
      </div>
    </div>
  </footer>
  );
}

export default Footer;
