import React from "react";

import WebsiteOverviewMain from "../DashboardComponents/Overview/WebsiteOverviewMain";
import HorizontalHeader from "../DashboardComponents/HorizontalHeader/HorizontalHeader";
;

const WebsiteOverview = () => {
  return (
    <div className="flex flex-col w-full h-[100vh] overflow-hidden">
      <HorizontalHeader headerTitle={"Overview"} />
      <div className="flex-1 overflow-auto p-4">
        <WebsiteOverviewMain />
      </div>
    </div>
  );
};

export default WebsiteOverview;
