import React, { useState } from 'react';
import './dropdown.css'; // Import the CSS file for styling

const Dropdown = ({ options, selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container relative">
      <button 
        className="dropdown-button w-full p-4 rounded-lg border border-[#e1e1e1] bg-white text-[#000000] text-sm font-medium leading-5 tracking-tight"
        onClick={toggleDropdown}
      >
        {selectedOption || 'Select an option'}
        <svg className="dropdown-icon absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-[#000000]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="dropdown-menu absolute mt-2 w-full rounded-lg border border-[#e1e1e1] bg-white shadow-lg">
          {options.map((option) => (
            <div 
              key={option} 
              className="dropdown-item p-4 text-sm font-medium leading-5 tracking-tight hover:bg-yellow-100 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
